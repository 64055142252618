<template>
  <div class="container-fluid container-min-height">
    <orientation-detector
      @update-orientation="updateOrientation"
      @pause-question-audio="pauseLLMAudio"
      @resume-question-audio="playLLMAudio"
    />
    <audio
      id="learn-audio-player"
      :src="showEndTopicMsg ? endOptionSrc : learnAudioSrc"
      preload="auto"
      type="audio/mpeg"
      autoplay
      @ended="audioEnded"
    ></audio>
    <div class="row m-0 home-page">
      <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-0">
        <header-component @topicDetailsBack="topicDetailsBack" />
        <div
          class="row m-0 tab-container"
          :style="{ backgroundColor: topicColor }"
        >
          <div class="col-12 p-0 tabs">
            <div
              v-for="(topic, index) in topicChevron"
              :key="topic.name"
              class="tab-item"
            >
              <button
                class="tab-button"
                :class="[
                  isActiveTab(topic.name) ? 'active' : '',
                  isQuestionsTab(topic.name) ? 'question-tab' : '',
                  isQuizTab(topic.name) ? 'quiz-tab' : '',
                  clickQusQuizTab ? 'disabled-tab' : '',
                  shouldDisableTab(topic.name) ? 'disabled-tab' : '',
                ]"
                @click="!clickQusQuizTab ? selectedTab(topic.name) : ''"
                @mouseover="hoveredTab = index"
                @mouseleave="hoveredTab = null"
                :style="{
                  backgroundColor:
                    (!clickQusQuizTab && hoveredTab === index) ||
                    isActiveTab(topic.name)
                      ? topicColor
                      : '',
                  color:
                    (!clickQusQuizTab && hoveredTab === index) ||
                    isActiveTab(topic.name)
                      ? '#000000'
                      : '#32323280',
                }"
                :disabled="clickQusQuizTab || isActiveTab(topic.name)"
              >
                {{ topic.name }}
              </button>
            </div>
          </div>
          <!-- Presentation -->
          <div  class="col-12 p-0 content-container d-flex justify-content-center" v-if="presentation">
            <div class="row m-0 video-container d-flex justify-content-center">
              <video
                id="videoPlayer"
                ref="videoPlayer"
                class="custom-video"
                preload="auto"
                playsinline
                :poster="posterUrl"
                :src="videoUrl"
                @canplaythrough="handleCanPlay"
                @loadedmetadata="handleLoadedMetadata"
                @canplay="handleCanPlay"
                @ended="handleVideoEnd"
              >
                <source :src="videoUrl" type="video/mp4" />
              </video>
              <div class="col-12 p-0 controls" ref="animationContainer">
                <div
                  class="character-animation feather-animation"
                  ref="featherImage"
                ></div>
                <img
                  v-if="!isPlaying"
                  class="playNpause cursor-pointer"
                  src="../assets/Fnff-Play-Button.png"
                  @click="togglePlayPause"
                />
                <img
                  v-if="isPlaying"
                  class="playNpause cursor-pointer"
                  src="../assets/Fnff-Pause-Button.png"
                  @click="togglePlayPause"
                />
                <div
                  class="character-animation flute-animation"
                  ref="fluteImage"
                ></div>
              </div>
            </div>
          </div>
          <!-- Question and Answer -->
          <div
            class="col-12 p-0 content-container"
            v-if="qusAndAnswer || assignment"
          >
            <div>
              <div
                :class="{
                  'row m-0': !clickQusQuizTab,
                  'question-container': !isLoading,
                  'waiting-loader-position': isLoading && !clickQusQuizTab,
                }"
                :style="{
                  backgroundImage: showEndTopicMsg
                    ? 'url(' + require('../assets/Celebration.png') + ')'
                    : 'none',
                }"
              >
                <!-- Show loading image while waiting for API response after microphone click -->
                <div v-if="isLoading">
                  <!-- Conditionally show FadeLoader or image-loader-component -->
                  <div
                    v-if="clickQusQuizTab"
                    class="d-flex align-items-center justify-content-center question-container"
                  >
                    <fade-loader
                      :loading="loading"
                      :color="topicColor"
                      :size="30"
                    ></fade-loader>
                  </div>
                  <div v-else>
                    <image-loader-component
                      :isLoading="isLoading"
                      :imageResponse="loadingStateImgResponse"
                      :audioResponse="audioResponse"
                    />
                  </div>
                </div>
                <!-- Show Question body after LLM response -->
                <div
                  v-else
                  class="col-12 p-0 qustion-body"
                  :class="{
                    'blur-content': listeningText === 'I\'m listening',
                  }"
                  :style="{ backgroundColor: topicTertiaryColor }"
                >
                  <div v-if="qusAndAnswer" class="row m-0">
                    <p class="dummy-text" v-if="!scrollableText">
                      Ask me any questions you have about {{ topicName }}
                    </p>
                    <div ref="scrollContainer" class="text-scroller-container">
                      <div class="dummy-text">{{ scrollableText }}</div>
                    </div>
                  </div>
                  <div v-if="assignment" class="row m-0">
                    <div ref="scrollContainer" class="text-scroller-container">
                      <div class="dummy-text" v-if="showEndTopicMsg">
                        {{ endOptionText }}
                      </div>
                      <div class="dummy-text" v-else>{{ optionText }}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 question-controls"
                  ref="featheranimationContainer"
                >
                  <div
                    v-if="!isLoading"
                    class="character-animation feather-animation fnff-peacock-left"
                    ref="featherImage"
                  ></div>
                  <!-- Show microphone image Before LLM response screens -->
                  <div v-if="!isLoading && !audioLoaded && !showEndTopicMsg">
                    <img
                      v-if="isRecording && !afterIsRecording"
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Listen.png"
                    />
                    <img
                      v-else
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Mute.png"
                      @click="toggleRecording"
                    />
                    <span class="tooltiptext">{{ listeningText }}</span>
                  </div>
                  <div v-if="showEndTopicMsg">
                    <img
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Mute.png"
                      @click="playCelebrationAudioOnClick"
                    />
                    <span class="tooltiptext">{{ listeningText }}</span>
                  </div>
                  <div v-if="!isLoading && clickQuestion">
                    <img
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Mute.png"
                    />
                  </div>
                  <!-- Show audio controls like stop, play and pause button After LLM response screens -->
                  <div
                    class="row stopButton"
                    v-if="audioLoaded && !showEndTopicMsg && !clickQuestion"
                  >
                    <button
                      type="button"
                      @click="stopLLMAudio"
                      class="control-stop-button mr-2"
                      ref="featherStopAnimationContainer"
                    >
                      <img
                        class="cursor-pointer control-image"
                        src="../assets/Fnff-Stop-Button.png"
                      />
                      <span class="ml-2">Stop</span>
                    </button>
                    <button
                      class="control-button"
                      @click="pauseLLMAudio"
                      v-if="!isQustionAndAnswerPaused && audioLoaded"
                      ref="featherStopAnimationContainer"
                    >
                      <img
                        class="control-image cursor-pointer"
                        src="../assets/Fnff-Qus-Pause-Button.png"
                      />
                      <span class="ml-2">Pause</span>
                    </button>
                    <button v-else class="control-button" @click="playLLMAudio">
                      <img
                        class="control-image cursor-pointer"
                        src="../assets/Fnff-Qus-Play-Button.png"
                      />
                      <span class="ml-2">Play</span>
                    </button>
                  </div>
                  <!-- <img
                    class="fnff-peacock-right"
                    src="../assets/Fnff-Peacock.png"
                    alt="Sound GIF"
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <!-- Quiz -->
          <!-- <div class="col-12 p-0 content-container" v-if="assignment">Quiz</div> -->
        </div>
      </div>
    </div>
    <div
      v-if="!isMicrophoneAllowed && isMicrophoneBrowserAllowed"
      class="auth-overlay"
    >
      <div>
        <div class="prompt-block">
          <template>
            <div>
              <h4>We need access to your microphone.</h4>
              <p>
                Please click the lock icon in your address bar to fix this and
                refresh the page.
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="isVideoLoading">
      <loading
        :active="isVideoLoading"
        color="#9651F1"
        backgroundColor="#C9C9C9"
        :height="100"
        :width="100"
      />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import ImageLoaderComponent from "@/components/common/ImageLoaderComponent.vue";
import { updateTitle } from "@/utils/utils";
import axios from "axios";
import Loading from "vue-loading-overlay";
import NoSleep from "@uriopass/nosleep.js";
import OrientationDetector from "@/components/common/OrientationDetector.vue";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export default {
  components: {
    FadeLoader,
    HeaderComponent,
    Loading,
    ImageLoaderComponent,
    OrientationDetector,
  },
  name: "TopicDetailsNew",
  data() {
    return {
      isPlaying: false,
      presentation: true,
      qusAndAnswer: false,
      assignment: false,
      hoveredTab: null,
      subjectName: localStorage.getItem("subjectName"),
      topicName: localStorage.getItem("topicName"),
      categoryName: localStorage.getItem("categoryName"),
      topicColor: localStorage.getItem("topicColor"),
      topicSecondarColor: localStorage.getItem("topicSecondarColor"),
      topicTertiaryColor: localStorage.getItem("topicTertiaryColor"),
      userEmail: localStorage.getItem("email"),
      topicChevron: [
        { name: "Learn" },
        { name: "Questions" },
        { name: "Quiz" },
      ],
      videoUrl: null,
      isVideoLoading: true, // Default loader state
      posterUrl: "",
      isRecording: false,
      afterIsRecording: false,
      isMicrophoneAllowed: false,
      isMicrophoneBrowserAllowed: false,
      mediaStream: null,
      mediaRecorder: null,
      chunks: [],
      listeningText: "Click the microphone to speak",
      isNavigatingAway: false,
      cancelTokenSource: null,
      audioLoaded: false,
      learnAudioSrc: "",
      session_type: null,
      noSleep: null,
      learn_audio_player: null,
      isLoading: false,
      //loading image
      loadingStateImg: [require("@/assets/WaitingLLMResponse.gif")],
      loadingStateImgResponse: [],
      //loader audio
      audioFiles: [
        require("@/assets/jenny_response1_out.mp3"),
        require("@/assets/jenny_response2_out.mp3"),
        require("@/assets/jenny_response3_out.mp3"),
        require("@/assets/jenny_response4_out.mp3"),
        require("@/assets/jenny_response5_out.mp3"),
      ],
      audioResponse: [],
      isQustionAndAnswerPaused: false,
      isPortrait: false,
      //text auto scrollable
      scrollableText: "",
      wordChunkSize: 20, // Number of words per scroll chunk
      interval: null, // Interval ID for scrolling
      scrollAmount: 0, // Amount of scroll in pixels for each chunk
      optionText: "",
      textOptionChunks: [],
      scrollInterval: null,
      clickQusQuizTab: false,
      clickQuestion: false,
      // below variable are use for quiz tab. once quiz is over for celebration page.
      endQuizFlag: "",
      showEndTopicMsg: false, // to show celebration page
      endQuizMsg: "",
      endOptionText: "",
      endOptionUrl: "",
      endOptionSrc: "",
      audio_flag: true,
      videoData: {
        fluteImage: null,
        featherImage: null,
        animation_checkpoints: [],
      },
      videoPlayer: null,
      currentSprites: {
        flute: "",
        feather: "",
      },
      spriteData: {
        flute: {},
        feather: {},
      },
      images: {
        flute: new Image(),
        feather: new Image(),
      },
      poseChanges: [],
      animationInterval: null,
      scaleFactor: 0.3,
      qusFeather: null,
      activeAnimations: {
        feather: null,
        flute: null,
      },
      player: null,
    };
  },
    computed: {
    deviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "Desktop";
    }
  },
  async mounted() {
    this.updatePageTitle();
    this.noSleep = new NoSleep(); // Initialize NoSleep
    await this.fetchVideoUrl(); // Load the video URL initially
    this.endOptionSrc = false;
    this.videoPlayer = this.$refs.videoPlayer;
    this.loadJsonConfig();
    //this.syncSprites();
    this.videoPlayer.addEventListener("pause", this.stopAnimation);
    this.videoPlayer.addEventListener("play", this.syncSprites);
    //this.videoPlayer.addEventListener("pause", this.syncSprites); // Sync sprites when paused
    this.loadingStateImg.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.loadingStateImgResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    // loader background audio
    this.audioFiles.map((audioFile) => {
      return axios
        .get(audioFile, { responseType: "blob" })
        .then((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            const audioUrl = reader.result;
            if (audioUrl) {
              this.audioResponse.push(audioUrl);
            }
          };
        })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  watch: {
    presentation(newVal) {
      if (newVal) this.updatePageTitle();
    },
    qusAndAnswer(newVal) {
      if (newVal) this.updatePageTitle();
    },
    assignment(newVal) {
      if (newVal) this.updatePageTitle();
    },
  },
  methods: {
    loadJsonConfig() {
      // Since you're using hardcoded data for now, simply set videoData directly
      this.poseChanges = this.videoData.animation_checkpoints; // Initialize pose changes
      this.initializeDefaultImages();
    },
    topicDetailsBack() {
      this.isNavigatingAway = true;
      localStorage.removeItem("topicDetails");
      localStorage.removeItem("isRefreshed");
      this.refreshSession();
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Navigation away"); // Cancel the API request
      }
      this.$router.push("/home").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    async selectedTab(tab) {
      this.presentation = tab === "Learn";
      this.qusAndAnswer = tab === "Questions";
      this.assignment = tab === "Quiz";
      this.isPlaying = false;

      if (tab === "Learn") {
        // this.isVideoLoading = true; // Show loader when switching to Learn tab
        this.refreshSession();
        this.resetPresentationTab();
        await this.fetchVideoUrl(); // Fetch video URL again to reload the video
        this.videoPlayer = this.$refs.videoPlayer;
        this.loadJsonConfig();
        //this.syncSprites();
        this.videoPlayer.addEventListener("pause", this.stopAnimation);
        this.videoPlayer.addEventListener("play", this.syncSprites);
        //this.videoPlayer.addEventListener("pause", this.syncSprites); // Sync sprites when paused
      } else if (tab === "Questions") {
        this.qusAndAnswer = true;
        this.refreshSession();
        this.resetQuestionTab();
        this.checkMicrophoneAccess();
        this.sendRecordingToApi();
        this.qusFeather = JSON.parse(localStorage.getItem("spriteData"));
      } else if (tab === "Quiz") {
        this.assignment = true;
        this.refreshSession();
        this.restQuizTab();
        this.checkMicrophoneAccess();
        this.sendRecordingToApi();
        this.qusFeather = JSON.parse(localStorage.getItem("spriteData"));
      }
    },
    async fetchVideoUrl() {
      this.isVideoLoading = true;
      const dataTosend = {
        subject: this.subjectName,
        category: this.categoryName,
        topic: this.topicName,
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_TOPIC_DETAILS_URL_PATH,
          dataTosend
        );

        if (
          response &&
          response.data &&
          response.data.video_url &&
          response.data.animation_checkpoints
        ) {
          this.videoData.animation_checkpoints =
            response.data.animation_checkpoints;
          this.videoUrl = response.data.video_url;
          // this.videoUrl = 'https://storageprodfnf.blob.core.windows.net/fnf-prod-content/artandmusic_music_musicalinstruments_2024_09_29/artandmusic_music_musicalinstruments_2024_09_29.m3u8';
          if (response.data.thumbnail_image) {
            this.posterUrl = response.data.thumbnail_image;
          }
          console.log('this.deviceType', this.deviceType);
          if (this.deviceType === "Desktop") {
      this.player = videojs(this.$refs.videoPlayer, {
    autoplay: false,
    playsinline: true,
    sources: [{
      src: this.videoUrl, // Ensure videoUrl points to your .m3u8 file
      type: 'application/x-mpegURL'
    }],
    html5: {
      vhs: {
        overrideNative: true, // Enables fallback to Video.js handling
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    },
  });
          }
          localStorage.setItem('spriteData', JSON.stringify(response.data));
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            text: response?.data?.message || "Video URL not found.",
          });
        }
      } catch (error) {
        console.error("Error fetching video URL", error);
      }
    },
    initializeDefaultImages() {
      const characterActions = new Set();
      this.videoData.animation_checkpoints.forEach((entry) => {
        const characterName = entry.name.toLowerCase();
        const actionName = entry.action.toLowerCase();
        characterActions.add({ name: characterName, action: actionName });
      });

      characterActions.forEach(({ name, action }) => {
        const checkpoint = this.videoData.animation_checkpoints.find(
          (entry) =>
            entry.name.toLowerCase() === name &&
            entry.action.toLowerCase() === action
        );
        //console.log("checkpoint",checkpoint);
        if (checkpoint) {
          this.currentSprites[name] = checkpoint.png_url;
          this.loadSpriteData(name, checkpoint.action);
        }
      });
    },

    // Function to load character sprite data
    async loadSpriteData(character, pose) {
      const checkpoint = this.videoData.animation_checkpoints.find(
        (entry) =>
          entry.name.toLowerCase() === character.toLowerCase() &&
          entry.action.toLowerCase() === pose.toLowerCase()
      );

      if (!checkpoint) {
        console.error(
          `No matching data found for character: ${character}, pose: ${pose}`
        );
        return;
      }

      try {
        const spriteData = await this.fetchPoseJson(checkpoint.json_url);
        const image = new Image();
        image.src = checkpoint.png_url;

        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });

        return { spriteData, image };
      } catch (error) {
        console.error("Error loading sprite data:", error);
      }
    },

    // Load sprite JSON and image data for a given character and pose
    async loadQuestionSpriteData(character, state) {
      //console.log('character =', character, 'state =', state)
      try {
        // Get the correct JSON URL based on the character's state (Listening or Speaking)
        const spriteMetadataUrl =
          this.qusFeather.action_sprite_metadata_map[character][state];
        if (!spriteMetadataUrl) {
          throw new Error(`Metadata URL not found for state: ${state}`);
        }

        // Fetch the sprite data JSON
        const spriteData = await this.fetchPoseJson(spriteMetadataUrl);

        // Update the sprite with the loaded image URL
        const imageUrl = this.qusFeather.action_sprite_map[character][state]; // Get the image URL for the given state
        this.currentSprites[character] = imageUrl;

        const image = new Image();
        image.src = imageUrl;

        // Wait for the image to load before starting the animation
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });

        // Start the animation with the loaded sprite data and image
        this.startAnimation(character, spriteData, image, state);
      } catch (error) {
        console.error("Error loading sprite data:", error);
      }
    },

    startAnimation(character, spriteData, image, state) {
      const frames = Object.keys(spriteData.frames);
      const element =
        character.toLowerCase() === "feather"
          ? this.$refs.featherImage
          : this.$refs.fluteImage;

      // Clear any existing animation interval if it exists
      if (this.animationInterval) clearInterval(this.animationInterval);

      // Run the animation loop with dynamic timing based on the state
      this.runAnimationLoop(frames, spriteData, image, element, state);
    },

    runAnimationLoop(frames, spriteData, image, element, state) {
      let frameIndex = 0;
      const frameCount = frames.length;
      // Set dynamic frame duration based on state (faster for speaking, slower for listening)
      const baseDuration = state === "Speaking" ? 50 : 50; // Adjust values as needed

      // Main loop for frame animation with responsive scaling
      this.animationInterval = setInterval(() => {
        if (!this.isPlaying && this.presentation) {
          clearInterval(this.animationInterval);
          return; // Stop animation if video is paused
        }

        const frameKey = frames[frameIndex];
        const frameData = spriteData.frames[frameKey].frame;

        const container = this.$refs.featheranimationContainer;
        const stopContainer = this.$refs.featherStopAnimationContainer;
        if (container) {
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 932
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.3; // Default scale for larger screens
        }
        if (stopContainer) {
          const containerWidth = container.offsetWidth;
          const containerHeight = "80";
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 932
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.3; // Default scale for larger screens
        }
        // Update element's background and size for the current frame
        element.style.backgroundImage = `url(${image.src})`;
        element.style.backgroundPosition = `-${
          frameData.x * this.scaleFactor
        }px -${frameData.y * this.scaleFactor}px`;
        element.style.width = `${frameData.w * this.scaleFactor}px`;
        element.style.height = `${frameData.h * this.scaleFactor}px`;
        element.style.backgroundSize = `${image.width * this.scaleFactor}px ${
          image.height * this.scaleFactor
        }px`;

        // Cycle through frames
        frameIndex = (frameIndex + 1) % frameCount;
      }, baseDuration);
    },
    async fetchPoseJson(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch JSON: ${response.statusText}`);
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching JSON:", error);
        return null;
      }
    },

    syncSprites() {
      const video = this.$refs.videoPlayer;
      if (!video) return;
      video.addEventListener("timeupdate", () => {
        const currentTimeSeconds = video.currentTime;

        this.videoData.animation_checkpoints.forEach((checkpoint, index) => {
          const [minutes, seconds] = checkpoint.timestamp_minutes_seconds
            .split(":")
            .map(Number);
          const checkpointTime = minutes * 60 + seconds;

          // Add a small buffer to account for video timing precision issues
          if (Math.abs(currentTimeSeconds - checkpointTime) < 0.5) {
            const character = checkpoint.name.toLowerCase();
            const action = checkpoint.action.toLowerCase();

            //console.log(`Checkpoint reached for ${character} to perform action: ${action} at ${checkpointTime}s`);

            // Cancel any existing animation for the character before starting a new one
            if (this.activeAnimations[character]) {
              cancelAnimationFrame(this.activeAnimations[character]);
              this.activeAnimations[character] = null;
              //console.log(`Previous animation canceled for ${character}`);
            }

            this.loadSpriteData(character, action)
              .then(({ spriteData, image }) => {
                const nextCheckpointTime = this.getNextCheckpointTime(index);
                this.activeAnimations[character] = this.runSmoothAnimation(
                  character,
                  spriteData,
                  image,
                  this.$refs[`${character}Image`],
                  checkpointTime,
                  nextCheckpointTime,
                  video,
                  action
                );
              })
              .catch((error) =>
                console.error(
                  `Failed to load sprite data for ${character}: ${error.message}`
                )
              );
          }
        });
      });
    },

    getNextCheckpointTime(index) {
      // Get time of next checkpoint to calculate duration for the animation
      if (index < this.videoData.animation_checkpoints.length - 1) {
        const [nextMinutes, nextSeconds] = this.videoData.animation_checkpoints[
          index + 1
        ].timestamp_minutes_seconds
          .split(":")
          .map(Number);
        return nextMinutes * 60 + nextSeconds;
      }
      return null; // No next checkpoint, so animation can end here
    },

    runSmoothAnimation(
      character,
      spriteData,
      image,
      element,
      startTime,
      endTime,
      video,
      action
    ) {
      const frames = Object.keys(spriteData.frames);
      const totalDuration = endTime ? endTime - startTime : 1;
      const frameCount = frames.length;

      const animate = () => {
        const currentTime = video.currentTime;

        // Determine if in "speaking" interval
        const inSpeakingInterval =
          currentTime >= startTime && (endTime ? currentTime < endTime : true);

        // Calculate frame for current animation (speaking or listening)
        const elapsed = inSpeakingInterval
          ? currentTime - startTime
          : currentTime % 2; // For listening, loop every 2 seconds
        const activeDuration = inSpeakingInterval ? totalDuration : 2; // Listening duration set to 2 seconds for a loop
        const frameIndex =
          Math.floor((elapsed / activeDuration) * frameCount) % frameCount;
        const frameKey = frames[frameIndex];
        const frameData = spriteData.frames[frameKey].frame;

        const container = this.$refs.animationContainer; // Assuming you have a container ref for responsive scaling
        if (container) {
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;

          // Define the base size of the animation
          const baseWidth = frameData.w || 513; // Replace with the actual width of your sprite frames
          const baseHeight = frameData.h || 411; // Replace with the actual height of your sprite frames

          // Check screen width and apply scaling only for small screens (e.g., less than 768px)
          if (window.innerWidth <= 932) {
            // Calculate the scale factor to fit within the container while maintaining aspect ratio
            this.scaleFactor = Math.min(
              containerWidth / baseWidth,
              containerHeight / baseHeight
            );
          } else {
            this.scaleFactor = 0.3; // Default scale factor for larger screens
          }
        }
        element.style.backgroundImage = `url(${image.src})`;
        element.style.backgroundPosition = `-${
          frameData.x * this.scaleFactor
        }px -${frameData.y * this.scaleFactor}px`;
        element.style.width = `${frameData.w * this.scaleFactor}px`;
        element.style.height = `${frameData.h * this.scaleFactor}px`;
        element.style.backgroundSize = `${image.width * this.scaleFactor}px ${
          image.height * this.scaleFactor
        }px`;

        // Schedule next frame update
        this.activeAnimations[character] = requestAnimationFrame(animate);

        // If interval ends, transition back to listening
        if (!inSpeakingInterval) {
          const nextInterval = this.getNextAnimationInterval(
            character,
            action,
            currentTime
          );
          if (nextInterval) {
            cancelAnimationFrame(this.activeAnimations[character]);
            const { nextStartTime, nextEndTime } = nextInterval;
            this.runSmoothAnimation(
              character,
              spriteData,
              image,
              element,
              nextStartTime,
              nextEndTime,
              video,
              action
            );
          }
        }
      };

      // Start animation immediately, with listening animation until speaking interval starts
      requestAnimationFrame(animate);
    },

    // Helper function to find the next animation interval for "feather"
    getNextAnimationInterval(character, action, currentTime) {
      const nextCheckpoint = this.videoData.animation_checkpoints.find(
        (entry) =>
          entry.name.toLowerCase() === character.toLowerCase() &&
          entry.action.toLowerCase() === action.toLowerCase() &&
          entry.startTime > currentTime
      );

      if (nextCheckpoint) {
        return {
          nextStartTime: nextCheckpoint.startTime,
          nextEndTime: nextCheckpoint.endTime,
        };
      }

      return null;
    },
    clearPreviousAnimation() {
      if (this.animationInterval) {
        clearInterval(this.animationInterval);
        this.animationInterval = null; // Clear the reference to the interval
      }
    },
    stopAnimation() {
      if (this.animationInterval) {
        console.log("Stopping animation");
        clearInterval(this.animationInterval);
        this.animationInterval = null;
      } else {
        console.log("No animation to stop");
      }
    },

      shouldDisableTab(tab) {
      // Disable tabs based on the current active tab and the sequential order
      if (this.presentation) return tab !== "Learn"; // If in "Learn" tab, disable other tabs
      if (this.qusAndAnswer) return tab === "Quiz"; // If in "Questions" tab, disable "Quiz" tab
      if (this.assignment) return tab === "Questions"; // If in "Quiz" tab, disable "Questions" tab
      return false;
    },

    isActiveTab(tab) {
      return (
        (tab === "Learn" && this.presentation) ||
        (tab === "Questions" && this.qusAndAnswer) ||
        (tab === "Quiz" && this.assignment)
      );
    },
    isQuestionsTab(tab) {
      return tab === "Questions";
    },
    isQuizTab(tab) {
      return tab === "Quiz";
    },
    getActiveTopic() {
      if (this.presentation) return "Learn";
      if (this.qusAndAnswer) return "Questions";
      if (this.assignment) return "Quiz";
      return "";
    },
    updatePageTitle() {
      const activeTopic = this.getActiveTopic();
      updateTitle(activeTopic);
    },
    togglePlayPause() {
      const video = this.$refs.videoPlayer;
      if (video.paused) {
        video.play();
        this.isPlaying = true;
      } else {
        video.pause();
        this.isPlaying = false;
      }
    },
    handleVideoEnd() {
      this.presentation = false;
      this.qusAndAnswer = true;
      this.assignment = false;
      this.isRecording = false;
      this.audioLoaded = false;
      this.afterIsRecording = false;
      this.isNavigatingAway = false;
      (this.isQustionAndAnswerPaused = false), this.checkMicrophoneAccess();
    },
    resetPresentationTab() {
      this.isRecording = false;
      this.afterIsRecording = false;
      this.isNavigatingAway = true;
      this.clickQusQuizTab = false;
      this.clickQuestion = false;
      this.optionText = "";
      this.endOptionText = "";
      this.learnAudioSrc = "";
      this.endOptionSrc = "";
      this.scrollableText = "";
      this.audioLoaded = false;
      this.endQuizMsg = "";
      this.showEndTopicMsg = false;
      this.listeningText = "Click the microphone to speak";
      const audioElement = document.getElementById("learn-audio-player");
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Navigation away"); // Cancel the API request
      }
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.mediaRecorder = null;
      }

      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
        this.mediaStream = null;
      }
    },
    resetQuestionTab() {
      this.isRecording = false;
      this.clickQusQuizTab = true;
      this.clickQuestion = true;
      this.afterIsRecording = false;
      this.isQustionAndAnswerPaused = false;
      this.scrollableText = "";
      this.audioLoaded = false;
      this.isNavigatingAway = false;
      this.isLoading = false;
      this.mediaStream = null;
      this.mediaRecorder = null;
      this.chunks = []; // Reset recorded chunks array
      this.optionText = "";
      this.endOptionText = "";
      this.endQuizMsg = "";
      this.learnAudioSrc = "";
      this.endOptionSrc = "";
      this.showEndTopicMsg = false;
      this.listeningText = "Click the microphone to speak";
    },
    restQuizTab() {
      this.isNavigatingAway = false;
      this.clickQusQuizTab = true;
      this.clickQuestion = false;
      this.scrollableText = "";
      this.optionText = "";
      this.endQuizMsg = "";
      this.endOptionText = "";
      (this.textOptionChunks = []),
        (this.isQustionAndAnswerPaused = false),
        (this.learnAudioSrc = "");
      this.endOptionSrc = "";
      this.showEndTopicMsg = false;
      const audioElement = document.getElementById("learn-audio-player");
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
      }
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Navigation away"); // Cancel the API request
      }
    },
    async checkMicrophoneAccess() {
      try {
        const constraints = { audio: true };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaStream = stream;
        this.isMicrophoneAllowed = true;
      } catch (error) {
        this.isMicrophoneAllowed = false;
        this.isMicrophoneBrowserAllowed = true;
      }
    },
    toggleRecording() {
      if (!this.isRecording && !this.afterIsRecording) {
        this.startRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
      } else if (this.afterIsRecording) {
        this.stopRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
        this.afterIsRecording = false;
      }
    },
    async startRecording() {
      try {
        // if (!this.isRecording && this.isMicrophoneAllowed) {
        this.isRecording = true;
        this.listeningText = "I'm listening";
        this.loadQuestionSpriteData("Feather", "Listening");
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(stream);
        this.chunks = [];
        let silenceStart;

        this.mediaRecorder.start();

        const audioContext = new AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        const processor = audioContext.createScriptProcessor(512, 1, 1);

        source.connect(processor);
        processor.connect(audioContext.destination);

        // Enable the microphone button after 5 seconds
        setTimeout(() => {
          this.afterIsRecording = true;
          this.listeningText = "Tap below for a response";
          silenceStart = Date.now();
        }, 5000);

        processor.onaudioprocess = (event) => {
          if (
            !this.mediaRecorder ||
            this.mediaRecorder.state !== "recording" ||
            audioContext.state === "closed"
          )
            return;
          if (this.afterIsRecording === true) {
            const input = event.inputBuffer.getChannelData(0);
            const isSilent = input.every((value) => Math.abs(value) < 0.01);

            if (isSilent) {
              if (Date.now() - silenceStart > 2000) {
                this.mediaRecorder.stop();
                stream.getTracks().forEach((track) => track.stop());
                processor.disconnect();
                audioContext.close();
              }
            } else {
              silenceStart = Date.now();
            }
          }
        };

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.chunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.chunks, {
            type: "audio/wav; codecs=0",
          });

          const now = new Date();
          const timestamp =
            now.getFullYear().toString() +
            (now.getMonth() + 1).toString().padStart(2, "0") +
            now.getDate().toString().padStart(2, "0") +
            "_" +
            now.getHours().toString().padStart(2, "0") +
            now.getMinutes().toString().padStart(2, "0") +
            now.getSeconds().toString().padStart(2, "0");

          const filename = "audio_" + timestamp + ".wav";
          const audioFile = new File([audioBlob], filename, {
            type: "audio/wav",
          });
          this.audioFile = audioFile;
          this.sendRecordingToApi(audioFile);
        };
        // }
      } catch (error) {
        console.error("Error sending audio to API:", error);
      }
    },
    stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;
        if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
          // Stop the mediaRecorder
          this.mediaRecorder.stop();
        }
      }
    },
    // Handle the orientation update from the child component
    updateOrientation(isPortrait) {
      this.isPortrait = isPortrait;
      // Check if the audio element exists
      const audioElement = document.getElementById("learn-audio-player");

      if (audioElement.getAttribute("src")) {
        if (!this.isPortrait && !this.isQustionAndAnswerPaused) {
          // If the device is in landscape mode, play the audio
          audioElement.play();
          this.autoScrollText();
        } else {
          // If the device is in portrait mode, pause the audio
          audioElement.pause();
          this.stopScrolling();
        }
      }
    },
    stopScrolling() {
      console.log("Stopping scrolling...");
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Stop the interval-based scrolling
        this.scrollInterval = null;
      }

      // Cancel the ongoing animation frame if there's any active scroll
      if (this.scrollAnimationFrame) {
        cancelAnimationFrame(this.scrollAnimationFrame);
        this.scrollAnimationFrame = null;
      }
    },
    autoScrollText() {
      const container = this.$refs.scrollContainer;

      if (!container || !(container instanceof Element)) {
        console.error("Invalid container element for scrolling.");
        return;
      }

      const scrollDelay = 6000; // Delay between scrolls (in ms)
      const scrollDuration = 1000; // Time in ms for one scroll transition
      const initialDelay = 6000; // Initial delay for the first line (in ms)
      const lineHeight = parseFloat(getComputedStyle(container).lineHeight);

      let startTime = null; // Initialize start time

      // Function to smoothly scroll
      const smoothScroll = (timestamp) => {
        // If the audio is paused, stop scrolling
        const audioElement = document.getElementById("learn-audio-player");
        if (audioElement && audioElement.paused) {
          return; // Exit if audio is paused
        }

        if (!startTime) startTime = timestamp; // Set the start time when function is first called
        const elapsed = timestamp - startTime; // Calculate time elapsed

        const progress = Math.min(elapsed / scrollDuration, 1); // Ensure progress doesn't exceed 1
        const scrollAmount = this.scrollAmount + progress * lineHeight; // Calculate the scroll amount

        container.scrollTop = scrollAmount; // Set the scrollTop position

        // Continue scrolling if progress < 1
        if (progress < 1) {
          this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Continue smooth scrolling
        } else {
          this.scrollAmount += lineHeight; // Update scroll amount after one complete line is scrolled

          // Stop if the bottom is reached
          if (
            this.scrollAmount >=
            container.scrollHeight - container.clientHeight
          ) {
            clearInterval(this.scrollInterval); // Stop scrolling at the bottom
          } else {
            // Pause for the scrollDelay before starting the next scroll
            setTimeout(() => {
              startTime = null; // Reset start time for the next scroll
              this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start next smooth scroll
            }, scrollDelay);
          }
        }
      };
      // Start with an initial delay to allow users to read the first line
      setTimeout(() => {
        this.scrollAmount = 0; // Ensure the scroll amount starts from the top
        this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start the smooth scrolling
      }, initialDelay);
    },
    sendRecordingToApi(audioFile) {
      console.log("inside");
      // Append the audio file with the desired filename to the FormData object
      if (this.isNavigatingAway) {
        return;
      }
      console.log("inside2");
      this.cancelTokenSource = axios.CancelToken.source();
      this.isLoading = true;
      this.afterIsRecording = false;
      this.audioLoaded = false;
      this.$store.dispatch("updateIsAudioResponse", "");
      this.listeningText = "";
      const formData = new FormData();
      if (audioFile) {
        formData.append("audioFile", audioFile);
      } else {
        const now = new Date();
        const timestamp =
          now.getFullYear().toString() +
          (now.getMonth() + 1).toString().padStart(2, "0") +
          now.getDate().toString().padStart(2, "0") +
          "_" +
          now.getHours().toString().padStart(2, "0") +
          now.getMinutes().toString().padStart(2, "0") +
          now.getSeconds().toString().padStart(2, "0");

        const filename = "audio_" + timestamp + ".wav";

        // Create an empty Blob
        const emptyBlob = new Blob([], { type: "audio/wav" });

        // Create a File from the empty Blob with the desired filename
        const emptyFile = new File([emptyBlob], filename, {
          type: "audio/wav",
        });

        // Append the empty file with timestamp to FormData
        formData.append("audioFile", emptyFile);
        formData.append("audio_flag", this.audio_flag);
      }
      formData.append("user_email", this.userEmail);
      formData.append("topic", this.topicName);
      formData.append("session_state", this.qusAndAnswer ? "QnA" : "Quiz");
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_TRANSCRIBE_DOMAIN_URL_PATH,
          formData,
          { cancelToken: this.cancelTokenSource.token }
        )
        .then((response) => {
          console.log("response", response);
          if (response && response.data && response.data.audio_url) {
            // setTimeout(() => {
            if (response.data.stage_flag) {
              this.stageApi();
            }
            this.endQuizFlag = response.data.end_topic_flag;
            this.endQuizMsg = response.data.end_topic_msg;
            this.learnAudioSrc = response.data.audio_url;
            this.endOptionUrl = response.data.end_topic_url;
            this.scrollableText = response.data.llm_response;
            console.log("scrollableText", this.scrollableText);
            this.optionText = this.formatLLMResponse(this.scrollableText);
            console.log("optionaltext", this.optionText);
            // Ensure DOM is updated before accessing the audio element
            this.$nextTick(() => {
              this.learn_audio_player =
                document.getElementById("learn-audio-player");
              if (this.learn_audio_player) {
                this.learn_audio_player.src = this.learnAudioSrc;
                this.learn_audio_player.volume = 1.0;
                this.learn_audio_player.onloadedmetadata = () => {
                  this.isLoading = false;
                  this.audioLoaded = true;
                  this.clickQusQuizTab = false;
                  this.$store.dispatch("updateFunToipcLodingState", "");
                  this.$store.dispatch("updateIsAudioResponse", true);
                  this.$nextTick(() => {
                    const container = document.querySelector(
                      ".text-scroller-container"
                    );
                    if (container) {
                      // Reset scroll amount to the top for each new response
                      container.scrollTo({
                        top: 0, // Reset to the top
                        behavior: "auto", // No smooth behavior when resetting
                      });
                      this.scrollAmount = 0; // Reset scrollAmount to 0

                      // Ensure any existing scroll interval is cleared
                      clearInterval(this.interval);
                      // Split the formatted text into chunks and start scrolling for Question
                      if (this.scrollableText && this.qusAndAnswer) {
                        if (!this.isPortrait) {
                          this.autoScrollText(); // Start auto-scroll for Question
                        }
                      }
                      if (this.optionText && this.assignment) {
                        this.autoScrollText();
                      }
                    }
                  });

                  if (this.noSleep) {
                    this.noSleep.enable();
                  }

                  // Only play the audio if the device is already in landscape mode
                  if (!this.isPortrait) {
                    this.learn_audio_player.play();
                    this.loadQuestionSpriteData("Feather", "Speaking");
                  } else {
                    this.learn_audio_player.pause();
                  }
                };
              } else {
                this.$store.dispatch("updateFunToipcLodingState", "");
                this.$store.dispatch("updateIsAudioResponse", "");
                this.isLoading = false;
                this.clickQusQuizTab = false;
                this.audioLoaded = false;
              }
            });
            // }, 2000); // 2000 milliseconds = 2 seconds
          }
        })
        .catch((error) => {
          this.isRecording = false;
          this.audioLoaded = false;
          this.clickQusQuizTab = false;
          this.afterIsRecording = false;
          this.isLoading = false;
          this.listeningText = "Click the microphone to speak";
          if (axios.isCancel(error)) {
            console.log("Request canceled:", error.message);
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              text:
                "Something went wrong. Please try again." ||
                error.response.data.message,
            });
          }
        });
    },
    stageApi() {
      const formData = new FormData();
      formData.append("user_email", this.userEmail);
      formData.append("topic", this.topicName);
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_SESSION_STAGE_URL_PATH,
          formData
        )
        .then((res) => {
          this.session_type = res && res.data && res.data.session_type;
          if (res && res.data && res.data.session_type === "quiz") {
            this.presentation = false;
            this.qusAndAnswer = false;
            this.assignment = true;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // Reset scrolling-related variables before handling a new response
    resetScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Clear any existing scroll interval
        this.scrollInterval = null;
      }
    },
    audioEnded() {
      this.resetScrolling();
      this.isRecording = false;
      this.audioLoaded = false;
      this.afterIsRecording = false;
      this.clickQusQuizTab = false;
      this.clickQuestion = false;
      this.learnAudioSrc = "";
      this.listeningText = "Click the microphone to speak";
      this.loadQuestionSpriteData("Feather", "Listening");
      // this.scrollableText = null;
      if (this.noSleep) {
        this.noSleep.disable();
      }
      this.stageApi();
      if (this.endQuizFlag) {
        this.showEndTopicMsg = true;
        this.endOptionText = this.formatLLMResponse(this.endQuizMsg);
        console.log("endOptionText", this.endOptionText);
        console.log("endOptionUrl", this.endOptionUrl);
        // this.endOptionSrc = this.endOptionUrl;
        console.log("endOptionSrc", this.endOptionSrc);
        this.listeningText = "Click the microphone";
      }
    },
    playCelebrationAudioOnClick() {
      console.log("inside");
      this.endOptionSrc = this.endOptionUrl;
      if (this.showEndTopicMsg && this.endOptionSrc && !this.isPortrait) {
        console.log("inside1");
        const audioPlayer = document.getElementById("learn-audio-player");
        audioPlayer.play();
      }
    },
    handleCanPlay() {
      console.log("loading video");
      this.isVideoLoading = false; // Remove loader once video can play
    },
    handleLoadedMetadata() {
      console.log("Metadata loaded");
      this.isVideoLoading = false; // Hide loader when metadata is available
    },
    refreshSession() {
      let dataTosend = {};
      dataTosend.user_email = this.userEmail;
      dataTosend.topic = this.topicName;
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_REFRESH_SESSION_URL_PATH,
          dataTosend
        )
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    stopLLMAudio() {
      const audioElement = document.getElementById("learn-audio-player");
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
        this.audioLoaded = false;
        this.learnAudioSrc = "";
        this.afterIsRecording = false;
        this.isQustionAndAnswerPaused = false;
        this.clickQusQuizTab = false;
        this.isRecording = true;
        this.listeningText = "I'm listening";
        // this.scrollableText = null;
        // this.scrollableText = "";
        // this.endQuizMsg = "";
        if (this.noSleep) {
          this.noSleep.disable();
        }
        this.stageApi();
        this.startRecording();
        this.resetScrolling();
      } else {
        console.error("Audio element not found");
      }
    },
    playLLMAudio() {
      this.$nextTick(() => {
        const audioElement = document.getElementById("learn-audio-player");
        if (audioElement) {
          this.loadQuestionSpriteData("Feather", "Speaking");
          audioElement.play();
          audioElement.volume = 1.0;
          this.isQustionAndAnswerPaused = false;
          // Resume scrolling
          // Reset scroll amount to current scroll position to avoid duplicate scrolling
          this.scrollAmount = this.$refs.scrollContainer.scrollTo;
          // Clear existing interval before starting a new one
          clearInterval(this.interval);
          this.autoScrollText();
          if (this.noSleep) {
            this.noSleep.enable();
          }
        } else {
          console.error("Audio element not found");
        }
      });
    },
    pauseLLMAudio() {
      const audioElement = document.getElementById("learn-audio-player");
      if (audioElement) {
        if (this.noSleep) {
          this.noSleep.disable();
        }
        audioElement.pause();
        clearInterval(this.animationInterval);
        this.isQustionAndAnswerPaused = true;
        // Stop scrolling
        clearInterval(this.scrollInterval);
      } else {
        console.error("Audio element not found");
      }
    },
    handlePopState() {
      this.stopLLMAudio();
      this.refreshSession();
      this.isNavigatingAway = true;
      localStorage.removeItem("isRefreshed");
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Navigation away"); // Cancel the API request
      }
      if (this.$route.path !== "/topic") {
        this.$router.push("/topic").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            throw err;
          }
        });
      }
    },
    // Method to format LLM response with labeled options
    formatLLMResponse(response) {
      let lines = response.split("\n");
      let formattedLines = [];
      let optionCounter = 0;
      let optionLabels = ["a", "b", "c", "d", "e"]; // Adjust as needed

      lines.forEach((line) => {
        // Trim the line to check for empty content
        line = line.trim();
        if (line) {
          // Only process non-empty lines
          if (line.startsWith("-")) {
            formattedLines.push(
              `${optionLabels[optionCounter]}. ${line.substring(2)}`
            );
            optionCounter++;
          } else {
            formattedLines.push(line);
          }
        }
      });

      return formattedLines.join("\n");
    },
    beforeDestroy() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      this.stopLLMAudio();
      const audioElement = document.getElementById("learn-audio-player");
      if (audioElement) {
        audioElement.removeEventListener("ended", this.audioEnded);
      }
      // the audio will stop playing when the user navigates away from the page or clicks the back button on both desktop and mobile device
      window.removeEventListener("popstate", this.handlePopState);
      clearInterval(this.interval);
      if (this.player) {
        this.player.dispose();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/color.scss";
.character-animation {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}
.feather-animation {
  position: absolute;
  bottom: 0px;
  left: -25px;
}
.flute-animation {
  position: absolute;
  bottom: 0px;
  right: -25px;
}
.tab-container {
  // background-color: $primary-color;
  margin-top: 5px !important;
  width: 100%;
  //   min-height: 500px;
  border-radius: 0px 0px 15px 15px;
  padding: 20px;
}

.tabs {
  display: flex;
}

.tab-item {
  margin-right: 2px;
}

.tab-button {
  position: relative;
  z-index: 999;
  // background-color: $tab-inactive-color;
  border-radius: 10px 10px 0 0;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  color: #32323280;
  background-color: $qus-chat-body-color;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.25s;
  box-shadow: 7px 0px 15px 0px #00000026;
  @media all and (min-width: 600px) {
    min-width: 160px;
  }
}

.tab-button.disabled-tab {
  pointer-events: none; /* Disable any interaction */
  cursor: not-allowed; /* Show 'not allowed' cursor when disabled */
  background-color: $qus-chat-body-color;
  // z-index: 997;
}

.tab-button.disabled-tab:hover {
  color: inherit !important; /* Prevent hover color change */
}

.question-tab {
  position: relative;
  right: 20px;
}
.quiz-tab {
  position: relative;
  right: 40px;
  z-index: 998;
}

.tab-button.active {
  // background-color: darken($tab-active-color, 15%);
  color: $black-color;
  z-index: 1000;
}

.tab-button:hover {
  // background-color: darken($tab-active-color, 15%);
  border-radius: 10px 10px 0 0;
  border: 2px solid #ffffff;
  color: $black-color;
  //   font-size: 16px;
  z-index: 1001;
}

@media (max-width: 768px) {
  .tab-button {
    width: 100%; /* Makes the buttons full width on mobile */
  }
}
.content-container {
  background-color: $white-color;
  min-height: 435px;
  border: 5px solid $white-color;
  border-radius: 0px 15px 15px 15px;
  padding: 0; /* Ensure no padding in the container */
}

.video-container {
  height: 400px;
  aspect-ratio: 16/9;
  // width: 100%;
  max-width: 100%;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  border-radius: 15px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Ensure the video fills the space without distortion */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  border-radius: 15px;
}

  .custom-video {
    opacity: 0.99; /* Force a reflow */
    height: 100% !important;
  }

.controls {
  text-align: center;
  position: relative;
  bottom: 71px;
  width: 100%;
}

.question-controls {
  text-align: center;
  position: absolute;
  bottom: -25px;
  left: 0px;
}

.waiting-loader-position {
  display: contents;
}

.playNpause {
  width: 6rem;
  height: 6rem;
}

.stopButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 30px;
  bottom: 25px;
}

.fnff-peacock-left {
  width: 10rem;
  height: 8rem;
  position: absolute;
  bottom: 0px;
  left: -25px;
}
.fnff-peacock-right {
  width: 10rem;
  height: 8rem;
  position: absolute;
  bottom: 0px;
  right: -25px;
}

.question-container {
  background-color: $qus-chat-body-color;
  height: 400px;
  padding: 20px 50px 10px 50px;
  border-radius: 10px;
  position: relative;
}

.qustion-body {
  height: 300px;
  border-radius: 10px;
  padding: 20px !important;
  font-size: 20px;
  background-color: $qus-tab-body-color;
}

/* Add blur to the content container, but not the images */
/* Add blur and background color to the content container, but not the images */
.blur-content {
  filter: blur(2px);
  pointer-events: none; /* Disables interaction with content */
  background-color: #f8f6f0; /* Adds the specified color while blurred */
  opacity: 0.8; /* Optional: Adjust opacity to make blur more visible */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.question-play-n-pause {
  width: 6rem;
  height: 6rem;
}

.auth-overlay {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.prompt-block {
  max-width: 700px;
  margin: 30px auto 30px auto;
  max-height: 500px;
  display: inline-block;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  border-radius: 15px;

  h4 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
  }

  > div + div {
    border-top: 1px solid lightgrey;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

.tooltiptext {
  visibility: visible; /* Set to hidden if you want tooltip only on hover */
  min-width: 120px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 998; /* Set higher than the image */
  top: -30%; /* Position the tooltip above the image */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip horizontally */
  margin-bottom: 10px; /* Optional: Space between the tooltip and the image */
}

/* Optional: Show the tooltip only on hover */
.tooltip-container .tooltiptext {
  visibility: hidden; /* Hide by default */
}

.tooltip-container:hover .tooltiptext {
  visibility: visible; /* Show on hover */
}

.loading-image {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.control-stop-button {
  // position: absolute;
  // top: 89%;
  // left: 40%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.control-button {
  // position: absolute;
  // top: 89%;
  // left: 55%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.control-image {
  height: 25px;
}

.text-scroller-container {
  overflow-y: hidden; /* Hide overflowing text */
  line-height: 1.25; /* Adjust line height for better readability */
  height: 260px; /* Height of the visible text area */
  position: relative;
  width: 100%;
  white-space: pre-wrap;
}

.text-scroller {
  transition: transform 1.5s ease-in-out; /* Smooth transition effect */
}

.text-scroller p {
  margin: 0;
}

.dummy-text {
  font-size: 18px; /* Default size */
  line-height: 1.5; /* Adjust line height for better readability */
  margin: 0;
  padding: 0;
  // white-space: normal; /* Allow natural wrapping */
  word-wrap: break-word; /* Ensure long words break properly */
  overflow-wrap: break-word; /* Handle overflow of long words */
  display: block;
  max-width: 100%; /* Ensure it adapts to the screen size */
  box-sizing: border-box; /* Include padding/margins in width */
}

.option-text {
  white-space: pre-line !important;
}

/* Mobile Specific Adjustments */
@media (max-width: 600px) {
  .dummy-text {
    font-size: 16px; /* Slightly smaller for mobile */
    padding: 0 10px; /* Ensure some padding for readability */
  }
}
@media (max-width: 470px) {
  .video-container {
    aspect-ratio: 4/3;
  }
}
@media screen and (orientation: landscape) {
  /* Small mobile landscape (max width 400px) */
  @media (max-width: 400px) {
    .dummy-text {
      font-size: 12px;
      line-height: 21px;
    }
  }

  /* Medium mobile landscape (400px to 600px) */
  @media (min-width: 401px) and (max-width: 600px) {
    .dummy-text {
      font-size: 14px;
      line-height: 21px;
    }
  }

  /* Large mobile/tablet landscape (600px to 768px) */
  @media (min-width: 601px) and (max-width: 811px) {
    .dummy-text {
      font-size: 16px;
      line-height: 21px;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 812px) {
  /* iPhone SE, iPhone 8, iPhone X, XS, 11 Pro, 12 Mini, 13 Mini, etc. */
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .content-container {
    min-height: 260px;
  }
  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .text-scroller-container {
    height: 124px;
  }
  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
  .question-controls {
    bottom: -15px;
  }
  .tooltiptext {
    bottom: 90%;
    top: -40%;
  }
  .stopButton {
    bottom: 10px;
  }
}

/* iPhones with medium widths and heights */
@media screen and (orientation: landscape) and (min-width: 813px) and (max-width: 896px) and (max-height: 414px) {
  /* iPhone X, XR, 11, 11 Pro Max, 12, 13, 14 */

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .content-container {
    min-height: 260px;
  }
  .custom-video {
    opacity: 0.99; /* Force a reflow */
  }

  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .text-scroller-container {
    height: 124px;
  }
  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
  .question-controls {
    bottom: -15px;
  }
  .tooltiptext {
    bottom: 90%;
    top: -40%;
  }
  .stopButton {
    bottom: 10px;
  }
}

/* For larger iPhones in landscape */
@media screen and (orientation: landscape) and (min-width: 897px) and (max-height: 430px) {
  /* iPhone 12 Pro Max, 13 Pro Max, 14 Pro Max, 14 Plus, 15 Plus and larger future devices */

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .content-container {
    min-height: 260px;
  }
  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .text-scroller-container {
    height: 124px;
  }
  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
  .question-controls {
    bottom: -15px;
  }
  .tooltiptext {
    bottom: 90%;
    top: -40%;
  }
  .stopButton {
    bottom: 10px;
  }
}

@media screen and (orientation: landscape) and (min-width: 1000px) {
  .content-container {
    min-height: 435px;
  }
  .video-container {
  height: 400px;
  }
}
</style>
