<template>
  <div class="row m-0 d-flex headerTopic" :style="{ backgroundColor: topicColor }">
    <div class="font-size-18 fw-600 text-capitalize">{{ videoTitle }}</div>
    <div class="d-flex align-items-center backButtonPosition" @click="topicDetailsBack">
      <img class="topicLeftArrow" src="../../assets/Home.png" />
      <span class="pl-1 font-size-12">Home</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      videoTitle: localStorage.getItem("videoTitle"),
      topicColor: localStorage.getItem("topicColor")
    };
  },
  methods: {
    topicDetailsBack() {
      // this.$router.push("/topic");
      // localStorage.removeItem("topicName");
      this.$emit('topicDetailsBack');
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/color.scss";

.headerTopic {
  min-height: 60px;
  // background-color: $primary-color;
  padding: 10px 20px 10px 20px;
  border-radius: 15px 15px 0px 0px;
  align-items: center;
  color: black;
}
.backButtonPosition {
  margin-left: auto;
  cursor: pointer;
}
.topicLeftArrow {
    width: 30px;
    height: 30px;
}
</style>