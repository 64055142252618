<template>
  <div class="container-fluid p-0">
    <div
      class="row mx-0 justify-content-center align-items-center container-min-height pt-5per pb-5per"
    >
      <div class="registerCard">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-20">
          <div class="registerHeader pb-1">Welcome to flute n feather</div>
          <div class="registerSecondaryHeader pb-2">
            Your child’s personal companion
          </div>
          <form>
            <div class="form-group">
              <label class="label"
                >Child's Full name<span class="text-danger"> *</span></label
              >
              <text-field
                id="fullName"
                name="fullName"
                v-model="form_data.fullName"
                placeholder="Enter your child's name"
                :valid="!$v.form_data.fullName.$error"
                :errorMessage="
                  isSubmitted && !$v.form_data.fullName.required
                    ? 'Full Name is required'
                    : !$v.form_data.fullName.regex
                    ? 'Only alphabetic characters and spaces are allowed'
                    : ''
                "
              />
            </div>
            <div class="form-group">
              <div class="row m-0">
                <div
                  class="col-12 col-sm-12 col-md-6 col-xl-6 p-0 ageResponsive"
                >
                  <label class="label"
                    >Child's Age<span class="text-danger"> *</span></label
                  >
                  <v-select
                    v-model="form_data.age"
                    class="custom-v-select"
                    placeholder="Choose"
                    :class="{ 'is-invalid': !$v.form_data.age.$error }"
                    :searchable="false"
                    :clearable="false"
                    :options="ageArr"
                  />
                  <span
                    v-if="isSubmitted && !$v.form_data.age.required"
                    class="error"
                    >Age is required</span
                  >
                </div>
                <div
                  class="col-12 col-sm-12 col-md-6 col-xl-6 p-0 genderResponsive"
                >
                  <label class="label"
                    >Child's Gender<span class="text-danger"> *</span></label
                  >
                  <v-select
                    v-model="form_data.gender"
                    class="custom-v-select"
                    placeholder="Choose"
                    :class="{ 'is-invalid': !$v.form_data.gender.$error }"
                    :searchable="false"
                    :clearable="false"
                    :options="genderArr"
                  />
                  <span
                    v-if="isSubmitted && !$v.form_data.gender.required"
                    class="error"
                    >Gender is required</span
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="label"
                >Email Address<span class="text-danger"> *</span></label
              >
              <text-field
                id="email"
                name="email"
                v-model="form_data.email"
                placeholder="Enter your email address"
                :valid="!$v.form_data.email.$error"
                :errorMessage="
                  isSubmitted && !$v.form_data.email.required
                    ? 'Email is required'
                    : !$v.form_data.email.email
                    ? 'Please enter a valid email address'
                    : ''
                "
              />
            </div>
            <div class="form-group position-relative">
              <label class="label">Phone number(Optional)</label>
              <span class="country-code px-2">+1</span>
              <text-field
                id="mobileNumber"
                name="mobileNumber"
                v-model="form_data.mobileNumber"
                placeholder="89999990"
                :maxLength="10"
                type="text"
                className="textIndent"
                :valid="!$v.form_data.mobileNumber.$error"
                :errorMessage="
                  isSubmitted && !$v.form_data.mobileNumber.isValidPhoneNumber
                    ? 'Please enter a valid phone number'
                    : ''
                "
              />
            </div>
            <div class="form-group">
              <label class="label"
                >Choose password<span class="text-danger"> *</span></label
              >
              <div class="position-relative">
                <text-field
                  id="password"
                  name="password"
                  v-model="form_data.password"
                  placeholder="Enter password"
                  :type="showPassword ? 'text' : 'password'"
                  :valid="!$v.form_data.password.$error"
                  :errorMessage="
                    isSubmitted && !$v.form_data.password.required
                      ? 'Password is required'
                      : ''
                  "
                />
                <button
                  class="password-toggle"
                  @click="togglePasswordVisibility"
                >
                  <img
                    v-if="showPassword"
                    class="showPassword"
                    src="../assets/eye-filled.png"
                    alt="Show Password"
                  />
                  <img v-else src="../assets/hide.png" alt="Hide Password" />
                </button>
              </div>
            </div>
            <div class="form-group">
              <label class="label"
                >Confirm password<span class="text-danger"> *</span></label
              >
              <div class="position-relative">
                <text-field
                  id="confirmPassword"
                  name="confirmPassword"
                  v-model="form_data.confirmPassword"
                  :readOnly="isConfirmPasswordDisabled"
                  placeholder="Confirm your password"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  :valid="!$v.form_data.confirmPassword.$error"
                  :errorMessage="
                    isSubmitted && !$v.form_data.confirmPassword.required
                      ? 'Please confirm your password.'
                      : !$v.form_data.confirmPassword.sameAsPassword
                      ? 'Passwords do not match'
                      : ''
                  "
                />
                <button
                  class="password-toggle"
                  @click="toggleConfirmPasswordVisibility"
                >
                  <img
                    v-if="showConfirmPassword"
                    class="showPassword"
                    src="../assets/eye-filled.png"
                    alt="Show Password"
                  />
                  <img v-else src="../assets/hide.png" alt="Hide Password" />
                </button>
              </div>
            </div>
            <div class="form-group mb-10 pt-2">
              <div class="custom-checkbox">
                <input v-model="terms" type="checkbox" />
                <label class="m-0 label-color pl-2"> I agree to the </label>
                <a href="/terms" target="_blank" class="pl-2 text-primary">
                  Terms & Conditions
                </a>
                <label class="m-0 label-color pl-2"> and </label>
                <a href="/privacy" target="_blank" class="pl-2 text-primary">
                  Privacy Policy
                </a>
              </div>
            </div>
            <Button @click="register" :disabled="!terms" :btnClass="buttonClass"
              >Sign up</Button
            >
          </form>
          <br />
          <div class="text-center font-size-16 fw-600">
            Already have an account? Click here to
            <a href="/login" class="text-primary">Sign in</a>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>
<script>
import TextField from "@/components/common/TextField.vue";
import Button from "./common/ButtonComponent.vue";
import { required, email, sameAs } from "vuelidate/lib/validators";
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";

export default {
  components: { TextField, Button, Loading },
  name: "RegisterComponent",
  data() {
    return {
      form_data: {
        fullName: null,
        age: null,
        gender: null,
        email: null,
        mobileNumber: null,
        password: null,
        confirmPassword: null,
      },
      terms: false,
      isConfirmPasswordDisabled: true,
      isSubmitted: false,
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      ageArr: [
        { id: 3, label: "3" },
        { id: 4, label: "4" },
        { id: 5, label: "5" },
        { id: 6, label: "6" },
        { id: 7, label: "7" },
        { id: 8, label: "8" },
      ],
      genderArr: [
        { id: "male", label: "Male" },
        { id: "female", label: "Female" },
      ],
    };
  },
  computed: {
    buttonClass() {
      // If terms are not accepted, apply the disabled style
      return !this.terms ? "bg-disabled" : "";
    },
  },
  watch: {
    "form_data.password": function (newValue) {
      this.isConfirmPasswordDisabled = !newValue;
      this.form_data.confirmPassword = "";
    },
  },
  validations: {
    form_data: {
      fullName: {
        required,
        isValidFullName(value) {
          if (!value) return true;
          return /^[A-Za-z\s]+$/.test(value);
        },
      },
      age: { required },
      gender: { required },
      email: { required, email },
      mobileNumber: {
        isValidPhoneNumber(value) {
          if (!value) return true;
          return /^\d+$/.test(value);
        },
      },
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  mounted() {
    updateTitle("Sign up");
    document.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    register() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (!this.terms) {
        return;
      }
      this.isLoading = true;
      let dataTosend = {};
      (dataTosend.username = this.form_data.fullName.trim()),
        (dataTosend.email = this.form_data.email.trim().toLowerCase()),
        (dataTosend.age = this.form_data.age.id),
        (dataTosend.gender = this.form_data.gender.id),
        (dataTosend.phone_number = this.form_data.mobileNumber
          ? this.form_data.mobileNumber
          : null),
        (dataTosend.password = this.form_data.password.trim()),
        (dataTosend.is_google = false);
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_REGISTER_BASE_API_URL_PATH,
          dataTosend
        )
        .then((responce) => {
          this.isLoading = false;
          this.$notify({
            group: "foo",
            type: "success",
            text: "User registered successfully." || responce.data.status,
          });
          this.$router.push("/login");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            group: "foo",
            type: "error",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "Registration failed.",
          });
        });
    },
    togglePasswordVisibility(event) {
      event.preventDefault();
      this.showPassword = !this.showPassword; // Toggle visibility
    },
    toggleConfirmPasswordVisibility(event) {
      event.preventDefault();
      this.showConfirmPassword = !this.showConfirmPassword;
      this.disabled = !this.form_data.password;
    },
    handleKeyDown(event) {
      if (event.key === "Enter" && this.terms) {
        event.preventDefault();
        this.register();
      }
    },
    isValidFullName(value) {
      if (!value) return true;
      return /^[A-Za-z\s]+$/.test(value);
    },
  },
};
</script>
<style lang="scss">
@import "../scss/global.scss";
.registerCard {
  width: 456px;
  border-radius: 47px 45px 50px 50px;
  background: #fff;
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 342px !important;
  }
  @media all and (max-width: 400px) {
    width: 300px !important;
  }
}
.registerHeader {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media all and (max-width: 400px) {
    font-size: 20px;
  }
}
.registerSecondaryHeader {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
}
.country-code {
  border-right: 1px solid #5e6366 !important;
  position: absolute;
  left: 4px;
  top: 34px;
  font-size: 16px;
}
.textIndent {
  text-indent: 33px;
  padding-left: 20px !important;
}
@media (max-width: 600px) {
  .signin-title {
    width: 220px; /* Set width for mobile screens */
  }
}
.password-toggle {
  position: absolute;
  right: 20px;
  top: 25px;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
}
.vs__dropdown-option {
  margin-left: 0px;
}
.vs__dropdown-option--highlight {
  background-color: #9651f1 !important;
  color: #ffffff !important;
}
.vs__dropdown-toggle {
  height: 48px;
  border: 1px solid #ccc !important;
  background: #f2f3ff !important;
  color: #5e6366 !important;
  // border-radius: 16px !important;
  box-shadow: 0px 2px 4px 0px rgba(71, 29, 143, 0.25);
}
.vs__actions {
  padding: 23px !important;
}
.showPassword {
  height: 21px;
}
.custom-checkbox {
  padding-left: 5px;
  position: relative;
}
.bg-disabled {
  background: rgba(85, 83, 83, 0.2) !important;
  border: none;
}
.ageResponsive {
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
  @media (min-width: 600px) {
    padding-right: 5px !important;
  }
}
.genderResponsive {
  @media (min-width: 600px) {
    padding-left: 5px !important;
  }
}
</style>
