<template>
  <div class="container-fluid p-0 pl-3per pr-3per">
    <div
      class="d-flex align-items-center justify-content-center font-size-18 fw-700 text-primary pb-3"
    >
      FluteNFeather Privacy Policy
    </div>
    <div class="section pb-3">
      <span class="font-size-16 fw-600 mb-2"
        >Please note that the Privacy Policy was last revised on May 15,
        2024</span
      >
    </div>
    <div class="section">
      <h2>1. General</h2>
      <p>
        At FluteNFeather, we care about your personal information, so we have
        prepared this Privacy Policy to explain how we collect, use, and share
        it. This Privacy Policy applies to FluteNFeather websites, mobile apps,
        and related services (“Service”). By using the Service, you agree with
        FluteNFeather’s collection, use, and sharing of your personal
        information in accordance with the terms of this Privacy Policy.
      </p>
      <p>
        Please note that FluteNFeather is constantly testing and improving our
        product features. Any features discussed in this Privacy Policy may not
        be available to all users or in all jurisdictions.
      </p>
    </div>
    <div class="section">
      <h2>2. Information We Collect</h2>
      <p>
        When you use the Service, FluteNFeather may collect the following
        personal information about you.
      </p>
      <u>a. Account Registration</u>
      <p>
        To register for a FluteNFeather account, you may provide us with your
        name, age, gender, phone number and email address.
      </p>
      <p>
        You may also register for a FluteNFeather account using certain social
        logins, such as Google and Facebook. If you register for FluteNFeather
        using a social login, FluteNFeather may receive information about you
        from your social login provider, including your email address and
        contacts.
      </p>
      <u>b. Profile Page & User-Generated Content</u>
      <p>
        After you register for your FluteNFeather account, a profile will be
        created for you (“Profile”). Your Profile will be populated by
        information you submit, such as your name, username, bio, and profile
        picture (“User-Generated Content”). Your age, phone number, and email
        address are not included in your public Profile and are not considered
        User-Generated Content under this Privacy Policy.
      </p>
      <p>
        Your Profile will also include information about your learning progress,
        such as the topics you are learning, your learning statistics, and your
        achievements.
      </p>
      <p>
        FluteNFeather may scan or review any User-Generated Content, or share
        your User-Generated Content with a third-party content moderation
        service provider, to ensure compliance with Community Guidelines we
        might come up with. FluteNFeather may restrict or delete User-Generated
        Content if it violates our Community Guidelines or for any other reason.
      </p>
      <u>c. AI Features</u>
      <p>
        When using FluteNFeather services, you may interact with Artificial
        Intelligence (“AI”) features, which provides you with AI-powered
        responses to your questions and open-ended general conversations.
      </p>
      <p>
        When you interact with AI features, your information may be shared with
        AI vendors such as OpenAI, Claude, Google which may keep a copy of your
        text or audio responses for their own purposes. Please do not submit any
        personal or confidential information when using AI features.
      </p>
      <u>d. Speaking</u>
      <p>
        Some lessons involve you speaking into the FluteNFeather website or app.
        To recognize speech, your audio may be sent to a third-party provider
        such as Google, Apple, or Microsoft Azure.
      </p>
      <p>
        FluteNFeather may also collect and analyze your speech to help us
        understand the effectiveness of our lessons and to improve the product.
      </p>
      <u>e. Activity Data</u>
      <p>
        When you use the Service, we may generate data about your use of the
        Service, which may include your browser and device data, log data, and
        IP address. We will also generate data regarding your engagement in
        educational activities on the Service.
      </p>
      <u>f. Cookies</u>
      <p>
        When you access the FluteNFeather website, we store certain data from
        your browser using cookies. A cookie is a piece of data stored on the
        user’s computer tied to data about the user. We use session ID cookies
        to confirm that users are logged in. If you do not want your data to be
        collected by cookies, there is a simple procedure in most browsers that
        allows you to deny or accept the cookie feature. However, cookies may be
        necessary to provide you with certain features available on the website
        (e.g., customized information). You can find more information about
        cookies and how to manage them
        <a href="http://www.allaboutcookies.org/" target="_blank">here</a>.
      </p>
      <p>
        Please note that our cookies might include Targeting Cookies from
        Google, Facebook, Amazon, and other companies, which these companies may
        use to track your activities across multiple websites to deliver
        personalized advertising to you.
      </p>
      <u>g. Google Analytics</u>
      <p>
        We use Google Analytics, which is a web analytics tool that helps us
        understand how users engage with our website. Like many services, Google
        Analytics uses first-party cookies to track user interactions, as in our
        case, where they are used to collect information about how users use our
        site. This information is used to compile reports and to help us improve
        our site. These reports disclose website trends without identifying
        individual visitors. You can opt out of Google Analytics by installing
        Google’s browser add-on available
        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">
          here</a
        >.
      </p>
    </div>
    <div class="section">
      <h2>3. How We Process Your Information</h2>
      <p>
        FluteNFeather may process your personal information according to the
        following legal bases: to provide products or services you request, to
        promote FluteNFeather’s legitimate interests, to comply with legal
        obligations, and with your consent. In particular, FluteNFeather may
        process and share your personal information in the following ways:
      </p>
      <u>a. Providing and improving the Service</u>
      <p>
        FluteNFeather will process your personal information to provide and
        improve the Service. For example, we will use the information you
        provide to maintain your Profile and learning progress, personalize
        learning content, detect and fix bugs, perform research, and provide
        customer service to you.
      </p>
      <p>
        FluteNFeather may share personal information with third parties that
        help us provide or improve the Service, including hosting providers such
        as Microsoft Azure, search engine providers such as Google, analytics
        providers such as Crashlytics, support providers such as ZenDesk, and
        payment processors such as Stripe. FluteNFeather may also share your
        information with third-party vendors that support product features such
        as AI, speech recognition, and content moderation.
      </p>
      <p>
        If you redeem a promotion code to gain a FluteNFeather subscription, we
        may share data regarding your usage of FluteNFeather with the
        organization that provided the promotion code to you.
      </p>
      <u>b. Communicating with you</u>
      <p>
        FluteNFeather will use the email address or phone number you provide to
        send you the following types of messages:
      </p>
      <ul>
        <li>Essential messages to support the operation of the Service.</li>
        <li>
          Messages to support the FluteNFeather teaching methodology and
          reinforce your learning, such as learning tips, reminders to practice,
          and progress reports.
        </li>
        <li>Announcements regarding changes to the Service or products.</li>
        <li>
          Announcements of new FluteNFeather products, services, offers, or
          research opportunities
        </li>
      </ul>
      <u>c. Complying with law and preventing harmful activities</u>
      <p>
        FluteNFeather may process and share personal information if necessary to
        comply with legal requests, such as subpoenas or court orders.
        FluteNFeather may share personal information when we believe it is
        necessary to comply with law, to protect our interests or property, to
        prevent fraud or other illegal activity, to assist law enforcement, or
        to prevent imminent harm. This may include sharing information with
        other companies, lawyers, agents, or government agencies.
      </p>
      <u>d. Anonymous information</u>
      <p>
        FluteNFeather may process aggregated, de-identified, or otherwise
        anonymous or anonymized information for any purpose. Such information is
        not considered personal information.
      </p>
    </div>
    <div class="section">
      <h2>4. Your Data Subject Rights</h2>
      <p>
        You also have the following rights in relation to the personal
        information we hold about you, in addition to any other rights required
        by applicable law:
      </p>
      <ul>
        <li>Know what personal information we have collected about you.</li>
        <li>
          Access a copy of the personal information that we hold about you.
        </li>
        <li>
          Know what personal information about you we have shared with third
          parties.
        </li>
        <li>
          Opt out of the sharing of your personal information with third
          parties.
        </li>
        <li>Object to our processing of your personal information.</li>
        <li>
          Request that we limit our use of your sensitive personal information
          to what is necessary to perform the services you requested.
        </li>
        <li>
          Not be discriminated against for exercising your data subject rights.
        </li>
        <li>
          Request that we delete any personal information we have collected from
          you.
        </li>
        <li>
          Request that we correct any inaccurate personal information about you.
        </li>
        <li>
          Export the personal information you have provided to FluteNFeather in
          a format that can be transferred electronically to a third party.
        </li>
        <li>
          Withdraw any consent you previously gave us to process your personal
          information.
        </li>
        <li>Delete your FluteNFeather account.</li>
      </ul>
    </div>
    <div class="section">
      <p>
        5. To make privacy-related requests or delete your account, please send
        an email to rajesh.kumar@flutenfeather.com.
      </p>
      <p>
        Please note that these rights are not absolute and FluteNFeather may
        refuse requests to exercise data subject rights if there is a legitimate
        reason, such as if we cannot authenticate your identity, if the request
        could violate the rights of a third party or applicable law, or if the
        request could interfere with a FluteNFeather service or prevent us from
        delivering a service you requested.
      </p>
    </div>
    <div class="section">
      <h2>6. Data Retention</h2>
      <p>
        FluteNFeather will generally retain your personal information until your
        account is deleted. However, FluteNFeather may retain certain
        information longer if necessary to provide our Service, defend our
        legitimate interests or those of third parties, comply with legal
        requirements, resolve or defend ourselves in disputes, investigate
        misuse or disruption of the Service, or perform agreements. We may also
        retain anonymous data indefinitely.
      </p>
    </div>
    <div class="section">
      <h2>7. Child Users</h2>
      <p>
        We know that children deserve extra privacy protection. That’s why we
        treat Child Users (meaning users under the age of 13 in the United
        States or another age of digital consent in their country) differently
        to ensure their parents are in control and we only collect the bare
        minimum information we need to make FluteNFeather work. Child Users are
        not permitted to create a FluteNFeather account directly, but we ask the
        parent to create the account on behalf of the child using the parent’s
        email address. The parent may choose to not share the child’s real name.
      </p>
      <p>
        All Child Users receive the following special treatment when using
        FluteNFeather (“Age Restrictions”):
      </p>
      <ul>
        <li>
          Lessons are made age-appropriate by removing mature words. While
          providing AI Features, we take our best efforts to keep the content
          and response child appropriate.
        </li>
        <li>
          Advertisements, if any, are non-personalized and set to family-safe
          content.
        </li>
        <li>
          Child Users cannot submit any pictures to their Profile, but are
          represented only by a letter avatar or cartoon avatar they can create
          from preset options.
        </li>
        <li>Promotional emails are disabled.</li>
      </ul>
    </div>
    <div class="section">
      <p>
        8. Child Users in certain jurisdictions (including the United States,
        European Union, United Kingdom, Norway, Canada, Australia, New Zealand,
        Singapore, South Korea, and Japan) are subject to these additional Age
        Restrictions:
      </p>
      <ul>
        <li>
          Child Users may submit User-Generated Content to FluteNFeather only
          specific to lessons and activities performed as part of the Services.
        </li>
        <li>
          Child Users may not provide FluteNFeather with their real name,
          profile picture, location, contact information, or other personal
          information.
        </li>
        <li>
          Child Users cannot link their FluteNFeather account to their contact
          information or to their accounts on other online services.
        </li>
        <li>
          Child Users’ Profiles contain no personal information that could be
          used to publicly identify or contact the child, but only the Child
          User’s username, an avatar created from preset options, list of
          friends on FluteNFeather, and information about their learning
          progress on FluteNFeather.
        </li>
      </ul>
    </div>
    <div class="section">
      <p>
        9. Child Users might have access to leaderboards, if any, but will
        receive the following special treatment:
      </p>
      <ul>
        <li>
          The Child User’s username will be anonymized to all other users in the
          leaderboard. For example, the Child User could appear as “John Smith”
          instead of their real username to other users.
        </li>
        <li>
          Other leaderboard users will not be able to view the Child User’s
          Profile or follow the Child User from leaderboards.
        </li>
        <li>
          Child Users will only see avatars instead of profile pictures in their
          leaderboard.
        </li>
      </ul>
    </div>
    <div class="section">
      <p>
        10. With regards to the Children’s Online Privacy Protection Act,
        FluteNFeather collects personal information from children under the age
        of 13 for the sole purpose of performing internal operations of the
        Service. If we discover that we have unknowingly collected additional
        personal information from these children, we will delete it. If you
        believe this to be the case, please contact us at
        rajesh.kumar@flutenfeather.com.
      </p>
    </div>
    <div class="section">
      <h2>11. Do Not Track</h2>
      <p>
        The Service is not designed to respond to “do not track” signals sent by
        some browsers.
      </p>
    </div>
    <div class="section">
      <h2>12. Links to Third-Party Websites</h2>
      <p>
        The Service may contain links to other websites. FluteNFeather is not
        responsible for the content or privacy practices of other websites,
        including any personal or financial information collected by third-party
        payment processors. We encourage users to be aware of the privacy
        policies of other websites they visit. This Privacy Policy applies only
        to information collected by FluteNFeather. Any time you click on a link
        (including advertising banners) or submit your personal information to a
        third-party website, you will be subject to that third party’s privacy
        policies.
      </p>
    </div>
    <div class="section">
      <h2>13. Privacy Policy Updates</h2>
      <p>
        We may update our Privacy Policy to reflect changes to our information
        practices. If we do this and the changes are material, we will post a
        notice that we have made changes to this Privacy Policy on the Website
        for at least seven (7) days before the changes are made, and we will
        indicate the date these terms were last revised at the bottom of the
        Privacy Policy. Any revisions to this Privacy Policy will become
        effective at the end of that seven (7) day period.
      </p>
    </div>
    <div class="section">
      <h2>14. Data Transfer</h2>
      <p>
        FluteNFeather is based in the United States and processes data in the
        United States, which may not provide equivalent levels of data
        protection as your home jurisdiction. FluteNFeather may transfer the
        data of users outside the United States to the United States.
      </p>
    </div>
    <div class="section">
      <h2>15. Supplemental Terms in Certain Jurisdictions</h2>
      <p>
        In some jurisdictions, the Service may be subject to supplemental
        privacy policies or local laws. For users in those jurisdictions, the
        applicable supplemental privacy policies and local laws will take
        precedence over this Privacy Policy to the extent they conflict.
      </p>
    </div>
    <div class="section">
      <h2>Contact Us</h2>
      <p>
        FluteNFeather, Inc. is the data controller of your data for the purposes
        of the General Data Protection Regulation (“GDPR”) and any relevant
        local legislation. FluteNFeather’s headquarters are located within the
        United States at:
      </p>
      <p>FluteNFeather, Inc.</p>
      <p>254 Chapman Rd, Ste 209, Newark DE 19702</p>
      <p>United States of America</p>
      <p>Phone: +1-415-917-0029</p>
      <p>
        For all data privacy inquiries and any questions or concerns you have
        about this Privacy Policy, please contact our Data Protection Officer at
        rajesh.kumar@flutenfeather.com.
      </p>
      <p>
        For all support inquiries, please contact
        rajesh.kumar@flutenfeather.com.
      </p>
    </div>
  </div>
</template>

<script>
import { updateTitle } from "@/utils/utils";
export default {
  name: "PrivacyPolicy",
  mounted() {
    updateTitle("Privacy Policy");
  },
};
</script>
<style lang="scss" scoped>
.section h2 {
  font-size: 18px;
  font-weight: 700;
}
.section p {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}
.section u {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.section ul li {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: auto !important;
}
.section ul li :hover {
  cursor: auto !important;
  color: var(--Gris-oscuro, #6b6b6b);
}
</style>