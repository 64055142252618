<template>
  <div
    class="row m-0 justify-content-center align-items-center h-100"
    :class="funToipcLodingState === 'loading' ? 'loader-min-height' : ''"
  >
    <div class="loader">
      <!-- <img v-if="isFunPage" class="backgroundImg" :src="backgroundImage" alt="" /> -->
      <img
        v-if="funToipcLodingState === ''"
        :class="isFunPage ? 'overlayImg' : 'otherPageOverlay'"
        :src="backgroundImgSrc"
        alt="Loading..."
      />
      <div
        v-if="funToipcLodingState === 'loading'"
        class="overlayImg text-center overlayBackground"
      >
        <img class="peacockImg" :src="backgroundImgSrc" alt="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from "howler";

export default {
  name: "ImageLoaderComponent",
  props: {
    backgroundImage: {
      type: String,
      required: false,
    },
    imageResponse: {
      type: Array,
      required: true,
    },
    audioResponse: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      backgroundImgSrc: "",
      sound: null,
      pausedMedia: false, // Tracks if Howler sound is paused
      audioWasPlayedInitially: false, // Tracks if the audio has started playing initially
    };
  },
  watch: {
    imageResponse: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.updateImage(newVal);
        }
      },
      immediate: true,
    },
    audioResponse: {
      handler(newVal) {
        this.updateAudio(newVal);
      },
      immediate: true,
    },
  },
  async mounted() {
    this.updateAudio(this.audioResponse);
    this.updateImage(this.imageResponse);

    if (this.isAudioResponse) {
      this.stopAudio();
    }

    // Check initial orientation and handle it accordingly
    this.handleOrientationChange();

    // Add listener for orientation change
    window.addEventListener("resize", this.handleOrientationChange);
  },
  beforeDestroy() {
    this.stopAudio();
    window.removeEventListener("resize", this.handleOrientationChange);
  },
  computed: {
    funToipcLodingState() {
      return this.$store.state.funToipcLodingState;
    },
    isAudioResponse() {
      return this.$store.state.isAudioResponse;
    },
    isFunPage() {
      return this.$route.path === '/fun'; 
    },
  },
  methods: {
    updateAudio(newAudioResponse) {
      if (this.sound) {
        this.sound.stop(); // Stop any currently playing audio
      }
      if (newAudioResponse.length > 0) {
        const musicSrc = newAudioResponse[Math.floor(Math.random() * newAudioResponse.length)];
        this.sound = new Howl({
          src: [musicSrc],
          autoplay: false, // Do not autoplay initially
          loop: true, // Loop the audio
          volume: 1.0,
        });

        // Play audio only if in landscape mode
        if (!this.pausedMedia && !this.isPortrait()) {
          this.sound.play();
          this.audioWasPlayedInitially = true; // Track if the audio was started initially
        }
      }
    },
    updateImage(newImageResponse) {
      if (newImageResponse.length > 0) {
        this.backgroundImgSrc =
          this.imageResponse[
            Math.floor(Math.random() * this.imageResponse.length)
          ];
      }
    },
    stopAudio() {
      if (this.sound) {
        this.sound.stop();
        this.sound = null;
      }
    },
    pauseMedia() {
      // Pause Howler sound
      if (this.sound && this.sound.playing()) {
        this.sound.pause();
        this.pausedMedia = true;
      }
    },
    resumeMedia() {
      // Resume Howler sound if it was paused
      if (this.pausedMedia && this.sound) {
        this.sound.play();
        this.pausedMedia = false;
      }
    },
    isPortrait() {
      return window.matchMedia("(orientation: portrait)").matches;
    },
    handleOrientationChange() {
      if (this.isPortrait()) {
        // Pause audio if the device is in portrait mode
        this.pauseMedia();
      } else {
        // Resume only if audio was started initially and we are switching to landscape
        if (this.audioWasPlayedInitially) {
          this.resumeMedia();
    }
  }
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent; /* Set the background to transparent */
}

.backgroundImg {
  width: 600px;
  height: 400px;
  border: none; /* Removes any border */
  outline: none; /* Removes any outline */
  display: block; /* Removes inline spacing issues */
  @media all and (max-width: 350px) {
    width: 310px;
    height: 200px;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    width: 360px;
    height: 200px;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 410px;
    height: 220px;
  }
  @media all and (min-width: 600px) and (max-width: 1000px) {
    width: 550px;
    height: 350px;
  }
  @media all and (min-width: 1000px) and (max-width: 1200px) {
    width: 600px;
    height: 350px;
  }
}

.overlayImg {
  position: absolute;
  width: 375px; /* Adjust the size as needed */
  height: 216px; /* Adjust the size as needed */
  border-radius: 10px;
  @media all and (min-width: 200px) and (max-width: 330px) {
    width: 170px;
    height: 100px;
  }
  @media all and (max-width: 330px) {
    width: 200px;
    height: 125px;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    width: 250px;
    height: 125px;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 300px;
    height: 150px;
  }
}
.loader-min-height {
  min-height: 80vh;
}
.overlayBackground {
  background-color: #e5cbf5;
}
.peacockImg {
  height: 250px;
  @media all and (max-width: 400px) {
    height: 125px;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    height: 150px;
  }
}
.otherPageOverlay {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  @media all and (max-width: 350px) {
    height: 200px;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    height: 200px;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    height: 220px;
  }
  @media all and (min-width: 600px) and (max-width: 1000px) {
    height: 350px;
  }
  @media all and (min-width: 1000px) and (max-width: 1200px) {
    height: 350px;
  }
}
@media screen and (orientation: landscape) and (max-width: 812px) and (max-height: 375px) {
  .otherPageOverlay {
    height: 239px;
  }
}
@media screen and (orientation: landscape) and (min-width: 813px) and (max-width: 896px) and (max-height: 414px) {
  .otherPageOverlay {
    height: 239px;
  }
}
@media screen and (orientation: landscape) and (min-width: 897px) and (max-height: 430px) {
    .otherPageOverlay {
    height: 239px;
  }
}
</style>