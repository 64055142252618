import Vue from 'vue'
import App from './App.vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { router, hideNavbar } from "./router/index";
import LoadingPlugin from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './axios/axios'
import Vuelidate from 'vuelidate'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Notifications from 'vue-notification';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';
import { store } from './store';
import GSignInButton from 'vue-google-signin-button'

Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(LoadingPlugin)
Vue.use(Notifications)
Vue.use(GSignInButton)

Vue.prototype.$uuid = uuidv4();

Vue.config.productionTip = false
Vue.prototype.$http = Axios;

const token = localStorage.getItem('token');
console.log('tokennnn', token);

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] =  localStorage.token;
}
Vue.component('v-select', vSelect)
new Vue({
  router: router,
  store,
  render: (h) => h(App),
  computed: {
    shouldShowNavbar() {
      console.log('mainpage', hideNavbar);
      return !hideNavbar;
    },
  },
}).$mount("#app");

