var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"logo-container"},[_c('notifications',{attrs:{"group":"dashboard","duration":2000,"width":"100%"}}),_c('img',{staticClass:"fnf-logo",attrs:{"src":require("../assets/fnf.png"),"alt":"Vue"}})],1),(!_vm.isFinished)?_c('div',{staticClass:"d-flex flex-column align-items-center move-left"},[_c('div',[_c('h2',{staticClass:"welcome-title mb-1"},[_vm._v("Welcome to flute n feather")]),_c('p',{staticClass:"subtitle mb-4",attrs:{"data-next-step":_vm.isNextStep}},[_vm._v(" "+_vm._s(_vm.isNextStep ? "Select one subject from each category below" : "Your child's interest")+" ")]),(!_vm.isNextStep)?_c('div',{staticClass:"sub-content"},[_c('label',{staticClass:"label mb-2",attrs:{"for":"subjects"}},[_vm._v("Select exactly 3 subjects")]),_c('div',{ref:"dropdown",staticClass:"custom-dropdown"},[_c('button',{class:['dropdown-toggle', { 'dropdown-open': _vm.dropdownOpen }],on:{"click":function($event){$event.stopPropagation();return _vm.toggleDropdown.apply(null, arguments)}}},[_c('span',{staticClass:"dropdown-text"},[_vm._v("Select subjects")]),_c('span',{class:[
                'dropdown-icon',
                { 'icon-up': _vm.dropdownOpen, 'icon-down': !_vm.dropdownOpen },
              ]})]),(_vm.dropdownOpen)?_c('div',{staticClass:"dropdown-menu"},_vm._l((_vm.subjects),function(subject){return _c('div',{key:subject.id,staticClass:"dropdown-item",class:{ selected: _vm.selectedSubjectIds.includes(subject.id) },on:{"click":function($event){return _vm.selectSubject(subject)}}},[_c('div',{staticClass:"radio-button"},[(!_vm.selectedSubjectIds.includes(subject.id))?_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","fill":"#DADADA"}})]):_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","fill":"#DADADA"}}),_c('circle',{attrs:{"cx":"7","cy":"7","r":"4","fill":"black"}})])]),_c('span',[_vm._v(_vm._s(subject.name))])])}),0):_vm._e()]),(_vm.errorMessage)?_c('p',{class:[
            'error-message',
            _vm.dropdownOpen ? 'dropdown-open' : 'dropdown-closed',
          ]},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]):_c('div',{staticClass:"sub-content"},[_c('label',{staticClass:"label mb-2"},[_vm._v("Select a minimum of 1 and maximum of 3 categories")]),_vm._l((_vm.selectedSubjects),function(subject,index){return _c('div',{key:subject.id,staticClass:"custom-dropdown",staticStyle:{"margin-bottom":"1rem"}},[_c('button',{class:[
              'dropdown-toggle',
              { 'dropdown-open': _vm.categoryDropdowns[index] },
            ],attrs:{"disabled":_vm.isDropdownDisabled(index)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategoryDropdown(index)}}},[_c('span',{staticClass:"dropdown-text"},[_vm._v(_vm._s(subject))]),_c('span',{class:[
                'dropdown-icon',
                {
                  'icon-up': _vm.categoryDropdowns[index],
                  'icon-down': !_vm.categoryDropdowns[index],
                },
              ]})]),(_vm.categoryDropdowns[index])?_c('div',{ref:'categoryDropdown-' + _vm.selectedSubjects[index],refInFor:true,staticClass:"dropdown-menu-category"},_vm._l((_vm.categories[_vm.selectedSubjects[index]]),function(category){return _c('div',{key:category.id,staticClass:"dropdown-item",class:{
                selected: _vm.selectedCategories[
                  _vm.selectedSubjects[index]
                ]?.includes(category.id),
              },on:{"click":function($event){return _vm.selectCategory(_vm.selectedSubjects[index], category)}}},[_c('div',{staticClass:"radio-button"},[(
                    !_vm.selectedCategories[_vm.selectedSubjects[index]]?.includes(
                      category.id
                    )
                  )?_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","fill":"#DADADA"}})]):_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"7","cy":"7","r":"7","fill":"#DADADA"}}),_c('circle',{attrs:{"cx":"7","cy":"7","r":"4","fill":"black"}})])]),_c('span',[_vm._v(_vm._s(category.name))])])}),0):_vm._e()])}),(_vm.errorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],2),_c('button',{ref:"nextButton",staticClass:"btn btn-dark next-button",style:({
          marginTop: _vm.buttonMarginTop,
          background: _vm.finishButtonEnabled ? '#8142EF' : '#5e6366',
          cursor: 'pointer',
        }),on:{"click":_vm.handleNext}},[_vm._v(" "+_vm._s(_vm.isNextStep ? "Finish Setup" : "Next")+" ")])])]):_c('div',{staticClass:"peacock-container"},[(_vm.isFinished)?_c('OrientationDetector'):_vm._e(),_c('div',{staticClass:"peacock-content"},[_c('img',{staticClass:"peacock-image",attrs:{"src":require("../assets/FeatherCharacter.png"),"alt":"Peacock"}}),_c('div',{staticClass:"peacock-svg-wrapper"},[_c('svg',{staticClass:"peacock-svg",attrs:{"width":"646","height":"274","viewBox":"0 0 646 274","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.9","d":"M0.219543 101.235C19.4435 127.896 49.825 134.079 71.9511 134.569V78.2306C71.9511 35.0229 110.886 0 158.921 0H559.03C607.065 0 646 35.0229 646 78.2306V195.769C646 238.977 607.065 274 559.03 274H158.921C110.886 274 71.9511 238.971 71.9511 195.769V188.2C-7.72314 180.276 0.219543 101.235 0.219543 101.235Z","fill":"white"}})]),_vm._m(0)]),_c('button',{staticClass:"btn btn-dark get-started-button",on:{"click":_vm.homeRedirect}},[_vm._v(" Get Started ")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"peacock-text"},[_vm._v(" To achieve an optimum experience for your child."),_c('br'),_c('br'),_vm._v(" Guide them on how to use the app for the first time. ")])
}]

export { render, staticRenderFns }