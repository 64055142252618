<template>
  <div
    class="container-fluid p-0 container-min-height d-flex justify-content-center align-items-center"
  >
    <div class="speakerText">
      <span class="speakerTextPostion">{{
        `Hello ${userName}, what would you like to do today?`
      }}</span>
    </div>
    <div class="row m-0 d-flex align-items-center justify-content-center mt-3">
      <div
        v-for="subject in filteredSubjects"
        :key="subject.subject_name"
        class="col-12 col-sm-12 col-md-4 col-xl-3 p-0 subject-card mb-2"
      >
        <div class="row m-0" @click.prevent="selectSubject(subject)">
          <div class="col-4 p-0">
            <img
              class="subject-card-img"
              :src="
                subject.img ||
                (subject.subject_name === 'have fun'
                  ? defaultFunImage
                  : defaultLearnImage)
              "
              :alt="subject.subject_name"
            />
          </div>
          <div class="col-8 p-0">
            <div class="row m-0 d-flex justify-content-end pt-10 pr-10">
              <img class="subject-card-arrow" src="../assets/arrow.png" />
            </div>
            <div class="row m-0 d-flex justify-content-end pt-10 pr-30">
              <span class="subject-card-text text-capitalize">{{
                subject.subject_name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>
<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";

export default {
  components: { Loading },
  name: "DashboardComponent",
  data() {
    return {
      userName: localStorage.getItem("username"),
      subjectArr: [],
      defaultFunImage: require("@/assets/Fun.png"),
      defaultLearnImage: require("@/assets/English.png"),
      isLoading: false,
    };
  },
  async mounted() {
    updateTitle("Dashboard");
    this.fetchSubject();
    const token = localStorage.getItem("token");
    const loaded = localStorage.getItem("pageReloaded");
    this.loggedIn = !!token;
    if (this.$route.path === "/dashboard" && token && !loaded) {
      this.$notify({
        group: "foo",
        type: "success",
        text: "Succesfully Logged In",
      });
      localStorage.setItem("pageReloaded", "loaded");
    }
  },
   computed: {
     filteredSubjects() {
      // Ensure subjectArr is an array before filtering
      if (Array.isArray(this.subjectArr)) {
        return this.subjectArr
          .filter(
            (subject) =>
              subject.subject_name.toLowerCase() === "have fun" ||
              subject.subject_name.toLowerCase() === "learn"
          )
          .map((subject) => ({
            ...subject,
            subject_name: subject.subject_name.toLowerCase() // Convert the subject name to lowercase
          }));
      } else {
        return [];
      }
    }
  },
  methods: {
    selectSubject(subject) {
      if (subject.subject_name === "have fun") {
        localStorage.setItem("subjectName", subject.subject_name);
        this.$router.push("/fun");
      } else {
        this.$router.push("/topic");
      }
    },
    fetchSubject() {
      this.isLoading = true;
      axios
        .get(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_SUBJECT_URL_PATH
        )
        .then((response) => {
          this.isLoading = false;
         // Check if the response is an array and set subjectArr
          if (Array.isArray(response && response.data && response.data.subjects)) {
            this.subjectArr = response.data.subjects; // Assign response to subjectArr
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error fetching subject", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.speakerText {
  position: absolute;
  color: #000;
  bottom: 70%;
  padding: 10px;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media all and (max-width: 600px) {
    font-size: 20px;
  }
  @media all and (orientation: landscape) and (max-height: 500px) {
    bottom: 70%; /* Adjust as needed */
    font-size: 18px; /* Adjust as needed */
    padding: 5px; /* Adjust as needed */
  }
}
.speakerTextPostion {
  position: relative;
}
.subject-card {
  min-width: 220px;
  height: 100px;
  border-radius: 20px;
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 4px solid #5800ea;
  cursor: pointer;
  box-shadow: 0px 6px 23px 0px rgba(122, 57, 235, 0.36);
  transition: transform 0.3s;
}
.subject-card:hover {
  color: #fff;
  background: #9e3fff;
  box-shadow: 0px 6px 23px 0px rgba(122, 57, 235, 0.36);
  border-bottom: none;
}
.subject-card-img {
  height: 100px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.subject-card-text {
  color: #9e3fff;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.subject-card:hover .subject-card-text {
  color: #fff !important;
}
.subject-card-arrow {
  height: 25px;
  opacity: 0; /* Initially hide the arrow */
  transition: opacity 0.3s; /* Add transition for smooth opacity change */
}
.subject-card:hover .subject-card-arrow {
  opacity: 1; /* Show the arrow on hover */
}
.large-screen-card {
  @media (min-width: 600px) {
    display: flex;
    justify-content: end;
  }
}
</style>