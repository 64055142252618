<template>
  <div id="app">
    <div
      class="container-fluid full-height p-0"
      :class="isTermsAndConditionPage || isPrivacyPage ? '' : 'bg-dashboard'"
    >
      <div
        :class="isTermsAndConditionPage || isPrivacyPage ? '' : 'bg-feather'"
      >
        <notifications group="foo" :duration="5000" width="100%" />
        <!-- <navbar-component /> -->
        <sidebar-component v-if="isLoggedIn && !parentOnboarding" />
        <!-- Add OrientationDetector except for login and register pages -->
        <orientation-detector v-if="!beforeLoginPage" />
        <div v-if="isLoggedIn" class="container-min-height" :class="this.isTopicDetailsPage ? 'pl-60' : 'pl-70'">
          <div :style="getBackgroundColorStyle()">
            <router-view />
          </div>
        </div>
        <div v-else>
          <div>
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavbarComponent from "@/components/NavbarComponent.vue";
import SidebarComponent from "@/components/common/SidebarComponent.vue";
import OrientationDetector from "@/components/common/OrientationDetector.vue";

export default {
  name: "App",
  components: {
    SidebarComponent,
    OrientationDetector,
  },
  data() {
    return {
      showAlert: false,
      alertMessage: "",
      isLoggedIn: !!localStorage.getItem("isLoggedIn"),
      topicColor: null,
    };
  },
  watch: {
    // Watch `$route` to update `isLoggedIn` when the route changes
    $route() {
      this.isLoggedIn = !!localStorage.getItem("isLoggedIn");
    },
  },
  created() {
    // Ensure `isLoggedIn` is set when the component is first created
    this.isLoggedIn = !!localStorage.getItem("isLoggedIn");
  },

  computed: {
    shouldShowNavbar() {
      return this.$root.shouldShowNavbar;
    },
    parentOnboarding() {
      return this.$route.name === "ParentOnboardComponent";
    },
    // isLoggedIn() {
    //   return localStorage.getItem('isLoggedIn');
    // },
    isTopicDetailsPage() {
      return this.$route.name === "topicDetails";
    },
    isTermsAndConditionPage() {
      return this.$route.path === "/terms";
    },
    isPrivacyPage() {
      return this.$route.path === "/privacy";
    },
    // Computed property to detect if the current route is login or register
    beforeLoginPage() {
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/" ||
        this.$route.path === "/register" ||
        this.$route.path === "/gisregister" ||
        this.$route.path === "/privacy" ||
        this.$route.path === "/terms" ||
        this.$route.path === "/parent_onboard"
      );
    },
  },
  methods: {
    showNotification(message) {
      this.$refs.notification.showNotification(message);
    },
    showAlertMessage() {
      this.showAlert = true;
      this.alertMessage = "This is an alert message.";
    },
    getBackgroundColorStyle() {
      console.log("Current topicColor:", localStorage.getItem("topicColor"));
      if (this.isTopicDetailsPage) {
        return { backgroundColor: localStorage.getItem("topicColor") };
      }
      return {}; // No background color if the condition is false
    },
  },
};
</script>

<style>
@import "./scss/global.scss";
#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: white;
}
#nav {
  /* padding: 30px; */
  text-align: center;
}

#nav a {
  /* font-weight: bold; */
  color: #8f8f8f;
  text-decoration: none !important;
}

#nav a.router-link-exact-active {
  color: whitesmoke;
  background: crimson;
  border-radius: 0.5rem;
}

.NavBarShadow {
  box-shadow: 0 0.15rem 1.75rem #21283226 !important;
}
.logoName {
  font-size: 20px;
  font-weight: 600;
  padding-left: 5px;
  color: black;
}

.profile {
  border-radius: 50%;
  width: 45px;
  border: 1px solid #979797;
  height: 45px;
  display: inline-block;
}

.sub-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.sub-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.sub-menu a:hover {
  background-color: #ddd;
  color: #e0696d !important;
}

.sub-menu-first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sub-menu-middle-child {
  border-radius: 0px;
}

.sub-menu-last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.main-menu:hover .sub-menu {
  display: block;
}

li {
  font-size: 16px;
  color: #8f8f8f;
  margin-left: 30px;
  padding: 5px 2px;
  cursor: pointer;
}

li:hover {
  color: #e0696d;
}

.nav-item a:hover {
  color: #e0696d !important;
}

.h-60 {
  height: 55px;
}
.z-index {
  z-index: 999;
}
.content {
  display: block;
  margin: auto 0;
  padding-top: 11vh;
  padding-bottom: 10vh;
}
.notification-content {
  text-align: center;
}
.sakhaImage {
  height: 45px;
}
</style>
