<template>
  <b-button :class="['btn', btnClass]" @click="handleClick">
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    btnClass: {
      type: String,
      default: '' // Default CSS class for the button
    }
  },
  methods: {
    handleClick() {
      // Emit a click event
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.btn {
color: #FFF;
width: 100%;
font-size: 20px;
font-weight: 700;
line-height: normal;
border-radius: 40px;
padding: 15px 21px;
background: linear-gradient(88deg, #8142EF -3.68%, #B566F2 103.37%);
box-shadow: 0px 6px 23px 0px rgba(122, 57, 235, 0.36);
}
/* Add scoped CSS styles for your button */
</style>
