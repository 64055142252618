<template>
  <div class="container-fluid container-min-height">
    <notifications group="dashboard" :duration="2000" width="100%" />
    <orientation-detector
      @update-orientation="updateOrientation"
      @pause-question-audio="pauseAudio"
      @resume-question-audio="playAudio"
    />
    <audio
      id="audio-player"
      :src="audioSrc"
      preload="auto"
      type="audio/mpeg"
      @ended="audioEnded"
    ></audio>
    <!-- Sidebar on the left -->

    <SidebarComponent />

    <!-- Main Content Area -->
    <div class="main-content">
      <!-- Top Navbar with Home icon on the right, inside main content -->
      <div class="navbar-content">
        <button @click="home" class="home-button">
          <img src="../assets/Home.png" alt="Home Icon" class="home-icon" />
          <span class="home-text">Home</span>
        </button>
      </div>
      <!-- Inner Content Area -->
      <div class="inner-content">
        <div v-if="isLoading" class="image-loader-container">
          <image-loader-component
            :isLoading="isLoading"
            :imageResponse="loadingStateImgResponse"
            :audioResponse="audioResponse"
          />
        </div>
        <div v-if="!isLoading" class="content-when-loaded">
          <p class="prompt-text" v-if="!scrollableText">
            Do you want to hear a joke or learn about something specific.<br />
            Let me know and I will dig up an answer for you.
          </p>
          <div
            v-if="scrollableText"
            ref="scrollContainer"
            class="text-scroller-container"
          >
            <div class="dummy-text">{{ scrollableText }}</div>
          </div>
          <!-- <p class="prompt-text">{{ landingText }}</p> -->
          <div class="col-12 question-controls" ref="featheranimationContainer">
            <div
              v-if="!isLoading"
              class="character-animation feather-animation fnff-peacock-left"
              ref="featherImage"
            ></div>
            <div class="row m-0 mt-5" v-if="!audioLoaded">
              <div class="col-12 p-0 pb-3">
                <span class="tooltiptext">
                  {{ listeningText }}
                </span>
              </div>
              <div class="col-12 p-0">
                <button
                  class="recording-btn"
                  @click="toggleRecording"
                >
                  <img
                    class="question-play-n-pause cursor-pointer"
                    v-if="isRecording && !afterIsRecording"
                    src="../assets/Fnff-Microphone-Listen.png"
                  />
                  <img
                    class="question-play-n-pause cursor-pointer"
                    v-if="!isRecording"
                    src="../assets/Fnff-Microphone-Mute.png"
                  />
                  <img
                    class="question-play-n-pause cursor-pointer"
                    v-if="afterIsRecording && isRecording"
                    src="../assets/Fnff-Microphone-Mute.png"
                  />
                </button>
              </div>
            </div>
            <div class="row stopButton" v-if="audioLoaded">
              <button
                type="button"
                @click="stopAudio"
                class="control-stop-button mr-2"
                ref="featherStopAnimationContainer"
              >
                <img
                  class="cursor-pointer control-image"
                  v-if="isRecording || audioLoaded"
                  src="../assets/Fnff-Stop-Button.png"
                />
                <span class="ml-2">Stop</span>
              </button>
              <button
                class="control-button"
                @click="pauseAudio"
                v-if="!isPaused && audioLoaded"
                ref="featherStopAnimationContainer"
              >
                <img
                  class="control-image cursor-pointer"
                  v-if="!isPaused && audioLoaded"
                  src="../assets/Fnff-Qus-Pause-Button.png"
                />
                <span class="ml-2">Pause</span>
              </button>
              <button v-else class="control-button" @click="playAudio">
                <img
                  class="control-image cursor-pointer"
                  src="../assets/Fnff-Qus-Play-Button.png"
                />
                <span class="ml-2">Play</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isMicrophoneAllowed && isMicrophoneBrowserAllowed"
      class="auth-overlay"
    >
      <div>
        <div class="prompt-block">
          <template>
            <div>
              <h4>We need access to your microphone.</h4>
              <p>
                Please click the lock icon in your address bar to fix this and
                refresh the page.
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageLoaderComponent from "@/components/common/ImageLoaderComponent.vue";
import { updateTitle } from "@/utils/utils";
import NoSleep from "@uriopass/nosleep.js";
import OrientationDetector from "@/components/common/OrientationDetector.vue";
import SidebarComponent from "@/components/common/SidebarComponent.vue";
export default {
  components: { OrientationDetector, SidebarComponent, ImageLoaderComponent },
  name: "FunTopicComponent",
  data() {
    return {
      isRecording: false,
      afterIsRecording: false,
      mediaStream: null,
      mediaRecorder: null,
      silenceTimer: null,
      isMicrophoneAllowed: false,
      isMicrophoneBrowserAllowed: false,
      recordedChunks: [],
      isLoading: false,
      userName: localStorage.getItem("username"),
      userEmail: localStorage.getItem("email"),
      subjectName: localStorage.getItem("subjectName"),
      sound: null,
      audioLink: null,
      audioLoaded: false,
      audioFile: null,
      listeningText: "Click the microphone to speak",
      landingText:
        "Do you want to hear a joke or learn about something specific. Let me know and I will dig up an answer for you.",
      isPaused: false,
      //loader Images
      backgroundSrc: require("@/assets/Vector.png"),
      imageFiles: [require("@/assets/StaticPeacock.png")],
      imageResponse: [],
      loadingStateImg: [require("@/assets/WaitingLLMResponse.gif")],
      loadingStateImgResponse: [],
      backgroundImage: "",
      audioFiles: [
        require("@/assets/jenny_response1_out.mp3"),
        require("@/assets/jenny_response2_out.mp3"),
        require("@/assets/jenny_response3_out.mp3"),
        require("@/assets/jenny_response4_out.mp3"),
        require("@/assets/jenny_response5_out.mp3"),
      ],
      audioResponse: [],
      // After LLM response Images
      LLMResponseImg: [require("@/assets/WaitingLLMResponse.gif")],
      LLMimageResponse: [],
      audioSrc: "",
      audio_player: null,
      noSleep: null,
      isPortrait: false,
      scrollableText: "",
      wordChunkSize: 20, // Number of words per scroll chunk
      interval: null, // Interval ID for scrolling
      scrollAmount: 0, // Amount of scroll in pixels for each chunk
      scrollInterval: null,
      isQustionAndAnswerPaused: false,
      characterFeather: null,
      animationInterval: null,
      scaleFactor: 0.3,
      videoData: {
        fluteImage: null,
        featherImage: null,
        animation_checkpoints: [],
      },
      videoPlayer: null,
      currentSprites: {
        flute: "",
        feather: "",
      },
      spriteData: {
        flute: {},
        feather: {},
      },
      images: {
        flute: new Image(),
        feather: new Image(),
      },
      poseChanges: [],
    };
  },
  async mounted() {
    await this.fetchSpriteCharacter(); // Load the video URL initially
    this.characterFeather = JSON.parse(localStorage.getItem("characterData"));
    this.loadQuestionSpriteData("Feather", "Listening");
    updateTitle("Fun");
    this.noSleep = new NoSleep(); // Initialize NoSleep
    this.checkMicrophoneAccess();
    window.addEventListener("popstate", this.handlePopState);
    // loader image
    this.imageFiles.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.imageResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    // loader background image
    axios
      .get(this.backgroundSrc, { responseType: "blob" })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        this.backgroundImage = imageUrl;
      })
      .catch((error) => {
        console.error(error);
      });
    // loader background audio
    this.audioFiles.map((audioFile) => {
      return axios
        .get(audioFile, { responseType: "blob" })
        .then((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            const audioUrl = reader.result;
            if (audioUrl) {
              this.audioResponse.push(audioUrl);
            }
          };
        })
        .catch((error) => {
          console.error(error);
        });
    });
    this.loadingStateImg.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.loadingStateImgResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    // After LLM response image
    this.LLMResponseImg.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.LLMimageResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
  },
  watch: {
    // audioSrc(newSrc) {
    //   if (newSrc) {
    //     this.$nextTick(() => {
    //       const audioElement = document.getElementById("audio-player");
    //       if (audioElement) {
    //         audioElement.addEventListener("ended", this.audioEnded);
    //         this.playAudio();
    //       }
    //     });
    //   }
    // },
  },
  methods: {
    async fetchSpriteCharacter() {
      const dataTosend = {
        character_name: "Feather",
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_CHARACTER_ACTION,
          dataTosend
        );

        if (response && response.data) {
          localStorage.setItem("characterData", JSON.stringify(response.data));
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            text:
              response?.data?.message ||
              "Something went wrong. Try again later.",
          });
        }
      } catch (error) {
        console.error("Something went wrong. Try again later.", error);
      }
    },
    async checkMicrophoneAccess() {
      try {
        const constraints = { audio: true };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaStream = stream;
        this.isMicrophoneAllowed = true;
      } catch (error) {
        this.isMicrophoneAllowed = false;
        this.isMicrophoneBrowserAllowed = true;
      }
    },
    stopScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Stop the interval-based scrolling
        this.scrollInterval = null;
      }
    },
    resetScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Clear any existing scroll interval
        this.scrollInterval = null;
      }
    },
    topicDetailsBack() {
      if (this.sound) {
        this.sound.stop();
      }
      this.$router.push("/dashboard");
      window.location.reload();
    },
    toggleRecording() {
      clearTimeout(this.silenceTimer);
      if (!this.isRecording && !this.afterIsRecording) {
        this.startRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
      } else if (this.afterIsRecording) {
        this.stopRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
        this.afterIsRecording = false;
      }
    },
    async startRecording() {
      try {
        // event.preventDefault();
        // if (!this.isRecording && this.isMicrophoneAllowed) {
        this.isRecording = true;
        this.listeningText = "I'm listening";
        this.loadQuestionSpriteData("Feather", "Listening");
        let silenceStart;

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.chunks = [];

            this.mediaRecorder.start();

            const audioContext = new AudioContext();
            const source = audioContext.createMediaStreamSource(stream);
            const processor = audioContext.createScriptProcessor(512, 1, 1);

            source.connect(processor);
            processor.connect(audioContext.destination);

            // Enable the microphone button after 5 seconds
            setTimeout(() => {
              this.afterIsRecording = true;
              this.listeningText = "Tap below for a response";
              silenceStart = Date.now();
            }, 5000);

            processor.onaudioprocess = (event) => {
              if (
                this.mediaRecorder &&
                this.mediaRecorder.state !== "recording"
              )
                return;

              if (this.afterIsRecording === true) {
                const input = event.inputBuffer.getChannelData(0);
                let isSilent = input.every((value) => Math.abs(value) < 0.01);

                if (isSilent) {
                  if (Date.now() - silenceStart > 2000) {
                    this.mediaRecorder && this.mediaRecorder.stop();
                    stream.getTracks().forEach((track) => track.stop());
                    processor.disconnect();
                    audioContext.close();
                  }
                } else {
                  silenceStart = Date.now();
                }
              }
            };

            this.mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                this.chunks.push(event.data);
              }
            };

            this.mediaRecorder.onstop = () => {
              const audioBlob = new Blob(this.chunks, {
                type: "audio/wav; codecs=0",
              });

              const now = new Date();
              const timestamp =
                now.getFullYear().toString() +
                (now.getMonth() + 1).toString().padStart(2, "0") +
                now.getDate().toString().padStart(2, "0") +
                "_" +
                now.getHours().toString().padStart(2, "0") +
                now.getMinutes().toString().padStart(2, "0") +
                now.getSeconds().toString().padStart(2, "0");

              const filename = "audio_" + timestamp + ".wav";
              const audioFile = new File([audioBlob], filename, {
                type: "audio/wav",
              });
              // this.sendRecordingToApi(audioFile);
              this.audioFile = audioFile;
              this.sendRecordingToApi(audioFile);
            };
          })
          .catch((error) => console.log(error));
        // }
      } catch (error) {
        console.error("Error sending audio to API:", error);
      }
    },

    stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;
        if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
          // Stop the mediaRecorder
          this.mediaRecorder.stop();
        }
      }
    },

    // Handle the orientation update from the child component
    updateOrientation(isPortrait) {
      this.isPortrait = isPortrait;
      const audioElement = document.getElementById("audio-player");
      if (audioElement.getAttribute("src")) {
        if (!this.isPortrait && !this.isPaused) {
          // If the device is in landscape mode, play the audio
          audioElement.play();
          this.autoScrollText();
        } else {
          // If the device is in portrait mode, pause the audio
          audioElement.pause();
          this.stopScrolling();
        }
      }
    },

    sendRecordingToApi(audioFile) {
      this.characterFeather = JSON.parse(localStorage.getItem("characterData"));
      this.isLoading = true;
      this.audioLoaded = false;
      this.listeningText = "Click the microphone to speak";
      this.$store.dispatch("updateFunToipcLodingState", "");
      this.$store.dispatch("updateIsAudioResponse", "");

      const formData = new FormData();
      formData.append("audioFile", audioFile);
      formData.append("user_email", this.userEmail);
      formData.append("topic", "have fun");
      formData.append("session_state", null);

      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_TRANSCRIBE_DOMAIN_URL_PATH,
          formData
        )
        .then((response) => {
          if (response && response.data && response.data.audio_url) {
            this.audioSrc = response.data.audio_url;
            this.scrollableText = response.data.llm_response;
            //this.optionText = this.formatLLMResponse(this.scrollableText);
            // Ensure DOM is updated before accessing the audio element
            this.$nextTick(() => {
              this.audio_player = document.getElementById("audio-player");
              if (this.audio_player) {
                this.audio_player.src = this.audioSrc;
                this.audio_player.volume = 1.0;

                this.$store.dispatch("updateFunToipcLodingState", "");
                this.$store.dispatch("updateIsAudioResponse", true);
                this.isLoading = false;
                this.audioLoaded = true;
                if (this.noSleep) {
                  this.noSleep.enable();
                }
                // Only play the audio if the device is already in landscape mode
                if (!this.isPortrait) {
                  this.audio_player.play();
                  this.loadQuestionSpriteData("Feather", "Speaking");
                } else {
                  this.audio_player.pause();
                }
                this.$nextTick(() => {
                  const container = document.querySelector(
                    ".text-scroller-container"
                  );
                  console.log("container", container);
                  if (container) {
                    // Reset scroll amount to the top for each new response
                    container.scrollTo({
                      top: 0, // Reset to the top
                      behavior: "auto", // No smooth behavior when resetting
                    });
                    this.scrollAmount = 0; // Reset scrollAmount to 0

                    // Ensure any existing scroll interval is cleared
                    clearInterval(this.interval);
                    // Split the formatted text into chunks and start scrolling for Question
                    if (this.scrollableText) {
                      if (!this.isPortrait) {
                        this.autoScrollText(); // Start auto-scroll for Question
                      }
                    }
                  }
                });
              } else {
                console.error("Audio element not found");
                this.$store.dispatch("updateFunToipcLodingState", "");
                this.isLoading = false;
                this.audioLoaded = false;
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.listeningText = "Click the microphone to speak";
          this.isRecording = false;
          this.afterIsRecording = false;
          this.audioLoaded = false;
          this.isLoading = false;
          this.$notify({
            group: "foo",
            type: "error",
            text:
              "Something went wrong. Please try again." ||
              error.response.data.message,
          });
        });
    },
    autoScrollText() {
      const container = this.$refs.scrollContainer;

      if (!container || !(container instanceof Element)) {
        console.error("Invalid container element for scrolling.");
        return;
      }

      const scrollDelay = 6000; // Delay between scrolls (in ms)
      const scrollDuration = 1000; // Time in ms for one scroll transition
      const initialDelay = 10000; // Initial delay for the first line (in ms)
      const lineHeight = parseFloat(getComputedStyle(container).lineHeight);

      let startTime = null; // Initialize start tim

      // Function to smoothly scroll
      const smoothScroll = (timestamp) => {
        // If the audio is paused, stop scrolling
        const audioElement = document.getElementById("audio-player");
        if (audioElement && audioElement.paused) {
          return; // Exit if audio is paused
        }

        if (!startTime) startTime = timestamp; // Set the start time when function is first called
        const elapsed = timestamp - startTime; // Calculate time elapsed

        const progress = Math.min(elapsed / scrollDuration, 1); // Ensure progress doesn't exceed 1
        const scrollAmount = this.scrollAmount + progress * lineHeight; // Calculate the scroll amount

        container.scrollTop = scrollAmount; // Set the scrollTop position

        // Continue scrolling if progress < 1
        if (progress < 1) {
          this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Continue smooth scrolling
        } else {
          this.scrollAmount += lineHeight; // Update scroll amount after one complete line is scrolled

          // Stop if the bottom is reached
          if (
            this.scrollAmount >=
            container.scrollHeight - container.clientHeight
          ) {
            clearInterval(this.scrollInterval); // Stop scrolling at the bottom
          } else {
            // Pause for the scrollDelay before starting the next scroll
            setTimeout(() => {
              startTime = null; // Reset start time for the next scroll
              this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start next smooth scroll
            }, scrollDelay);
          }
        }
      };
      // Start with an initial delay to allow users to read the first line
      setTimeout(() => {
        this.scrollAmount = 0; // Ensure the scroll amount starts from the top
        this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start the smooth scrolling
      }, initialDelay);
    },
    audioEnded() {
      this.resetScrolling();
      this.scrollableText = "";
      this.isRecording = false;
      this.audioLoaded = false;
      this.afterIsRecording = false;
      this.audioLink = null;
      this.audioSrc = "";
      this.audio_player = null;
      this.loadQuestionSpriteData("Feather", "Listening");
      if (this.noSleep) {
        this.noSleep.disable();
      }
    },
    home() {
      this.audioSrc = "";
      this.$router.push("/home");
    },
    playAudio() {
      this.$nextTick(() => {
        const audioElement = document.getElementById("audio-player");
        if (audioElement) {
          this.loadQuestionSpriteData("Feather", "Speaking");
          audioElement.play();
          audioElement.volume = 1.0;
          this.isPaused = false;
          this.isQustionAndAnswerPaused = false;
          this.scrollAmount = this.$refs.scrollContainer.scrollTo;
          clearInterval(this.interval);
          this.autoScrollText();
          if (this.noSleep) {
            this.noSleep.enable();
          }
        } else {
          console.error("Audio element not found");
        }
      });
    },

    pauseAudio() {
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        if (this.noSleep) {
          this.noSleep.disable();
        }
        audioElement.pause();
        clearInterval(this.animationInterval);
        this.isPaused = true;
        // Stop scrolling
        this.isQustionAndAnswerPaused = true;
        clearInterval(this.scrollInterval);
      } else {
        console.error("Audio element not found");
      }
    },
    stopAudio() {
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
        this.isPaused = false;
        this.isRecording = true;
        this.audioLoaded = false;
        this.scrollableText = "";
        this.afterIsRecording = false;
        this.audioLink = null;
        this.audioSrc = "";
        this.audio_player = null;
        this.listeningText = "I'm listening";
        if (this.noSleep) {
          this.noSleep.disable();
        }
        this.startRecording();
        this.resetScrolling();
        this.isQustionAndAnswerPaused = false;
      } else {
        console.error("Audio element not found");
      }
    },
    // Load sprite JSON and image data for a given character and pose
    async loadQuestionSpriteData(character, state) {
      try {
        // Get the correct JSON URL based on the character's state (Listening or Speaking)
        const spriteMetadataUrl =
          this.characterFeather.action_sprite_metadata_map[character][state];
        if (!spriteMetadataUrl) {
          throw new Error(`Metadata URL not found for state: ${state}`);
        }
        // Fetch the sprite data JSON
        const spriteData = await this.fetchPoseJson(spriteMetadataUrl);

        // Update the sprite with the loaded image URL
        const imageUrl =
          this.characterFeather.action_sprite_map[character][state]; // Get the image URL for the given state
        this.currentSprites[character] = imageUrl;

        const image = new Image();
        image.src = imageUrl;

        // Wait for the image to load before starting the animation
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });

        // Start the animation with the loaded sprite data and image
        this.startAnimation(character, spriteData, image, state);
      } catch (error) {
        console.error("Error loading sprite data:", error);
      }
    },
    async fetchPoseJson(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch JSON: ${response.statusText}`);
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching JSON:", error);
        return null;
      }
    },
    startAnimation(character, spriteData, image, state) {
      const frames = Object.keys(spriteData.frames);
      const element =
        character.toLowerCase() === "feather"
          ? this.$refs.featherImage
          : this.$refs.fluteImage;

      // Clear any existing animation interval if it exists
      if (this.animationInterval) clearInterval(this.animationInterval);
      // Run the animation loop with dynamic timing based on the state
      this.runAnimationLoop(frames, spriteData, image, element, state);
    },

    runAnimationLoop(frames, spriteData, image, element, state) {
      let frameIndex = 0;
      const frameCount = frames.length;
      // Set dynamic frame duration based on state (faster for speaking, slower for listening)
      const baseDuration = state === "Speaking" ? 30 : 50; // Adjust values as needed

      // Main loop for frame animation with responsive scaling
      this.animationInterval = setInterval(() => {
        const frameKey = frames[frameIndex];
        const frameData = spriteData.frames[frameKey].frame;

        const container = this.$refs.featheranimationContainer;
        const stopContainer = this.$refs.featherStopAnimationContainer;
        if (container) {
          const containerWidth = container.offsetWidth;
          const containerHeight = "100";
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 956
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.5; // Default scale for larger screens
        }
        if (stopContainer) {
          const containerWidth = container.offsetWidth;
          const containerHeight = "100";
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 956
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.5; // Default scale for larger screens
        }
        // Update element's background and size for the current frame
        element.style.backgroundImage = `url(${image.src})`;
        element.style.backgroundPosition = `-${
          frameData.x * this.scaleFactor
        }px -${frameData.y * this.scaleFactor}px`;
        element.style.width = `${frameData.w * this.scaleFactor}px`;
        element.style.height = `${frameData.h * this.scaleFactor}px`;
        element.style.backgroundSize = `${image.width * this.scaleFactor}px ${
          image.height * this.scaleFactor
        }px`;

        // Cycle through frames
        frameIndex = (frameIndex + 1) % frameCount;
      }, baseDuration);
    },
    stopAnimation() {
      if (this.animationInterval) {
        console.log("Stopping animation");
        clearInterval(this.animationInterval);
        this.animationInterval = null;
      } else {
        console.log("No animation to stop");
      }
    },
    handlePopState() {
      this.stopAudio();
    },
    beforeDestroy() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      this.stopAudio();
      window.removeEventListener("popstate", this.handlePopState);
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        audioElement.removeEventListener("ended", this.audioEnded);
      }
      clearInterval(this.interval);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/color.scss";

html,
body {
  width: 100%;
  height: 100%; /* Full height of the viewport */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow: hidden; /* Prevent body scroll */
}
.character-animation {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Base Styles for .feather-animation */
.feather-animation {
  position: absolute;
  bottom: 10px;
  left: -25px;
}

/* Responsive for .feather-animation */
@media (max-width: 1024px) {
  .feather-animation {
    bottom: 15px; /* Adjust for medium screens */
    left: -20px; /* Reduce left offset */
  }
}

@media (max-width: 768px) {
  .feather-animation {
    bottom: 20px; /* Adjust for small screens */
    left: -15px; /* Further reduce left offset */
  }
}

@media (max-width: 480px) {
  .feather-animation {
    bottom: 25px; /* Adjust for extra-small screens */
    left: -10px; /* Further reduce left offset for smaller devices */
  }
}

.fnff-peacock-left {
  width: 10rem; /* Default width */
  height: 8rem; /* Default height */
  position: absolute;
  bottom: 18px; /* Default distance from bottom */
  left: 50px; /* Default distance from left */
}

/* Large Screens (1025px to 1900px) */
@media (min-width: 1025px) and (max-width: 1900px) {
  .fnff-peacock-left {
    width: 9rem; /* Scale down slightly */
    height: 7.5rem; /* Adjust height */
    bottom: 19px; /* Slight adjustment */
    left: 74px; /* Move closer */
  }
}

/* Medium Screens (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .fnff-peacock-left {
    width: 8rem; /* Smaller for tablets */
    height: 6.5rem; /* Proportional height */
    bottom: 15px; /* Adjust position */
    left: 39px; /* Center more for medium screens */
  }
}

/* Small Screens (481px to 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .fnff-peacock-left {
    width: 7rem; /* Smaller width for phones */
    height: 5.5rem; /* Adjust height */
    bottom: 18px; /* Slight raise */
    left: 30px; /* Center more */
  }
}

/* Extra Small Screens (up to 480px) */
@media (max-width: 480px) {
  .fnff-peacock-left {
    width: 6rem; /* Compact width */
    height: 4.5rem; /* Compact height */
    bottom: -1px; /* Bring it up */
    left: 20px; /* Center closer */
  }
}

/* Ultra-Wide Screens (1901px and above) */
@media (min-width: 1901px) {
  .fnff-peacock-left {
    width: 11rem; /* Larger width for ultra-wide screens */
    height: 9rem; /* Proportional height */
    bottom: 20px; /* Slight adjustment */
    left: 60px; /* Adjust for wider screens */
  }
}


.navbar-content {
    position: absolute;
    top: 1rem; /* Default spacing for normal screens */
    right: 2.5rem; /* Default distance from the right */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    background-color: transparent;
}

/* Extra-Small Devices (up to 481px) */
@media (max-width: 481px) {
    .navbar-content {
        top: 0.5rem; /* Reduce distance from the top */
        right: 0.5rem; /* Adjust for smaller screens */
    }
}

/* Small Devices (481px to 575.98px) */
@media (min-width: 482px) and (max-width: 575.98px) {
    .navbar-content {
        top: 0.6rem; /* Slightly more spacing */
        right: 0.8rem; /* Adjusted for small devices */
    }
}

/* Medium Devices (576px to 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .navbar-content {
        top: 1rem; /* Balanced top spacing */
        right: 1rem; /* Adjust for medium-sized devices */
    }
}

/* Tablets and Larger Screens (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .navbar-content {
        top: 0.85rem; /* Comfortable top distance */
        right: 1.5rem; /* Adjust spacing for tablets */
    }
}

/* Large Screens (1025px to 1500px) */
@media (min-width: 1025px) and (max-width: 1500px) {
    .navbar-content {
        top: 1rem; /* Restore default top spacing */
        right: 2.6rem; /* Restore default right spacing */
    }
}

/* Extra-Large Screens (1501px to 1900px) */
@media (min-width: 1501px) and (max-width: 1900px) {
    .navbar-content {
        top: 1.2rem; /* Increase top spacing for larger screens */
        right: 3.6rem; /* More spacious right margin */
    }
}

/* Ultra-Wide Screens (1901px and above) */
@media (min-width: 1901px) {
    .navbar-content {
        top: 1.5rem; /* Extra spacing for ultra-wide screens */
        right: 4rem; /* Generous right margin */
    }
}

.home-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.2s ease, transform 0.2s ease;

  /* Adjust padding and spacing for smaller devices */
  @media (max-width: 575.98px) {
    padding: 0.4rem 0.8rem;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    padding: 0.45rem 0.9rem;
  }
}

.home-icon {
  width: 35px; /* Default size for larger screens */
  height: auto;
  margin-top: -14px;

  /* Adjust size for smaller screens */
  @media (max-width: 575.98px) {
    width: 20px; /* Smaller icons for phones */
    margin-top: -8px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 30px;
    margin-top: -17px;
  }
}

.home-text {
  font-weight: 600;
  color: #323232;
  font-family: Arial;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.25px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: -18px;

  /* Adjust text size and spacing for smaller screens */
  @media (max-width: 575.98px) {
    font-size: 13px;
    line-height: 15px;
    margin-top: -12px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    font-size: 14px;
    line-height: 16px;
    margin-top: -22px;
  }
}
.text-scroller-container {
  overflow-y: hidden; /* Prevent vertical scrolling */
  line-height: 1.25;
  position: relative;
  width: 100%;
  white-space: pre-wrap; /* Ensure text wraps naturally */
}

/* Ultra-large screens */
@media (min-width: 1901px) {
  .text-scroller-container {
    height: 350px; /* Larger height for ultra-wide displays */
    margin-top: 10px; /* Optional spacing */
    margin-left: 15px;
  }
}

/* Large screens */
@media (min-width: 1501px) and (max-width: 1900px) {
  .text-scroller-container {
    height: 434px; /* Suitable for large desktop screens */
    margin-top: 10px; 
    margin-left: 12px;
  }
}

/* Medium-large screens */
@media (min-width: 1025px) and (max-width: 1500px) {
  .text-scroller-container {
    height: 300px; /* Optimized height for mid-sized desktops */
    margin-top: 10px;
    margin-left: 12px;
  }
}

/* Tablet screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .text-scroller-container {
    height: 151px; /* Adjust height for tablets */
    margin-top: 8px;
    margin-left: 10px;
  }
}

/* Small devices (Landscape phones) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .text-scroller-container {
    height: 156px; /* Adjust height for small devices */
    margin-top: 6px;
    margin-left: 8px;
  }
}

/* Extra-small devices (Landscape or small phones) */
@media (min-width: 482px) and (max-width: 575.98px) {
  .text-scroller-container {
    height: 150px; /* Compact height for smaller devices */
    margin-top: 5px;
    margin-left: 6px;
  }
}

/* Mobile devices (Portrait, very small screens) */
@media (max-width: 481px) {
  .text-scroller-container {
    height: 162px; /* Compact height for mobile devices */
    margin-top: 5px;
    margin-left: 6px;
  }
}


.dummy-text {
  font-family: Arial;
  font-size: 17px; /* Default font size */
  line-height: 1.5; /* Default line height */
  margin: 0;
  padding: 0;
  word-wrap: break-word; /* Ensure long words break properly */
  overflow-wrap: break-word;
  display: block;
  max-width: 100%;
  box-sizing: border-box; /* Include padding/margins in width */
}

/* Responsive adjustments for font-size and line-height */
@media (max-width: 768px) {
  .dummy-text {
    font-size: 17px; /* Slightly smaller font for tablets */
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .dummy-text {
    font-size: 17px; /* Smaller font for mobile phones */
    line-height: 1.3;
  }
}

@media (max-height: 320px) {
  .dummy-text {
    font-size: 17px; /* Minimized font for very small screen heights */
    line-height: 1.2;
  }
}

.question-container {
  display: flex;
  align-items: center; /* Align content to the top */
  justify-content: center;
  background-color: $qus-chat-body-color;
  height: 400px;
  padding: 20px 50px 10px 50px;
  border-radius: 10px;
  position: relative;
}
.content-when-loaded {
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.image-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%; /* Ensures it doesn't exceed the parent's width */
  max-height: 100%; /* Ensures it doesn't exceed the parent's height */
  overflow: hidden; /* Prevents content from overflowing */
  box-sizing: border-box; /* Includes padding and borders in height/width calculations */
}

/* General responsiveness */
@media (max-width: 768px) {
  .image-loader-container {
    height: 80%; /* Adjust container height for smaller devices */
  }
}

@media (min-width: 200px) and (max-width: 320px) {
  .image-loader-container {
    width: 170px;
    height: 100px;
  }
}

/* Container Styling */
.container-fluid {
  display: flex;
  position: absolute; /* Positions relative to the viewport */
  top: 0; /* Start at the top of the viewport */
  left: 0; /* Start at the left of the viewport */
  width: 100%; /* Full width */
  min-height: 100vh; /* Keep the height as desired */
  background-color: #f8f2dc;
  overflow: hidden; /* Prevent scrollbars */
  box-sizing: border-box; /* Include padding in size calculation */
  padding: 20px; /* Default padding */

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) and (max-height: 640px) {
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: 100vh; /* Adjust height for shorter devices */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) and (max-height: 800px) {
    padding: 15px; /* Slightly larger padding */
    min-height: 100vh; /* Adjust height for landscape phones */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) and (max-height: 1024px) {
    padding: 20px; /* Default padding for medium screens */
    min-height: 100vh; /* Adjust height for tablets */
  }

  /* Large devices: Small laptops and desktops */
  @media (min-width: 1024px) and (max-width: 1365.98px) and (max-height: 1200px) {
    padding: 25px; /* Increase padding slightly */
    min-height: 100vh; /* Adjust height for small desktops */
  }

  /* Extra-large devices: Desktops and larger laptops */
  @media (min-width: 1366px) {
    padding: 30px; /* Ample padding for large screens */
    min-height: 100vh; /* Default full height for larger desktops */
  }
}

.stopButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 72px;
  bottom: 31px;
}

/* Ultra-large screens (min-width: 1901px) */
@media (min-width: 1901px) {
  .stopButton {
    bottom: 40px; /* Increased spacing for ultra-large screens */
    left: 85px; /* Adjusted left alignment for wider screens */
  }
}

/* Large screens (1501px - 1900px) */
@media (min-width: 1501px) and (max-width: 1900px) {
  .stopButton {
    bottom: 35px; /* Ample spacing for large screens */
    left: 80px; /* Center alignment for large desktops */
  }
}

/* Medium-large screens (1025px - 1500px) */
@media (min-width: 1025px) and (max-width: 1500px) {
  .stopButton {
    bottom: 30px; /* Slightly less spacing for medium-large desktops */
    left: 75px; /* Align closer for mid-sized screens */
  }
}

/* Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .stopButton {
    bottom: 25px; /* Adjust bottom spacing for tablets */
    left: 70px; /* Align closer for tablet screens */
  }
}

/* Small devices (576px - 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .stopButton {
    bottom: 20px; /* Adjust positioning for small devices */
    left: 65px; /* Align appropriately for landscape phones */
  }
}

/* Extra-small devices (482px - 575.98px) */
@media (min-width: 482px) and (max-width: 575.98px) {
  .stopButton {
    bottom: 15px; /* Compact bottom spacing for extra-small devices */
    left: 114px; /* Align further inward for smaller phones */
  }
}

/* Very small devices (max-width: 481px) */
@media (max-width: 481px) {
  .stopButton {
    bottom: 10px; /* Compact spacing for tiny screens */
    left: 55px; /* Ensure button fits small screens */
  }
}

/* Ultra-small devices (max-height: 320px) */
@media (max-height: 320px) {
  .stopButton {
    bottom: 30px; /* Maintain visibility for ultra-small devices */
    left: 89px; /* Align further inward */
  }
}



.control-image {
  height: 25px;
}
.control-button {
  // position: absolute;
  // top: 89%;
  // left: 55%;
  transform: translate(-50%, -50%);
  width: 145px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.control-stop-button {
  // position: absolute;
  // top: 89%;
  // left: 40%;
  transform: translate(-50%, -50%);
  width: 145px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}

/* Sidebar on the left */
.sidebar {
  width: 70px;
  background-color: #fbf8ec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.logo-container {
  margin-bottom: auto;
}

.fnf-logo {
  width: 52px;
  height: 40px;
  margin-bottom: 1rem;
}
.button {
  background: none;
  border: none;
  padding: 0;
  position: relative;
}
.icon-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin-top: auto;
}

.sidebar-icon {
  width: 32px;
  height: 132px;
}

.main-content {
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Keep it fixed in view */
  top: 0; /* Position at the top */
  left: 70px; /* Adjust for sidebar */
  right: 0;
  bottom: 0;
  overflow: hidden; /* Prevent scrolling within main-content */

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) and (max-height: 640px) {
    left: 70px; /* Adjust for narrower sidebars or reduce left space */
    padding: 0.5rem; /* Smaller padding for compact screens */
    flex-direction: column; /* Maintain column alignment */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) and (max-height: 800px) {
    left: 60px; /* Adjust for sidebar width */
    padding: 0.75rem; /* Moderate padding */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) and (max-height: 1024px) {
    left: 88px; /* Slightly wider sidebar adjustment */
    padding: 1rem; /* Default padding */
  }

  /* Large devices: Small laptops and desktops */
  @media (min-width: 1024px) and (max-width: 1365.98px) and (max-height: 1200px) {
    left: 70px; /* Sidebar width as default */
    padding: 1.25rem; /* Slightly increased padding */
  }

  /* Extra-large devices: Desktops and larger laptops */
  @media (min-width: 1366px) {
    left: 75px; /* Keep left as is */
    padding: 1.5rem; /* Ample padding for larger screens */
  }
}
.inner-content {
  display: flex;
  width: 94%; /* Ensure it fits within the container */
  height: 100vh; /* Full viewport height */
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable scrolling for content overflow */
  margin-top: 2rem;

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) {
    height: 80vh; /* Reduce height for smaller screens */
    padding: 1rem; /* Adjust padding */
    border-radius: 10px; /* Smaller corner radius */
    margin-top: 1rem; /* Reduce top margin */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) {
    height: 82vh; /* Adjust height */
    padding: 1.2rem; /* Moderate padding */
    border-radius: 12px; /* Adjust corner radius */
    margin-top: 1.5rem;
    margin-left: 0; /* Align with the container */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) {
    height: 84vh; /* Slightly taller for tablets */
    padding: 1.5rem;
    border-radius: 15px;
    margin-top: 2rem;
    margin-left: 0; /* Align consistently */
  }

  /* Large devices: Desktops and laptops */
  @media (min-width: 1024px) and (max-width: 1439.98px) {
    height: 85vh; /* Consistent viewport height */
    padding: 1.5rem;
    border-radius: 15px;
    margin-top: 2rem;
    margin-left: auto; /* Remove offset */
    margin-right: auto; /* Center horizontally if needed */
  }

  /* Extra-large devices: Large desktops */
  @media (min-width: 1440px) {
    height: 82vh; /* Slightly reduced height */
    padding: 2rem; /* More padding for readability */
    border-radius: 20px; /* Larger corners */
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto; /* Center content */
  }
}


.tooltiptext {
  visibility: visible;
  min-width: 120px;
  font-family: Arial;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 998;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 21rem;
  box-sizing: border-box;
}

/* Responsive styling for smaller devices */
@media (max-width: 768px) {
  .tooltiptext {
    font-size: 10px; /* Reduce font size for smaller screens */
    padding: 4px; /* Adjust padding */
    margin-top: 21rem; /* Adjust position for smaller screens */
  }
}

@media (max-width: 480px) {
  .tooltiptext {
    font-size: 9px; /* Further reduce font size */
    padding: 3px; /* Adjust padding */
    margin-top: 9rem; /* Adjust position for smaller devices */
  }
}

/* Responsive styling for very small devices (less than 350px) */
@media (max-width: 350px) {
  .tooltiptext {
    font-size: 8px; /* Reduce font size further */
    padding: 2px; /* Adjust padding for compact appearance */
    margin-top: 25rem; /* Adjust position for ultra-small screens */
  }
}

/* Base Styles for .question-controls */
.question-controls {
    text-align: center;
    position: absolute;
    bottom: 31px; /* Default bottom spacing */
    left: 0;
}

/* Extra-Small Screens (up to 481px) */
@media (max-width: 481px) {
    .question-controls {
        bottom: 33px; /* Adjust for extra-small screens */
        padding: 0 0.5rem; /* Reduce padding */
    }
}

/* Small Screens (481px to 575.98px) */
@media (min-width: 482px) and (max-width: 575.98px) {
    .question-controls {
        bottom: 13px; /* Slightly move up */
        padding: 0 0.75rem; /* Adjust padding */
    }
}

/* Medium Screens (576px to 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .question-controls {
        bottom: 5px; /* Adjust for larger mobile devices */
        padding: 0 1rem; /* Moderate padding */
    }
}

/* Larger Tablets and Small Laptops (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .question-controls {
        bottom: 6px; /* Adjust for tablets */
        padding: 0 1.5rem; /* Slightly increased padding */
    }
}

/* Large Screens and Desktops (1025px to 1500px) */
@media (min-width: 1025px) and (max-width: 1500px) {
    .question-controls {
        bottom: 21px; /* Default spacing for large screens */
        padding: 0 2rem; /* Ample padding for desktops */
    }
}

/* Extra-Large Screens (1501px to 1900px) */
@media (min-width: 1501px) and (max-width: 1900px) {
    .question-controls {
        bottom: 42px; /* Increase bottom spacing for large monitors */
        padding: 0 2.5rem; /* Generous padding */
    }
}

/* Ultra-Wide Screens (1901px and above) */
@media (min-width: 1901px) {
    .question-controls {
        bottom: 50px; /* Extra spacing for ultra-wide screens */
        padding: 0 3rem; /* Maximum padding for spacious layouts */
    }
}


.tooltip-container .tooltiptext {
  visibility: hidden; /* Hide by default */
}

.tooltip-container:hover .tooltiptext {
  visibility: visible; /* Show on hover */
}

.inner-content .prompt-text {
  max-width: 100%; /* Make it fit the container width */
  margin-top: 1px;
  font-family: Arial;
  font-size: 25px; /* Default font size */
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 0 1rem; /* Add some padding for better readability */

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) {
    font-size: 15px; /* Smaller font size for small screens */
    line-height: 1.4; /* Adjust line height */
    padding: 0 0.5rem; /* Reduce padding for narrow screens */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) {
    font-size: 16px; /* Slightly larger font size */
    line-height: 1.5; /* Balanced line height */
    padding: 0 0.8rem; /* Moderate padding */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 17px; /* Approaching desktop font size */
    line-height: 1.6; /* Enhance readability */
    padding: 0 1rem;
  }

  /* Large devices: Desktops and laptops */
  @media (min-width: 1024px) and (max-width: 1439.98px) {
    font-size: 23px; /* Default font size */
    line-height: 1.6; /* Comfortable for desktops */
    padding: 0 1rem;
  }

  /* Extra-large devices: Large desktops */
  @media (min-width: 1440px) {
    font-size: 25px; /* Larger font for high-resolution screens */
    line-height: 1.7; /* More spacing for readability */
    padding: 0 1.5rem; /* Increase padding slightly */
  }
}

.question-play-n-pause {
  width: 6rem;
  height: 6rem;
  margin-top: 350px;

  @media all and (max-width: 1068px) {
    width: 5rem;
    height: 5rem;
    margin-top: 350px;
    //margin-bottom: 200px;
  }
  /* For smaller screens (tablets) */
  @media all and (max-width: 768px) {
    width: 5rem;
    height: 5rem;
    margin-top: 350px; /* Adjust as needed for layout */
  }

  /* For mobile devices (landscape) */
  @media all and (max-width: 480px) {
    width: 4rem;
    height: 4rem;
    margin-top: 150px; /* Adjust as needed for layout */
    margin-bottom: -18px;
  }

  /* For small mobile devices (portrait) */
  @media all and (max-width: 350px) {
    width: 3rem;
    height: 3rem;
    margin-top: 400px; /* Adjust as needed for layout */
  }
}

.speakerText {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media all and (max-width: 600px) {
    font-size: 20px;
  }
}

.microImage {
  cursor: pointer;
}
.microImage {
  width: 160px;
  height: 160px;
  @media all and (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
  @media all and (min-width: 600px) and (max-width: 1280px) {
    width: 120px;
    height: 120px;
  }
}

.microImageRes {
  position: relative;
  // bottom: 10%;
  z-index: 1000;
  width: 100px;
  height: 100px;
  margin-left: 40px;
  @media all and (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
}
.playNpause {
  position: relative;
  left: 10%;
  // bottom: 10%;
  width: 40px;
  height: 40px;
  @media all and (max-width: 400px) {
    width: 40px;
    height: 40px;
    left: 25%;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 40px;
    height: 40px;
    left: 30%;
  }
}
.peacockImage {
  position: relative;
  top: 0px;
  right: 15%;
  height: 260px;

  @media all and (max-width: 350px) {
    height: 210px;
    right: 0;
    top: 12%;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    height: 210px;
    right: 0;
    top: 20%;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    // width: 300px;
    height: 230px;
    right: 0;
    top: 12%;
  }
  @media all and (min-width: 600px) and (max-width: 1000px) {
    height: 200px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1280px) {
    height: 230px;
  }
}
.peacockImageRes {
  position: absolute;
  top: 34%;
  right: 31%;
  height: 265px;
  @media all and (max-width: 350px) {
    position: relative;
    height: 200px;
    right: 0;
    top: 0%;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    position: relative;
    height: 200px;
    right: 0;
    top: 0%;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    position: relative;
    height: 200px;
    right: 0;
    top: 0%;
  }
  @media all and (min-width: 600px) and (max-width: 1000px) {
    top: 30%;
    right: 34%;
    height: 260px;
  }
  @media all and (min-width: 1000px) and (max-width: 1200px) {
    top: 29%;
    right: 33%;
    height: 260px;
  }
}

.soundWavesRes {
  position: relative;
  width: 800px;

  @media all and (max-width: 350px) {
    width: 300px;
    height: 200px;
  }
  @media all and (min-width: 350px) and (max-width: 400px) {
    width: 300px;
    height: 200px;
  }
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 300px;
    height: 200px;
  }
  @media all and (min-width: 600px) and (max-width: 1000px) {
    width: 590px;
    // height: 125px;
  }
}

.recording-btn {
  border: none !important;
  background: none !important;
  margin-bottom: 17px;
}

.notification-popup {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 1111119999;
  background: linear-gradient(88.37deg, #8142ef -3.68%, #b566f2 103.37%);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
}

.notification-content {
  display: flex;
  align-items: center;
}

.notification-message {
  flex: 1;
  margin-right: 10px;
  color: #fff;
}

.notification-close {
  background: none;
  border: none;
  color: #0f0f0f;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  transition: color 0.3s;
}
.notification-close:hover {
  color: #555555;
}
.auth-overlay {
  background-color: rgb(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
}

.prompt-block {
  max-width: 700px;
  margin: 30px auto 30px auto;
  max-height: 500px;
  display: inline-block;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  border-radius: 15px;

  h4 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
  }

  > div + div {
    border-top: 1px solid lightgrey;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

.audioDisply {
  display: none;
}
.backButtonPosition {
  cursor: pointer;
  position: absolute;
  top: 13%;
  bottom: 0;
  left: 5%;
  @media all and (min-width: 600px) {
    left: 2%;
  }
}
@media screen and (orientation: landscape) and (max-width: 812px) {
  /* iPhone SE, iPhone 8, iPhone X, XS, 11 Pro, 12 Mini, 13 Mini, etc. */

  .content-container {
    min-height: 260px;
  }
  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
}

/* iPhones with medium widths and heights */
@media screen and (orientation: landscape) and (min-width: 813px) and (max-width: 896px) and (max-height: 414px) {
  /* iPhone X, XR, 11, 11 Pro Max, 12, 13, 14 */

  .content-container {
    min-height: 260px;
  }
  .custom-video {
    opacity: 0.99; /* Force a reflow */
  }

  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
}

/* For larger iPhones in landscape */
@media screen and (orientation: landscape) and (min-width: 897px) and (max-height: 430px) {
  /* iPhone 12 Pro Max, 13 Pro Max, 14 Pro Max, 14 Plus, 15 Plus and larger future devices */

  .content-container {
    min-height: 260px;
  }
  .video-container {
    height: 239px;
  }
  .question-container {
    height: 239px;
  }
  .qustion-body {
    height: 164px;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 65px;
  }
}
</style>
