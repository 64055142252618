<template>
  <div class="container-fluid container-min-height scroll-top">
    <!-- Loop through subjects -->
     <div class="row m-0 home-page" v-if="!isLoading && subjectArr.length > 0">
      <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-0">
        <div
          class="row m-0 mb-3"
          v-for="(subject, index) in subjectArr"
          :key="index"
        >
          <div
            class="col-12 col-sm-12 col-md-12 col-xl-12 p-0 d-flex subject-card primary-bg"
            :style="{ backgroundColor: subject.base_color }"
          >
            <div class="vertical-text fw-600">{{ subject.subject_name }}</div>
            <div class="vertical-line"></div>
            <div class="row m-0 row-min-height topic-list-container" ref="topicListHeight">
              <!-- Left scroll icon -->
              <!-- <i
              class="fa fa-chevron-left scroll-icon left-icon"
              :class="{ disabled: !canScrollLeft[index] }"
              @click="scrollLeft(index)"
            ></i> -->

              <!-- Scrollable Topic List with Icons -->
              <div
                class="topic-list"
                :ref="'topicListContainer' + index"
                @scroll="handleScroll(index)"
              >
                <div
                  v-for="(topic, tIndex) in subject.list_topics"
                  :key="tIndex"
                  class="topic-tiles"
                >
                  <div
                    class="card card-radius cursor-pointer"
                    @click="selectTopic(subject, topic)"
                  >
                    <img
                      class="card-img-top"
                      :src="topic.thumbnail_image || defaultImage"
                      alt="Card image"
                    />
                    <div
                      class="font-size-14 fw-400 text-position"
                      :style="{ backgroundColor: subject.color_1 }"
                    >
                      {{ topic.category_name }}
                    </div>
                    <div
                      class="card-body card-body-down mt-5 secondary-bg d-flex align-items-start"
                      :style="{ backgroundColor: subject.color_2 }"
                    >
                      <div class="col-12 p-0 d-flex align-items-start">
                        <div class="col-8 p-0 card-button-text">
                          <span class="font-size-16 m-0 text-capitalize text-color">{{
                            topic.topic_name
                          }}</span>
                        </div>
                        <div class="col-4 p-0 mb-2">
                          <span class="font-size-10">Progress({{ progressText(topic.topic_status) }})</span>
                                <div class="progress bg-white h-5">
                <div
                  class="progress-bar"
                  role="progressbar"
                 :style="{ width: progressBarWidth(topic.topic_status), backgroundColor: subject.base_color + ' !important' }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="sr-only">{{
                    progressBarWidth(topic.topic_status)
                  }}</span>
                </div>
              </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Right scroll icon -->
              <!-- <i
              class="fa fa-chevron-right scroll-icon right-icon"
              :class="{ disabled: !canScrollRight[index] }"
              @click="scrollRight(index)"
            ></i> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>


<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";

export default {
  name: "HomePage",
  components: { Loading },
  data() {
    return {
      subjectArr: [],
      canScrollLeft: [], // Keep track of scroll state for each subject
      canScrollRight: [],
      isLoading: false,
    };
  },
  mounted() {
    updateTitle("Home");
    const token = localStorage.getItem("token");
    const loaded = localStorage.getItem("pageReloaded");
    this.loggedIn = !!token;
    if (this.$route.path === "/home" && token && !loaded) {
      this.$notify({
        group: "foo",
        type: "success",
        text: "Succesfully Logged In",
      });
      localStorage.setItem("pageReloaded", "loaded");
    }
    this.fetchTopic();
     this.setEqualVerticalTextWidth();
  //    this.$nextTick(() => {
  //   this.setEqualCardHeights();
  //   this.setEqualVerticalTextWidth();
  // });
  window.addEventListener("resize", this.setEqualCardHeights);
  window.addEventListener("resize", this.handleResize);
  window.addEventListener("orientationchange", this.handleOrientationChange);
  // Push an initial state to the history stack
  history.pushState(null, null, location.href);

  // Prevent back navigation
  window.addEventListener("popstate", this.preventBackNavigation);
  },
  beforeDestroy() {
    // Clean up event listener on component destroy
    window.removeEventListener("resize", this.setEqualCardHeights);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("orientationchange", this.handleOrientationChange);
     // Restrict back button navigation
  history.pushState(null, null, location.href);
  window.addEventListener("popstate", this.preventBackNavigation);

  // Continuously push state to block multiple back clicks
  // Clear the interval to avoid memory leaks
  clearInterval(this.blockBackButtonInterval);
  },

  methods: {
preventBackNavigation() {
  // Push the current state to the history stack to keep the user on the same page
  history.pushState(null, null, location.href);
  console.log("Back navigation is disabled on this page.");
},
    fetchTopic() {
      this.isLoading = true;
      let dataTosend = {};
      (dataTosend.user_email = localStorage.getItem("email")),
        // (dataTosend.subject_name = this.subjectName),
        axios
          .post(
            process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
              process.env.VUE_APP_FLUTE_N_FEATHER_TOPIC_URL_PATH,
            dataTosend
          )
          .then((response) => {
            console.log("responce", response.data);
            this.subjectArr = response.data || [];
          if(this.subjectArr) {
            this.$nextTick(() => {
              this.setEqualCardHeights();
              this.setEqualVerticalTextWidth();
            setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, 100);
            });
          }
          this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error fetching subject", error);
          });
    },
        selectTopic(subject, topic) {
          console.log('subject', subject);
          console.log('topic', topic);
      // Find the subject_name associated with the selected topic
      const selectedSubject = this.subjectArr.find((subject) =>
        subject.list_topics.some((t) => t.topic_name === topic.topic_name)
      );

      this.$store.dispatch("updateSubjectName", selectedSubject.subject_name);
this.$store.dispatch("updateCategoryName", topic.category_name);
this.$store.dispatch("updateTopicName", topic.topic_name);

      // Save topic details to localStorage
      localStorage.setItem("topicDetails", JSON.stringify(topic));
      localStorage.setItem("topicName", topic.topic_name);
      localStorage.setItem("videoTitle", topic.video_title);
      localStorage.setItem("categoryName", topic.category_name);
      const topicBaseColor = (subject.base_color && subject.base_color.trim() !== "") ? subject.base_color : "#7DD9CC";
      const topicSecondarColor = (subject.color_1 && subject.color_1.trim() !== "") ? subject.color_1 : "#9EF9EC";
      const topicTertiaryColor = (subject.color_2 && subject.color_2.trim() !== "") ? subject.color_2 : "#9EF9EC";
      localStorage.setItem("topicColor", topicBaseColor);
      localStorage.setItem("topicSecondarColor", topicSecondarColor);
      localStorage.setItem("topicTertiaryColor", topicTertiaryColor);


      // Save the associated subject_name to localStorage
      if (selectedSubject) {
        localStorage.setItem("subjectName", selectedSubject.subject_name);
      }

      // Enable NoSleep (to keep the screen awake)
      if (this.noSleep) {
        this.noSleep.enable();
      }
      // navigate to a route with the topic name as a parameter
      this.$router.push({
        name: "topicDetails",
        query: { topic: topic.topic_name },
      });
    },
    progressBarWidth(status) {
      if (status === "Not Completed") {
        return "0%";
      } else if (status === "In Progress") {
        return "50%";
      } else if (status === "completed") {
        return "100%";
      } else {
        // Default value if status is not recognized
        return "0%";
      }
    },
    progressText(status) {
  if (status === "Not Completed") {
    return "0%";
  } else if (status === "In Progress") {
    return "50%";
  } else if (status === "completed") {
    return "100%";
  } else {
    return "0%"; // Default text if status is unrecognized
  }
},
    scrollLeft(index) {
      const container =
        this.$refs["topicListContainer" + index]?.[0] ||
        this.$refs["topicListContainer" + index];
      if (container && container instanceof HTMLElement) {
        container.scrollBy({ left: -300, behavior: "smooth" });
        this.updateScrollState(index);
      }
    },
    scrollRight(index) {
      const container =
        this.$refs["topicListContainer" + index]?.[0] ||
        this.$refs["topicListContainer" + index];
      if (container && container instanceof HTMLElement) {
        container.scrollBy({ left: 300, behavior: "smooth" });
        this.updateScrollState(index);
      }
    },
    handleScroll(index) {
      this.updateScrollState(index); // Check scroll position dynamically
    },
    updateScrollState(index) {
      const container = this.getContainerElement(index);
      if (container) {
        // Check if we can scroll left or right
        this.canScrollLeft[index] = container.scrollLeft > 0;
        this.canScrollRight[index] =
          container.scrollWidth > container.clientWidth + container.scrollLeft;
      }
    },
    getContainerElement(index) {
      // Access the specific container by index
      const refArray = this.$refs["topicListContainer" + index];
      // Ensure we have an element (it could be an array if used in v-for)
      return Array.isArray(refArray) ? refArray[0] : refArray;
    },
          setEqualCardHeights() {
      // Select all rows
      const rows = this.$refs.topicListHeight;
       if (!rows || rows.length === 0) {
      return;
    }
      
      rows.forEach((row) => {
        // Get all cards in the current row
        const cards = row.querySelectorAll(".topic-tiles .card");
          if (cards.length === 0) {
        return;
      }

        // Reset height to auto to handle updates
        cards.forEach((card) => (card.style.height = "auto"));

        // Find the maximum height
        const maxHeight = Array.from(cards).reduce((max, card) => {
          return Math.max(max, card.offsetHeight);
        }, 0);
        // Apply the maximum height to all cards
        cards.forEach((card) => {
          card.style.height = `${maxHeight}px`;
        });
      });
    },
      handleOrientationChange() {
    // Trigger layout recalculation when orientation changes
    setTimeout(() => {
      this.setEqualVerticalTextWidth();
    }, 100); // Adjust the timeout duration if needed
  },
   handleResize() {
    // Delayed layout recalculation to allow browser time to adjust styles
    setTimeout(() => {
      this.setEqualVerticalTextWidth();
    }, 100); // Adjust the timeout duration if needed
  },
setEqualVerticalTextWidth() {
   this.$nextTick(() => {
  // Select all .vertical-text elements
  const verticalTexts = Array.from(document.querySelectorAll(".vertical-text"));
  const topicTilesContainers = Array.from(document.querySelectorAll(".topic-list"));

  if (!verticalTexts.length || !topicTilesContainers.length) {
    return;
  }

  // Reset styles to allow recalculating correct sizes
  verticalTexts.forEach((text) => {
    text.style.width = "auto";
    text.style.height = "auto";
  });

  // Calculate the maximum width required for vertical texts
  const maxWidth = verticalTexts.reduce((max, text) => Math.max(max, text.scrollWidth), 0);

  // Apply the calculated max width to all vertical texts
  verticalTexts.forEach((text) => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1000) {
        text.style.width = `${maxWidth + 15}px`; // Slight buffer for padding
      } else {
        text.style.width = `${maxWidth}px`;
      }
    });

  // Adjust the height of each subject card to align with the tallest topic tiles container
  topicTilesContainers.forEach((container, index) => {
    const cards = Array.from(container.querySelectorAll(".topic-tiles .card"));

    if (!cards.length) {
      console.warn(`No cards found in container at index ${index}`);
      return;
    }

    // Find the maximum height of cards in the current container
    const maxCardHeight = cards.reduce((max, card) => Math.max(max, card.offsetHeight), 0);

    // Determine additional height adjustments based on screen width
    const screenWidth = window.innerWidth;
    const extraHeight = screenWidth < 1000 ? 20 : 50;

    // Apply calculated height to the corresponding subject card
    const subjectCard = verticalTexts[index]?.closest(".subject-card");
    if (subjectCard) {
      subjectCard.style.height = `${maxCardHeight + extraHeight}px`;
    }
  });
   });
}

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.scroll-top {
  overflow-y: auto;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.subject-card {
  border-radius: 15px;
  min-height: 200px;
  // max-height: 340px 
}

.vertical-text {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    min-width: 60px;
    overflow-wrap: break-word; /* Break long words */
  word-wrap: break-word; /* For older browsers */
  white-space: normal; /* Allow wrapping */
    // height: 340px;
    // white-space: normal;
    /* left: 150px; */
    // word-break: break-all;
    top: 0;
    /* bottom: 30px; */
    font-size: 20px;
    position: relative;
    text-align: inherit;
    line-height: normal;
    padding: 25px 10px;
    font-family: Arial;
    color: $text-color;
    display: flex;
    align-items: center;
}

.vertical-line {
  border-left: 2px solid #ffffff;
}

// .row-min-height {
//   min-height: 200px;
//   padding: 15px;
// }

.topic-list-container {
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  display: flex;
  gap: 20px;
  padding: 25px 27px; /* Give space for icons */
  position: relative;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.topic-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.topic-list-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topic-list {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  white-space: nowrap;
}

.topic-list::-webkit-scrollbar {
  display: none;
}

.topic-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.topic-tiles {
  display: inline-block;
}

.topic-tiles .card {
  transition: height 0.2s ease-in-out; /* Smooth height adjustment */
}

/* Left and right scroll icons */
.scroll-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  color: #333;
  padding: 10px;
  border-radius: 50%;
}

// .disabled {
//   opacity: 0.5;
//   pointer-events: none;
// }
.scroll-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.left-icon {
  left: 0;
}

.right-icon {
  right: 0;
  cursor: pointer;
}

.card-radius {
  border-radius: 15px;
  border: 5px solid #ffffff;
  background: #fff;
  height: auto;
  width: 320px;
}

.card-img-top {
  height: 225px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.text-position {
  position: absolute;
  min-width: 100px;
  top: 190px;
  left: 10px;
  border-radius: 15px;
  padding: 0 10px;
  background-color: $primary-color;
  font-family: Arial;
  color: $text-color;
}

.card-body-down {
  border-radius: 0px 0px 15px 15px;
  height: 100%;
  color: $text-color;
  font-family: Arial;
  padding: 5px 10px;
}
  .card-button-text {
  white-space: normal;
  }
@media (min-width: 401px) and (max-width: 1000px) {
  .subject-card {
    min-height: 155px;
    // max-height: 178px 
  }
  .topic-list-container {
    padding: 10px 15px;
  }
  .topic-list {
    gap: 10px;
  }
  .card-radius {
    // height: 150px;
    height: auto;
    width: 225px;
    border-radius: 5px;
    border: 2px solid #ffffff;
  }
  .card-img-top {
    height: 107px;
    border-radius: 5px 5px 0px 0px;
  }
  .vertical-text {
    // min-width: 45px;
    height: 178px;
    padding: 10px 15px;
    // left: 70px;
    // top: 30px;
  }
  .text-position {
    top:80px
  }
  .card-body-down {
    border-radius: 0px 0px 5px 5px;
    height: 100%;
  }
  .card-button-text {
  white-space: normal;
  }
}
</style>
