import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      funToipcLodingState: '', // Initial state
      isAudioResponse: '',
      subjectName: '',
      categoryName: '',
      topicName: '',
      // other state properties
    },
    mutations: {
      setFunToipcLodingState(state, status) {
        state.funToipcLodingState = status;
      },
      setIsAudioResponse(state, status) {
        state.isAudioResponse = status;
      },
      setSubjectName(state, payload) {
        state.subjectName = payload;
      },
      setCategoryName(state, payload) {
        state.categoryName = payload;
      },
      setTopicName(state, payload) {
        state.topicName = payload;
      },
      // other mutations
    },
    actions: {
      updateFunToipcLodingState({ commit }, status) {

        commit('setFunToipcLodingState', status);
      },
      updateIsAudioResponse({ commit }, status) {
      commit('setIsAudioResponse', status);
    },
    updateSubjectName({ commit }, subjectName) {
      commit("setSubjectName", subjectName);
    },
    updateCategoryName({ commit }, categoryName) {
      commit("setCategoryName", categoryName);
    },
    updateTopicName({ commit }, topicName) {
      commit("setTopicName", topicName);
    },
      // other actions
    },
    getters: {
      getSubjectName: (state) => state.subjectName,
      getCategoryName: (state) => state.categoryName,
      getTopicName: (state) => state.topicName,
    },
  });