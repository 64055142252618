<template>
  <div class="container-fluid p-0 pl-3per pr-3per">
    <div
      class="d-flex align-items-center justify-content-center font-size-18 fw-700 text-primary pb-3"
    >
      Terms & Conditions of Use
    </div>
    <div class="section pb-3">
      <span class="font-size-16 fw-600 mb-2"
        >Please note that the Terms and Conditions was last revised on May 15,
        2024</span
      >
    </div>
    <div class="section">
      <h2>1. General</h2>
      <p>
        FluteNFeather websites (“Websites”), mobile applications (“Apps”), and
        related services (together with the Websites and Apps, the “Service”)
        are operated by FluteNFeather, Inc. (“FluteNFeather,” “us,” or “we”).
        Access and use of the Service is subject to the following Terms and
        Conditions of Service (“Terms and Conditions”). By accessing or using
        any part of the Service, you represent that you have read, understood,
        and agree to be bound by these Terms and Conditions including any future
        modifications. FluteNFeather may amend, update, or change these Terms
        and Conditions. If we do this, we will post a notice that we have made
        changes to these Terms and Conditions on the Websites for at least 7
        days after the changes are posted and will indicate at the bottom of the
        Terms and Conditions the date these terms were last revised. Any
        revisions to these Terms and Conditions will become effective the
        earlier of (i) the end of such 7-day period or (ii) the first time you
        access or use the Service after such changes. If you do not agree to
        abide by these Terms and Conditions, you are not authorized to use,
        access, or participate in the Service.
      </p>
      <p class="fw-700">
        PLEASE NOTE THAT THESE TERMS AND CONDITIONS CONTAIN A MANDATORY
        ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION
        ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN CIRCUMSTANCES,
        RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS.
      </p>
    </div>
    <div class="section">
      <h2>2. Description of Website and Service</h2>
      <p>
        The Service allows users to access and use a variety of educational
        services, including learning or practicing a language. FluteNFeather
        may, in its sole discretion and at any time, update, change, suspend,
        make improvements to or discontinue any aspect of the Service,
        temporarily or permanently.
      </p>
    </div>
    <div class="section">
      <h2>3. Acceptable Use of the Services</h2>
      <p>
        You are responsible for your use of the Services, and for any use of the
        Services made using your account. Our goal is to create a positive,
        useful, and safe user experience. To promote this goal, we prohibit
        certain kinds of conduct that may be harmful to other users or to us.
        When you use the Services, you must comply with any Community Guidelines
        we will come up with.
      </p>
    </div>
    <div class="section">
      <h2>4. Additional Terms</h2>
      <p>
        Some of our Services have additional terms and conditions (“Additional
        Terms”). Where Additional Terms apply to a Service, we will make them
        available for you to read through your use of that Service. By using
        that Service, you agree to the Additional Terms.
      </p>
    </div>
    <div class="section">
      <h2>5. Registration</h2>
      <p>
        In connection with registering for and using the Service, you agree (i)
        to provide accurate, current and complete information about you and/or
        your organization as requested by FluteNFeather; (ii) to maintain the
        confidentiality of your password and other information related to the
        security of your account; (iii) to maintain and promptly update any
        registration information you provide to FluteNFeather, to keep such
        information accurate, current and complete; and (iv) to be fully
        responsible for all use of your account and for any actions that take
        place through your account.
      </p>
    </div>
    <div class="section">
      <h2>6. Your Representations and Warranties</h2>
      <p>
        You represent and warrant to FluteNFeather that your access and use of
        the Service will be in accordance with these Terms and Conditions and
        with all applicable laws, rules, and regulations of the United States
        and any other relevant jurisdiction, including those regarding online
        conduct or acceptable content, and those regarding the transmission of
        data or information exported from the United States and/or the
        jurisdiction in which you reside. You further represent and warrant that
        you have created or own any material you submit via the Service
        (including Activity Materials and Content) and that you have the right,
        as applicable, to grant us a license to use that material as set forth
        above or the right to assign that material to us as set forth below.
      </p>
      <p>
        You represent and warrant that you are not: (1) organized under the laws
        of, operating from, or otherwise ordinarily resident in a country or
        territory that is the target of comprehensive U.S. economic or trade
        sanctions (i.e., an embargo); (2) identified on a list of prohibited or
        restricted persons, such as the U.S. Treasury Department’s List of
        Specially Designated Nationals and Blocked Persons; or (3) otherwise the
        target of U.S. sanctions.
      </p>
    </div>
    <div class="section">
      <h2>7. Submission of Content</h2>
      <p>
        As a condition of submitting any ratings, reviews, information, data,
        text, photographs, audio clips, audiovisual works, translations,
        flashcards, or other materials on the Service (collectively, “Content”),
        you hereby grant to FluteNFeather a full-paid, royalty free, perpetual,
        irrevocable, worldwide, nonexclusive, transferable, and sublicensable
        license to use, reproduce, copy, adapt, modify, merge, distribute,
        publicly display, and create derivative works from the Content;
        incorporate the Content into other works; and sublicense through
        multiple tiers the Content. You acknowledge that this license cannot be
        terminated by you once your Content is submitted to the Service. You
        represent that you own or have secured all legal rights necessary for
        the Content submitted by you to be used by you, FluteNFeather, and
        others as described and otherwise contemplated in these Terms and
        Conditions. You understand that other users will have access to the
        Content and that neither they or FluteNFeather have any obligation to
        you or anyone else to maintain the confidentiality of the Content.
      </p>
      <p>
        You will not upload, display, or otherwise provide on or through the
        Service any Content that: (i) is libelous, defamatory, abusive,
        threatening, harassing, hateful, offensive, or otherwise violates any
        law or infringes upon the right of any third party (including copyright,
        trademark, privacy, publicity, or other personal or proprietary rights);
        (ii) in FluteNFeather’s sole judgment, is objectionable, restricts or
        inhibits any other person from using the Service, or may expose
        FluteNFeather or its users to any harm or liability of any kind; or
        (iii) violates FluteNFeather’s Community Guidelines.
      </p>
    </div>
    <div class="section">
      <h2>8. Indemnification of FluteNFeather</h2>
      <p>
        You agree to defend, indemnify and hold harmless FluteNFeather and its
        directors, officers, employees, contractors, agents, suppliers,
        licensors, successors and assigns, from and against any and all losses,
        claims, causes of action, obligations, liabilities and damages
        whatsoever, including attorneys' fees, arising out of or relating to
        your access or use of the Service, any false representation made to us
        (as part of these Terms and Conditions or otherwise), your breach of any
        of these Terms and Conditions, or any claim that any translation we
        provide to you is inaccurate, inappropriate or defective in any way
        whatsoever.
      </p>
    </div>
    <div class="section">
      <h2>9. License to Apps</h2>
      <p>
        Subject to the terms of these Terms and Conditions, FluteNFeather grants
        you a non-transferable, non-exclusive license to download, install, and
        use one copy of each App in object code form only on an interactive
        wireless device that you own or control. You may not derive or attempt
        to derive the source code of all or any portion of any App, permit any
        third party to derive or attempt to derive such source code, or reverse
        engineer, decompile, disassemble, or translate any App or any part
        thereof. FluteNFeather and its licensors own and shall retain all
        intellectual property rights and other rights in and to the Apps, and
        any changes, modifications, or corrections thereto. The following terms
        and conditions apply to you only if you are using the Apps from the
        Apple App Store. To the extent the other terms and conditions of these
        Terms and Conditions are less restrictive than, or otherwise conflict
        with, the terms and conditions of this paragraph, the more restrictive
        or conflicting terms and conditions in this paragraph apply, but solely
        with respect to Apps from the Apple App Store. You acknowledge and agree
        that these Terms and Conditions are solely between you and
        FluteNFeather, not Apple, and that Apple has no responsibility for the
        Apps or content thereof. Your use of any App must comply with the App
        Store Terms of Service. You acknowledge that Apple has no obligation
        whatsoever to furnish any maintenance and support services with respect
        to the Apps. In the event of any failure of any App to conform to any
        applicable warranty, you may notify Apple, and Apple will refund the
        purchase price, if any, for the App to you; to the maximum extent
        permitted by applicable law, Apple will have no other warranty
        obligation whatsoever with respect to the Apps, and any other claims,
        losses, liabilities, damages, costs or expenses attributable to any
        failure to conform to any warranty will be solely governed by these
        Terms and Conditions. You and FluteNFeather acknowledge that Apple is
        not responsible for addressing any claims of you or any third party
        relating to the Apps or your possession and/or use of any App,
        including, but not limited to: (i) product liability claims; (ii) any
        claim that an App fails to conform to any applicable legal or regulatory
        requirement; and (iii) claims arising under consumer protection or
        similar legislation. You and FluteNFeather acknowledge that, in the
        event of any third-party claim that any App or your possession and use
        of that App infringes that third party’s intellectual property rights,
        FluteNFeather, not Apple, will be solely responsible for the
        investigation, defense, settlement and discharge of any such
        intellectual property infringement claim to the extent required by these
        Terms and Conditions. You must comply with applicable third party terms
        of agreement when using any App. You and FluteNFeather acknowledge and
        agree that Apple, and Apple’s subsidiaries, are third party
        beneficiaries of these Terms and Conditions as they relate to your
        license of the Apps, and that, upon your acceptance of these Terms and
        Conditions, Apple will have the right (and will be deemed to have
        accepted the right) to enforce these Terms and Conditions against you as
        a third party beneficiary thereof.
      </p>
    </div>
    <div class="section">
      <h2>10. In-App Purchases</h2>
      <p>
        If you purchase an auto-renewing periodic subscription through the
        Service, your FluteNFeather account will be billed continuously for the
        subscription until you terminate it as set forth below. After your
        initial subscription period, and again after any subsequent subscription
        period, your subscription will automatically renew for an additional
        equivalent period. If you do not wish your subscription to renew
        automatically, or if you want to change or terminate your subscription,
        you will need to log in to your FluteNFeather account and follow
        instructions to terminate or change your subscription, even if you have
        deleted your account.
      </p>
      <p>
        In the Service, you may purchase, with “real world” money, a limited,
        personal, non-transferable, non-sublicensable, revocable license to use
        (a) “virtual currency,” including but not limited to virtual gems,
        solely for use in the Service, and (b) “virtual in-app items” (together
        with “virtual currency,” “Virtual Items”). You are allowed to purchase
        Virtual Items through the Service, and not in any other way.
      </p>
      <p>
        FluteNFeather may manage, regulate, control, modify, or eliminate
        Virtual Items at any time, with or without notice. FluteNFeather may
        update the pricing of Virtual Items at any time in its sole discretion,
        and may add new Virtual Items for additional fees. FluteNFeather shall
        have no liability to you or any third party in the event that
        FluteNFeather exercises any such rights.
      </p>
      <p>
        The transfer of Virtual Items is prohibited except where expressly
        authorized in the Service. Other than as expressly authorized in the
        Service, you shall not sell, redeem or otherwise transfer Virtual Items
        to any person or entity, including but not limited to Company, another
        user, or any third party.
      </p>
      <p>
        You agree to pay all fees and applicable taxes incurred by you or anyone
        using a FluteNFeather account registered to you. FluteNFeather may
        revise the pricing for the goods and services offered through the
        Service at any time. All information that you provide in connection with
        a purchase or transaction or other monetary transaction interaction with
        the Service must be accurate, complete, and current. You agree to pay
        all charges incurred by users of your credit card, debit card, or other
        payment method used in connection with a purchase or transaction or
        other monetary transaction interaction with the Service at the prices in
        effect when such charges are incurred. You will pay any applicable
        taxes, if any, relating to any such purchases, transactions or other
        monetary transaction interactions.
      </p>
    </div>
    <div class="section">
      <h2>11. Payment Processors</h2>
      <p>
        All financial transactions made in connection with the Service will be
        processed by a third party in accordance with their respective terms of
        use, privacy policy, and/or any applicable payment terms and conditions.
        We encourage you to learn about the practices of such third party. In no
        event will FluteNFeather be responsible for the actions or inactions of
        any third party payment processor, including, but not limited to, system
        downtime or payment service outages.
      </p>
    </div>
    <div class="section">
      <h2>12. Refund Policy</h2>
      <p>
        All payments are nonrefundable. There are no refunds or credits for
        Virtual Items or partially used membership periods. However,
        FluteNFeather may grant refunds or credits on a case-by-case basis at
        our sole discretion. FluteNFeather’s grant of a refund or credit in one
        instance does not obligate FluteNFeather to provide a refund or credit
        in the future, under any circumstances. If you cancel your periodic
        subscription to a paid FluteNFeather service, you will continue to have
        access to the service through the end of your current billing period.
      </p>
    </div>
    <div class="section">
      <h2>13. Third-Party Links, Sites, and Services</h2>
      <p>
        The Service may contain links to third-party websites, advertisers,
        services, special offers, or other events or activities that are not
        owned or controlled by FluteNFeather. We do not endorse or assume any
        responsibility for any such third-party sites, information, materials,
        products, or services. If you access any third party website, service,
        or content from FluteNFeather, you understand that these Terms and
        Conditions and our Privacy Policy do not apply to your use of such
        sites. You expressly acknowledge and agree that FluteNFeather shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        arising from your use of any third-party website, service, or content.
      </p>
      <p>
        The Service may include advertisements, which may be targeted to the
        Content or information on the Service, or other information. The types
        and extent of advertising by FluteNFeather on the Service are subject to
        change. In consideration for FluteNFeather granting you access to and
        use of the Service, you agree that FluteNFeather and its third party
        providers and partners may place such advertising in connection with the
        display of content or information submitted by you or others.
      </p>
    </div>
    <div class="section">
      <h2>14. NO REPRESENTATIONS OR WARRANTIES BY FluteNFeather</h2>
      <p>
        THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT
        THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR
        PROVIDED TO YOU BY FluteNFeather ARE PROVIDED TO YOU ON AN “AS IS”
        BASIS. FluteNFeather AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR
        WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS OR
        IMPLIED, AND ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
        NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE
        GENERALITY OF THE FOREGOING, FluteNFeather DOES NOT MAKE ANY
        REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY, SERVICE
        AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR-FREE OPERATION,
        RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT. ACCESS AND USE OF
        THE SERVICE MAY BE UNAVAILABLE DURING PERIODS OF PEAK DEMAND, SYSTEM
        UPGRADES, MALFUNCTIONS OR SCHEDULED OR UNSCHEDULED MAINTENANCE OR FOR
        OTHER REASONS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
        WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
      </p>
    </div>
    <div class="section">
      <h2>15. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h2>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        FluteNFeather BE LIABLE TO YOU OR ANY THIRD PARTY CLAIMING THROUGH YOU
        (WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHER THEORY) FOR
        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES
        ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE INABILITY TO
        ACCESS OR USE, THE SERVICE OR ANY PORTION THEREOF, INCLUDING BUT NOT
        LIMITED TO THE LOSS OF USE OF THE SERVICE, INACCURATE RESULTS, LOSS OF
        PROFITS, BUSINESS INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR
        CORRUPTION OF DATA OR DATA BEING RENDERED INACCURATE, THE COST OF
        RECOVERING ANY DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD
        PARTIES FOR ANY DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, TELEPHONES OR
        OTHER PROPERTY, EVEN IF FluteNFeather HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, FluteNFeather’S LIABILITY TO YOU OR ANY THIRD PARTY
        CLAIMING THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE
        FORM OF THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
        FluteNFeather FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL
        ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE
        EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
      </p>
      <p>
        You understand and agree that we have set our prices and entered into
        these Terms and Conditions with you in reliance upon the limitations of
        liability set forth in these Terms and Conditions, which allocate risk
        between us and form the basis of a bargain between the parties.
      </p>
    </div>
    <div class="section">
      <h2>16. Termination</h2>
      <p>
        FluteNFeather may terminate your access and use of the Service
        immediately at any time, for any reason, and at such time you will have
        no further right to use the Service. You may terminate your
        FluteNFeather account at any time by following the instructions
        available through the Service. Sections 1, 6, 8, and 10-28 of these
        Terms and Conditions shall survive any termination.
      </p>
    </div>

    <div class="section">
      <h2>17. Proprietary Rights in Service Content and Activity Materials</h2>
      <p>
        All content available through the Service, including designs, text,
        graphics, images, information, software, audio and other files, and
        their selection and arrangement (“Service Content”), are the proprietary
        property of FluteNFeather or its licensors. No Service Content may be
        modified, copied, distributed, framed, reproduced, republished,
        downloaded, scraped, displayed, posted, transmitted, or sold in any form
        or by any means, in whole or in part, other than as expressly permitted
        in these Terms and Conditions. You may not use any data mining, robots,
        scraping, or similar data gathering or extraction methods to obtain
        Service Content. As between you and FluteNFeather, all data,
        information, and materials generated from your access and use of the
        educational activities made available on or through the Service,
        including translated content generated by you (“Activity Materials”),
        shall be exclusively owned by FluteNFeather, and you shall not have any
        right to use such Activity Materials except as expressly authorized by
        these Terms and Conditions. By using the Service, you hereby assign to
        FluteNFeather any and all rights, title, and interest, including any
        intellectual property rights or proprietary rights, in the Activity
        Materials. All rights of FluteNFeather or its licensors that are not
        expressly granted in these Terms and Conditions are reserved to
        FluteNFeather and its licensors.
      </p>
    </div>
    <div class="section">
      <h2>18. Trademarks</h2>
      <p>
        “FluteNFeather” and all other trademarks, service marks, graphics, and
        logos used in connection with the Service are trademarks or service
        marks of FluteNFeather or their respective owners, and certain of them
        are registered with the United States Patent and Trademark Office or
        other trademark authorities. Access and use of the Service does not
        grant or provide you with the right or license to reproduce or otherwise
        use the FluteNFeather name or any FluteNFeather or third-party
        trademarks, service marks, graphics, or logos.
      </p>
    </div>
    <div class="section">
      <h2>19. Privacy</h2>
      <p>
        Use of the Service is also governed by our Privacy Policy, a copy of
        which is located at
        <a href="/privacy" class="text-primary">www.flutenfeather.com/privacy</a
        >. By using the Service, you consent to the terms of the Privacy Policy.
      </p>
    </div>
    <div class="section">
      <h2>20. Promotion Code Terms</h2>
      <p>
        FluteNFeather may offer codes that can be redeemed for certain Services
        (“Promotion Codes”). Unless otherwise advertised, Promotion Codes are
        subject to the following terms, in addition to all other Terms and
        Conditions. By using or redeeming a Promotion Code, you accept and agree
        to all applicable terms.
      </p>
      <u>Code Providers</u>
      <p>
        In some cases, you may receive your Promotion Code from an authorized
        third party, such as your employer or another business (“Code
        Provider”).
      </p>
      <p>
        Your Code Provider may set additional limitations on your eligibility
        for the Promotion Code and may terminate your use of the Promotion Code.
        For example, if you obtained a prepaid subscription to FluteNFeather by
        redeeming a Promotion Code provided by your employer, your employer may
        terminate your prepaid subscription if your employment ceases. If your
        Code Provider terminates your use of the Promotion Code, you will retain
        control of your FluteNFeather account and data, and may subscribe to
        FluteNFeather on your own.
      </p>
      <p>
        If you redeem a Promotion Code from a Code Provider, you understand and
        agree that FluteNFeather may share data relating to your use of the
        Promotion Code with your Code Provider, including both anonymized,
        aggregate data, and individual usage data.
      </p>
      <u>Types of Promotion Codes</u>
      <p>FluteNFeather may offer the following types of Promotion Codes:</p>
      <ul>
        <li>
          Promotion Codes for Virtual Items (“Virtual Item Code”). All Virtual
          Items are subject to Section 10 of these Terms and Conditions.
        </li>
        <li>
          Promotion Codes for an extended free trial of a FluteNFeather
          subscription plan, such as Super FluteNFeather or FluteNFeather Max
          (“Extended Free Trial Code”). Existing FluteNFeather subscribers are
          not eligible to redeem Extended Free Trial Codes. Furthermore, you may
          redeem only one Extended Free Trial Code per calendar year.
        </li>
        <li>
          Promotion Codes for a prepaid subscription to a FluteNFeather
          subscription plan (“Prepaid Code”). Existing FluteNFeather subscribers
          are not eligible to redeem Prepaid Codes.
        </li>
      </ul>
    </div>
    <div class="section">
      <h2>21. Automatic Renewal Terms for Extended Free Trial Codes</h2>
      <p>
        To redeem an Extended Free Trial Code, you will be required to select a
        subscription plan for after your extended free trial and submit valid
        payment details that are accepted by FluteNFeather.
      </p>
      <p>
        <b
          >If you redeem an Extended Free Trial Code, unless you cancel at least
          24 hours before your extended free trial ends, you will automatically
          become a recurring subscriber to the subscription plan you selected
          after your extended free trial ends.</b
        >
        The payment method you provided will then automatically be charged for
        the subscription price you selected. If you cancel during your extended
        free trial, you will lose access to the subscription service at the end
        of the free trial.
      </p>
      <u>Terms Applicable to All Promotion Codes</u>
      <p>
        Promotion Codes have no dollar value, and may not be redeemed for cash
        or a refund on an existing subscription or past purchase.
      </p>
      <p>You may redeem each Promotion Code only once.</p>
      <p>
        Some Promotion Codes may be subject to expiration dates or an overall
        limit of allowed redemptions.
      </p>
      <p>
        FluteNFeather may modify, suspend, or terminate a Promotion Code at any
        time for any reason.
      </p>
      <p>
        Reselling or transferring Promotion Codes is strictly prohibited. Any
        Promotion Codes that have been resold or transferred are null and void.
      </p>
      <p>
        If any Promotion Code is redeemed or used in violation of this Section
        or other applicable terms, FluteNFeather may invalidate that Promotion
        Code and terminate any Services obtained from that Promotion Code,
        without refund or exchange of any kind. Furthermore, if FluteNFeather
        determines that a user has abusively violated any applicable terms,
        FluteNFeather may ban that user from redeeming or using Promotion Codes.
      </p>
    </div>
    <div class="section">
      <h2>
        22. Notice for Claims of Copyright Violations and Agent for Notice
      </h2>
      <p>
        If you are a copyright owner and have a good faith belief that any
        material available through the Service infringes upon your copyrights,
        you may submit a copyright infringement notification to FluteNFeather
        pursuant to the Digital Millennium Copyright Act by providing us with
        the following information in writing:
      </p>
      <ul>
        <li>
          an electronic or physical signature of the copyright owner or the
          person authorized to act on behalf of the owner of the copyright
          interest;
        </li>
        <li>
          a description of the copyrighted work that you claim has been
          infringed;
        </li>
        <li>
          a description of where the material that you claim is infringing is
          located on the Service, with enough detail that we may find it on the
          Service;
        </li>
        <li>your address, telephone number, and email address;</li>
        <li>
          a statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
          and
        </li>
        <li>
          a statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or are authorized to act on the copyright owner's behalf.
        </li>
      </ul>
    </div>
    <div class="section">
      <p>
        23. Please consult your legal counsel for further details or see 17
        U.S.C. §512(c)(3). FluteNFeather’s Agent for Notice of claims of
        copyright infringement can be reached as follows:
      </p>
      <p>By email: rajesh.kumar@flutenfeather.com</p>
      <p>
        For clarity, only copyright infringement notices should go to our
        Copyright Agent. You acknowledge that if you fail to comply with all of
        the requirements of this section your notice may not be valid.
      </p>
      <p>
        If you believe the content that was removed (or to which access was
        disabled) is not infringing, or that you have the authorization from the
        copyright owner, the copyright owner's agent, or pursuant to the law, to
        post and use such content, you may submit a counter-notice to the
        address listed above containing the following information:
      </p>
      <ul>
        <li>Your physical or electronic signature;</li>
        <li>
          Identification of the content that has been removed or to which access
          has been disabled and the location at which the content appeared
          before it was removed or disabled;
        </li>
        <li>
          A statement that you have a good faith belief that the content was
          removed or disabled as a result of mistake or a misidentification of
          the content; and
        </li>
        <li>
          Your name, physical address, telephone number, and e-mail address, a
          statement that you consent to the jurisdiction of the federal court in
          the judicial district in which you reside, or if you reside outside of
          the United States, then of the federal court in Newark Delaware, and a
          statement that you will accept service of process from the person who
          provided notification of the alleged infringement.
        </li>
      </ul>
    </div>
    <div class="section">
      <p>
        24. After we receive your counter-notification, we will forward it to
        the party who submitted the original claim of copyright infringement.
        Please note that when we forward the counter-notification, it includes
        your personal information. By submitting a counter-notification, you
        consent to having your information revealed in this way. We will not
        forward the counter-notification to any party other than the original
        claimant.
      </p>
      <p>
        After we send out the counter-notification, the claimant must then
        notify us within 10 days that he or she has filed an action seeking a
        court order to restrain you from engaging in infringing activity
        relating to the content that was removed or disabled. If we receive such
        notification, we will be unable to restore the material. If we do not
        receive such notification, we may reinstate the material.
      </p>
    </div>
    <div class="section">
      <h2>25. Governing Law</h2>
      <p>
        These Terms and Conditions shall be governed by and construed under the
        laws of the State of Delaware, United States of America, excluding any
        conflict of laws provisions, regardless of your country of origin or
        where you access the Service.
      </p>
    </div>
    <div class="section">
      <h2>26. Choice of Forum</h2>
      <p>
        Subject to Section 24 (Dispute Resolution & Arbitration), all judicial
        proceedings relating to or arising out of these Terms and Conditions or
        the Service shall be brought in the state or federal courts sitting in
        Newark, Delaware. Both parties hereby consent to the personal
        jurisdiction of the state and federal courts sitting in Newark, Delaware
        and waive any objections to venue in those courts.
      </p>
    </div>
    <div class="section">
      <h2>27. Dispute Resolution & Arbitration</h2>
      <p class="fw-700">
        ANY DISPUTE OR CLAIM RELATING TO THESE TERMS AND CONDITIONS OR THE
        SERVICE AND SEEKING MONETARY RELIEF SHALL BE RESOLVED BY BINDING
        ARBITRATION ON AN INDIVIDUAL BASIS ACCORDING TO THIS SECTION.
      </p>
      <p>
        Prior to initiating any arbitration, the party seeking arbitration
        (“Claimant”) must first send a written Notice of Claim to the other
        party (“Respondent”) by email. This Notice must contain the Claimant’s
        name, address, and contact information; the identity of counsel (if
        any); the nature and basis of the claim; and the specific relief sought.
        Within thirty (30) days of receipt of the Notice, the parties shall
        engage in at least one good-faith settlement conference by telephone or
        video conference. An individual party must be personally present at this
        settlement conference, and an entity party must be present at this
        settlement conference through an employee with settlement authority,
        though both parties may bring counsel as well. Arbitration shall be
        initiated only if no settlement can be reached at this settlement
        conference.
      </p>
      <p>
        The arbitration shall be conducted by a single arbitrator and
        administered by the American Arbitration Association (“AAA”) according
        to its
        <a
          href="https://adr.org/sites/default/files/Consumer%20Rules.pdf"
          target="_blank"
          >Consumer Arbitration Rules, Procedures for the Resolution of Disputes
          Through Document Submission, Consumer Fee Schedule,
        </a>
        and
        <a
          href="https://www.adr.org/sites/default/files/Supplementary_Rules_MultipleCase_Filings.pdf"
          target="_blank"
          >Supplementary Rules for Multiple Case Filings</a
        >
        (“AAA Rules”), and the provisions of this section. Where they conflict,
        the provisions of this section shall take precedence over any AAA Rules.
        If the AAA is unavailable to conduct an arbitration, the parties shall
        arbitrate through another mutually agreed upon organization or on an ad
        hoc basis according to the rules in this section. The arbitration shall
        be conducted in the English language.
      </p>
      <p>
        If a claim is within the jurisdiction of a small claims court, either
        party may choose to have the case heard in the small claims court on an
        individual basis, instead of arbitration.
      </p>
      <p>
        If twenty-five (25) or more similar Demands for Arbitration are filed
        against or on behalf of the same party or related parties, and if
        representation of the parties is consistent or coordinated across the
        cases, then the AAA’s
        <a
          href="https://www.adr.org/sites/default/files/Supplementary_Rules_MultipleCase_Filings.pdf"
          target="_blank"
          >Supplementary Rules for Multiple Case Filings
        </a>
        shall apply. Counsel filing twenty-five (25) or more similar Demands for
        Arbitration against FluteNFeather must comply with the AAA’s
        <a
          href="https://www.adr.org/consumer/multiple-case-filing"
          target="_blank"
        >
          Filing Requirements for Multiple Case Filings</a
        >. There shall be no class or collective arbitration, or joinder of
        claims, except as provided for in the AAA’s
        <a
          href="https://www.adr.org/sites/default/files/Supplementary_Rules_MultipleCase_Filings.pdf"
          target="_blank"
          >Supplementary Rules for Multiple Case Filings.</a
        >
      </p>
      <p>
        If you do not want to arbitrate disputes with FluteNFeather and you are
        an individual, you may opt out of this arbitration agreement by sending
        an email to rajesh.kumar@flutenfeather.com within 30 days of the day you
        first access or use the Service.
      </p>
      <p>
        Notwithstanding the above, either party may apply for and obtain
        non-monetary, injunctive remedies or urgent relief in court without
        engaging in any arbitration or informal dispute resolution process.
      </p>
    </div>
    <div class="section">
      <h2>28. Language</h2>
      <p>
        This agreement was originally written in English (US). To the extent any
        translated version of this agreement conflicts with the English version,
        the English version controls.
      </p>
    </div>
    <div class="section">
      <h2>29. California Resident</h2>
      <p>
        If you are a California resident, in accordance with Cal. Civ. Code §
        1789.3, you may report complaints to the Complaint Assistance Unit of
        the Division of Consumer Services of the California Department of
        Consumer Affairs by contacting them in writing at 1625 North Market
        Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800)
        952-5210.
      </p>
    </div>
    <div class="section">
      <h2>30. Additional Terms for Android Users in China</h2>
      <p>
        These Terms and Conditions are a contract between you and FluteNFeather,
        Inc., a Delaware corporation based in the United States. All paid
        FluteNFeather Services are developed and operated from the United
        States. When you pay for a FluteNFeather Service, your payment goes to
        FluteNFeather, Inc. in the United States. By purchasing or subscribing
        to a paid FluteNFeather service, you agree that you are buying an
        offshore service based in the United States.
      </p>
    </div>
    <div class="section">
      <h2>31. Miscellaneous</h2>
      <p>
        These Terms and Conditions constitute the entire agreement between
        FluteNFeather and you concerning the subject matter hereof. In the event
        that any of the Terms and Conditions are held by a court or other
        tribunal of competent jurisdiction to be unenforceable, such provisions
        shall be limited or eliminated to the minimum extent necessary so that
        these Terms and Conditions shall otherwise remain in full force and
        effect. A waiver by FluteNFeather or you of any provision of these Terms
        and Conditions or any breach thereof, in any one instance, will not
        waive such term or condition or any subsequent breach thereof.
        FluteNFeather may assign its rights or obligations under these Terms and
        Conditions without condition. These Terms and Conditions will be binding
        upon and will inure to the benefit of FluteNFeather and you, and
        FluteNFeather's and your respective successors and permitted assigns.
      </p>
    </div>
  </div>
</template>

<script>
import { updateTitle } from "@/utils/utils";

export default {
  name: "TermAndCondition",
  mounted() {
    updateTitle("Terms And Condition");
  },
};
</script>
<style lang="scss" scoped>
.section h2 {
  font-size: 18px;
  font-weight: 700;
}
.section p {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}
.section u {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.section ul li {
  color: var(--Gris-oscuro, #6b6b6b);
  text-align: justify;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: auto !important;
}
.section ul li :hover {
  cursor: auto !important;
  color: var(--Gris-oscuro, #6b6b6b);
}
</style>