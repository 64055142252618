var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row mx-0 justify-content-center align-items-center container-min-height pt-5per pb-5per"},[_c('div',{staticClass:"registerCard"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-xl-12 p-20"},[_c('div',{staticClass:"registerHeader pb-1"},[_vm._v("Welcome to flute n feather")]),_c('div',{staticClass:"registerSecondaryHeader pb-2"},[_vm._v(" Your child’s personal companion ")]),_c('form',[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('text-field',{attrs:{"id":"fullName","name":"fullName","placeholder":"Enter your child's name","valid":!_vm.$v.form_data.fullName.$error,"errorMessage":_vm.isSubmitted && !_vm.$v.form_data.fullName.required
                  ? 'Full Name is required'
                  : !_vm.$v.form_data.fullName.regex
                  ? 'Only alphabetic characters and spaces are allowed'
                  : ''},model:{value:(_vm.form_data.fullName),callback:function ($$v) {_vm.$set(_vm.form_data, "fullName", $$v)},expression:"form_data.fullName"}})],1),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-6 col-xl-6 p-0 ageResponsive"},[_vm._m(1),_c('v-select',{staticClass:"custom-v-select",class:{ 'is-invalid': !_vm.$v.form_data.age.$error },attrs:{"placeholder":"Choose","searchable":false,"clearable":false,"options":_vm.ageArr},model:{value:(_vm.form_data.age),callback:function ($$v) {_vm.$set(_vm.form_data, "age", $$v)},expression:"form_data.age"}}),(_vm.isSubmitted && !_vm.$v.form_data.age.required)?_c('span',{staticClass:"error"},[_vm._v("Age is required")]):_vm._e()],1),_c('div',{staticClass:"col-12 col-sm-12 col-md-6 col-xl-6 p-0 genderResponsive"},[_vm._m(2),_c('v-select',{staticClass:"custom-v-select",class:{ 'is-invalid': !_vm.$v.form_data.gender.$error },attrs:{"placeholder":"Choose","searchable":false,"clearable":false,"options":_vm.genderArr},model:{value:(_vm.form_data.gender),callback:function ($$v) {_vm.$set(_vm.form_data, "gender", $$v)},expression:"form_data.gender"}}),(_vm.isSubmitted && !_vm.$v.form_data.gender.required)?_c('span',{staticClass:"error"},[_vm._v("Gender is required")]):_vm._e()],1)])]),_c('div',{staticClass:"form-group"},[_vm._m(3),_c('text-field',{attrs:{"id":"email","name":"email","placeholder":"Enter your email address","valid":!_vm.$v.form_data.email.$error,"readOnly":true,"errorMessage":_vm.isSubmitted && !_vm.$v.form_data.email.required
                  ? 'Email is required'
                  : !_vm.$v.form_data.email.email
                  ? 'Please enter a valid email address'
                  : ''},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}})],1),_c('div',{staticClass:"form-group position-relative"},[_c('label',{staticClass:"label"},[_vm._v("Phone number(Optional)")]),_c('span',{staticClass:"country-code px-2"},[_vm._v("+1")]),_c('text-field',{attrs:{"id":"mobileNumber","name":"mobileNumber","placeholder":"89999990","maxLength":10,"type":"text","className":"textIndent","valid":!_vm.$v.form_data.mobileNumber.$error,"errorMessage":_vm.isSubmitted && !_vm.$v.form_data.mobileNumber.isValidPhoneNumber
                  ? 'Please enter a valid phone number'
                  : ''},model:{value:(_vm.form_data.mobileNumber),callback:function ($$v) {_vm.$set(_vm.form_data, "mobileNumber", $$v)},expression:"form_data.mobileNumber"}})],1),_c('div',{staticClass:"form-group mb-10 pt-2"},[_c('div',{staticClass:"custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}}),_c('label',{staticClass:"m-0 label-color pl-2"},[_vm._v(" I agree to the ")]),_c('a',{staticClass:"pl-2 text-primary",attrs:{"href":"/terms","target":"_blank"}},[_vm._v(" Terms & Conditions ")]),_c('label',{staticClass:"m-0 label-color pl-2"},[_vm._v(" and ")]),_c('a',{staticClass:"pl-2 text-primary",attrs:{"href":"/privacy","target":"_blank"}},[_vm._v(" Privacy Policy ")])])]),_c('Button',{attrs:{"disabled":!_vm.terms,"btnClass":_vm.buttonClass},on:{"click":_vm.register}},[_vm._v("Sign up")])],1),_c('br'),_vm._m(4)])])]),_c('loading',{attrs:{"active":_vm.isLoading,"color":"#9651F1","backgroundColor":"#C9C9C9","height":100,"width":100}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"label"},[_vm._v("Child's Full name"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"label"},[_vm._v("Child's Age"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"label"},[_vm._v("Child's Gender"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"label"},[_vm._v("Email Address"),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center font-size-16 fw-600"},[_vm._v(" Already have an account? Click here to "),_c('a',{staticClass:"text-primary",attrs:{"href":"/login"}},[_vm._v("Sign in")])])
}]

export { render, staticRenderFns }