<template>
  <div class="container-fluid">
    <!-- Sidebar for non-topic details page -->
    <div class="sidebar" v-if="!isTopicDetailsPage">
      <div class="logo-container">
        <img src="../../assets/fnf_logo.png" alt="FnF Logo" class="fnf-logo" />
      </div>
      <div class="microphone-container" v-if="!isFunTopic">
        <button @click="fun" class="button">
          <img
            src="../../assets/Fnff-Microphone-Mute.png"
            alt="Microphone Icon"
            class="microphone-icon"
          />
        </button>
      </div>
      <div class="icon-container">  
        <!-- <button class="sidebar-icon button" disabled>
          <img src="../../assets/profile_icon.png" alt="Profile Icon" />
        </button>
        <button class="sidebar-icon button" disabled>
          <img src="../../assets/settings_icon.png" alt="Settings Icon" />
        </button> -->
        <button @click="LogoutModal" class="sidebar-icon button">
          <img src="../../assets/logout_icon.png" alt="Logout Icon" />
        </button>
      </div>
    </div>

    <!-- Sidebar for topic details page -->
    <div class="topic-sidebar" :class="isTopicDetailsPage ? 'topic-sidebar-width': ''" v-else>
      <div class="sidebar-content">
        <span>{{ truncateText(subjectName, 10, 15) }}</span>
        <span class="separator">|</span>
        <span>{{ truncateText(categoryName, 10, 15) }}</span>
        <span class="separator">|</span>
        <span class="topicName">{{topicName}}</span>
      </div>
    </div>

    <!-- Logout Confirmation Modal -->
    <div v-if="showLogoutModal" class="sidebar-overlay">
      <div class="modal-content">
        <!-- Modal Header -->
        <!-- <div class="modal-header">
          <button @click="showLogoutModal = false" class="close-button">&times;</button>
        </div> -->
        <!-- Modal Body -->
        <div class="modal-body">
          <span>Are you sure you want to sign out?</span>
        </div>
        <!-- Modal Footer -->
        <div class="modal-footer">
          <button @click="showLogoutModal = false" class="cancel-logout">
            Cancel
          </button>
          <button @click="confirmLogout" class="confirm-logout">
            Sign out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SidebarComponent",
  data() {
    return {
      showLogoutModal: false,
    };
  },
  computed: {
    isFunTopic() {
      return this.$route.name === "Fun";
    },
    isTopic() {
      return this.$route.name === "TopicDetailsComponentNew";
    },
    isTopicDetailsPage() {
      return this.$route.name === "topicDetails";
    },
    subjectName() {
      return (
        this.$store.getters.getSubjectName ||
        localStorage.getItem("subjectName")
      );
    },
    categoryName() {
      return (
        this.$store.getters.getCategoryName ||
        localStorage.getItem("categoryName")
      );
    },
    topicName() {
      return (
        this.$store.getters.getTopicName || localStorage.getItem("topicName")
      );
    },
  },

  methods: {
     truncateText(text, maxLengthMobile, maxLengthDesktop) {
    const isMobile = window.innerWidth <= 956; // Adjust the breakpoint for mobile
    const maxLength = isMobile ? maxLengthMobile : maxLengthDesktop;

    return text.length > maxLength ? text.slice(0, maxLength).concat('...') : text;
  },
    LogoutModal() {
      this.showLogoutModal = true;
    },
    confirmLogout() {
      let dataTosend = {};
      (dataTosend.session_uuid = localStorage.getItem("token")),
        axios
          .post(
            process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
              process.env.VUE_APP_FLUTE_N_FEATHER_LOGOUT_URL_PATH,
            dataTosend
          )
          .then((response) => {
            console.log("responce", response.data);
                  localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("email");
      localStorage.removeItem("isRefreshed");

      // Redirect to login or home page
      this.$router.push("/login");
      this.showLogoutModal = false; // Close the modal after logout
          })
          .catch((error) => {
            console.error("Error fetching subject", error);
          });
    },
    fun() {
      this.$router.push("/fun");
    },
    settings() {
      this.$router.push("/settings");
    },
    profile() {
      this.$router.push("/profile");
    },
  },
};
</script>

<style scoped>
.container-fluid {
  display: flex;
  position: absolute; /* Positions relative to the viewport */
  top: 0; /* Start at the top of the viewport */
  left: 0; /* Start at the left of the viewport */
  width: 100%; /* Full width */
  min-height: 100vh; /* Keep the height as desired */
  overflow: hidden; /* Prevent scrollbars */
  box-sizing: border-box; /* Include padding in size calculation */
  padding: 20px; /* Default padding */

  /* Extra small devices: Portrait phones, smaller than 576px */
  @media (max-width: 575.98px) and (max-height: 640px) {
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: 100vh; /* Adjust height for shorter devices */
  }

  /* Small devices: Landscape phones and smaller tablets */
  @media (min-width: 576px) and (max-width: 767.98px) and (max-height: 800px) {
    padding: 15px; /* Slightly larger padding */
    min-height: 100vh; /* Adjust height for landscape phones */
  }

  /* Medium devices: Tablets */
  @media (min-width: 768px) and (max-width: 1023.98px) and (max-height: 1024px) {
    padding: 20px; /* Default padding for medium screens */
    min-height: 100vh; /* Adjust height for tablets */
  }

  /* Large devices: Small laptops and desktops */
  @media (min-width: 1024px) and (max-width: 1365.98px) and (max-height: 1200px) {
    padding: 25px; /* Increase padding slightly */
    min-height: 100vh; /* Adjust height for small desktops */
  }

  /* Extra-large devices: Desktops and larger laptops */
  @media (min-width: 1366px) {
    padding: 30px; /* Ample padding for large screens */
    min-height: 100vh; /* Default full height for larger desktops */
  }
}
.topic-sidebar-width {
  width: 60px !important;
}
.sidebar,
.topic-sidebar {
  width: 70px;
  background-color: #fbf8ec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  position: fixed; /* Make the sidebar sticky */
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 4999; /* Ensure it stays above other elements */
  height: 100vh; /* Full height of the viewport */
  box-sizing: border-box; /* Include padding in height calculation */
  overflow: hidden; /* Prevent any overflow from content */
}

/* Ensure it remains fixed on smaller devices */
@media only screen and (max-width: 1024px) {
  .sidebar,
  .topic-sidebar {
    width: 70px; /* Adjust width for tablets and smaller screens */
  }
}

@media only screen and (max-width: 576px) {
  .sidebar,
  .topic-sidebar {
    width: 70px; /* Further adjust width for smaller mobile phones */
  }
}


.logo-container {
  margin-bottom: auto;
}

/* Microphone Centered */
.microphone-container {
  margin: auto 0; /* Push to the center */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fnf-logo {
  width: 52px;
  height: 40px;
  margin-bottom: 1rem;
}
.button {
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.icon-container {
  position: relative; /* Position relative within the fixed sidebar */
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Add spacing between icons */
  margin-top: auto; /* Push to the bottom of the sidebar */
  margin-bottom: 1rem; /* Add spacing at the bottom */
}

/* Adjust sidebar icon position for all mobile devices */
@media only screen and (max-width: 1024px) { /* Common breakpoint for tablets and smaller devices */
  .icon-container {
    margin-bottom: 2rem; /* Optional spacing at the bottom */
  }
}
/* Adjust sidebar icon position for all mobile devices */
@media only screen and (max-width: 768px) { /* Common breakpoint for tablets and smaller devices */
  .icon-container {
    margin-bottom: 2rem; /* Optional spacing at the bottom */
  }
}

@media only screen and (max-width: 576px) { /* Common breakpoint for mobile phones */
  .icon-container {
    margin-bottom: 2rem; /* Increase bottom margin for smaller devices */
  }
}


.microphone-icon {
  width: 4rem;
}

.sidebar-icon {
  width: 32px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4999;
}

.modal-content {
  width: auto;
  background-color: #ede6cf;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: flex-end;
  border: none;
  padding: 0px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

/* Modal Body */
.modal-body {
  padding: 10px 0;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

/* Modal Footer */
.modal-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  border: none;
}

/* Modal Buttons */
.confirm-logout,
.cancel-logout {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.confirm-logout {
  background-color: #9651f1;
  color: #fff;
}

.confirm-logout:hover {
  background-color: #784eaf;
}

.cancel-logout {
  background-color: #cccccc;
  color: #000;
}

.cancel-logout:hover {
  background-color: #b3b3b3;
}
.sidebar-content {
  display: flex;
  justify-content: center; /* Center text vertically */
  align-items: center; /* Center text horizontally */
  writing-mode: vertical-rl; /* Rotate text vertically */
  text-align: center; /* Align text */
  transform: rotate(180deg); /* Flip text for proper readability */
  font-size: 14px; /* Font size */
  font-family: Arial, sans-serif; /* Font family */
  color: #333; /* Text color */
  gap: 0.5rem; /* Space between lines */
  margin-top: auto;
}
.topicName {
  font-weight: bold; /* Bold text */
}
.sidebar-content .separator {
  font-size: 12px;
}
@media (max-width: 1024px) {
  .sidebar-content {
    font-size: 12px;
    margin-bottom: 45px;
  }
}
</style>
