<template>
  <div>
    <div class="logo-container">
      <notifications group="dashboard" :duration="2000" width="100%" />
      <img src="../assets/fnf.png" alt="Vue" class="fnf-logo" />
    </div>

    <!-- Main Content -->
    <div
      v-if="!isFinished"
      class="d-flex flex-column align-items-center move-left"
    >
      <div>
        <h2 class="welcome-title mb-1">Welcome to flute n feather</h2>
        <p class="subtitle mb-4" :data-next-step="isNextStep">
          {{
            isNextStep
              ? "Select one subject from each category below"
              : "Your child's interest"
          }}
        </p>

        <!-- Initial Subject Selection -->
        <div v-if="!isNextStep" class="sub-content">
          <label for="subjects" class="label mb-2"
            >Select exactly 3 subjects</label
          >

          <div class="custom-dropdown" ref="dropdown">
            <button
              @click.stop="toggleDropdown"
              :class="['dropdown-toggle', { 'dropdown-open': dropdownOpen }]"
            >
              <span class="dropdown-text">Select subjects</span>
              <span
                :class="[
                  'dropdown-icon',
                  { 'icon-up': dropdownOpen, 'icon-down': !dropdownOpen },
                ]"
              ></span>
            </button>
            <div v-if="dropdownOpen" class="dropdown-menu">
              <div
                v-for="subject in subjects"
                :key="subject.id"
                @click="selectSubject(subject)"
                :class="{ selected: selectedSubjectIds.includes(subject.id) }"
                class="dropdown-item"
              >
                <!-- Radio button SVG -->
                <div class="radio-button">
                  <svg
                    v-if="!selectedSubjectIds.includes(subject.id)"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7" cy="7" r="7" fill="#DADADA" />
                  </svg>
                  <svg
                    v-else
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7" cy="7" r="7" fill="#DADADA" />
                    <circle cx="7" cy="7" r="4" fill="black" />
                  </svg>
                </div>
                <span>{{ subject.name }}</span>
              </div>
            </div>
          </div>
          <p
            v-if="errorMessage"
            :class="[
              'error-message',
              dropdownOpen ? 'dropdown-open' : 'dropdown-closed',
            ]"
          >
            {{ errorMessage }}
          </p>
        </div>

        <!-- Category Selection for Each Subject -->
        <div v-else class="sub-content">
          <label class="label mb-2"
            >Select a minimum of 1 and maximum of 3 categories</label
          >

          <div
            v-for="(subject, index) in selectedSubjects"
            :key="subject.id"
            class="custom-dropdown"
            style="margin-bottom: 1rem"
          >
            <button
              @click.stop="toggleCategoryDropdown(index)"
              :class="[
                'dropdown-toggle',
                { 'dropdown-open': categoryDropdowns[index] },
              ]"
              :disabled="isDropdownDisabled(index)"
            >
              <span class="dropdown-text">{{ subject }}</span>
              <span
                :class="[
                  'dropdown-icon',
                  {
                    'icon-up': categoryDropdowns[index],
                    'icon-down': !categoryDropdowns[index],
                  },
                ]"
              ></span>
            </button>
            <div
              v-if="categoryDropdowns[index]"
              :ref="'categoryDropdown-' + selectedSubjects[index]"
              class="dropdown-menu-category"
            >
              <div
                v-for="category in categories[selectedSubjects[index]]"
                :key="category.id"
                @click="selectCategory(selectedSubjects[index], category)"
                :class="{
                  selected: selectedCategories[
                    selectedSubjects[index]
                  ]?.includes(category.id),
                }"
                class="dropdown-item"
              >
                <!-- Radio button SVG for Category -->
                <div class="radio-button">
                  <svg
                    v-if="
                      !selectedCategories[selectedSubjects[index]]?.includes(
                        category.id
                      )
                    "
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7" cy="7" r="7" fill="#DADADA" />
                  </svg>
                  <svg
                    v-else
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="7" cy="7" r="7" fill="#DADADA" />
                    <circle cx="7" cy="7" r="4" fill="black" />
                  </svg>
                </div>
                <span>{{ category.name }}</span>
              </div>
            </div>
          </div>

          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </div>

        <!-- Next or Finish Button with Dynamic Style -->
        <button
          @click="handleNext"
          ref="nextButton"
          :style="{
            marginTop: buttonMarginTop,
            background: finishButtonEnabled ? '#8142EF' : '#5e6366',
            cursor: 'pointer',
          }"
          class="btn btn-dark next-button"
        >
          {{ isNextStep ? "Finish Setup" : "Next" }}
        </button>
      </div>
    </div>
    <div v-else class="peacock-container">
      <OrientationDetector v-if="isFinished" />
      <div class="peacock-content">
        <!-- Peacock Image -->
        <img
          src="../assets/FeatherCharacter.png"
          alt="Peacock"
          class="peacock-image"
        />

        <!-- SVG with Text -->
        <div class="peacock-svg-wrapper">
          <svg
            width="646"
            height="274"
            viewBox="0 0 646 274"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="peacock-svg"
          >
            <path
              opacity="0.9"
              d="M0.219543 101.235C19.4435 127.896 49.825 134.079 71.9511 134.569V78.2306C71.9511 35.0229 110.886 0 158.921 0H559.03C607.065 0 646 35.0229 646 78.2306V195.769C646 238.977 607.065 274 559.03 274H158.921C110.886 274 71.9511 238.971 71.9511 195.769V188.2C-7.72314 180.276 0.219543 101.235 0.219543 101.235Z"
              fill="white"
            />
          </svg>

          <p class="peacock-text">
            To achieve an optimum experience for your child.<br/><br/>
            Guide them on how to use the app for the first time.
          </p>

        </div>
        <!-- Get Started Button -->
        <button @click="homeRedirect" class="btn btn-dark get-started-button">
          Get Started
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import OrientationDetector from "@/components/common/OrientationDetector.vue";

export default {
  name: "ParentOnboardComponent",
  components: {
    OrientationDetector,
  },

  data() {
    return {
      selectedSubjects: [], // Store selected subject names
      selectedSubjectIds: [], // Store IDs of selected subjects
      selectedCategories: {}, // Store selected categories per subject
      dropdownOpen: false,
      categoryDropdowns: {}, // Track which category dropdowns are open
      subjects: [], // List of subjects fetched from the API
      subjectMap: {}, // Map subject IDs to names
      categories: {}, // Categories for each subject
      errorMessage: "",
      isNextStep: false, // Step flag
      isFinished: false, // Final setup flag
      backButtonPressed: false, // Track back button press state
    };
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave called: navigating to", to.path);
    if (to.path === "/home") {
      next(); // Allow navigation to /home
    } else {
      next(false); // Block navigation
    }
  },
  computed: {
    errorMessageClass() {
      return {
        "dropdown-open": this.dropdownOpen,
        "dropdown-closed": !this.dropdownOpen,
      };
    },

    finishButtonEnabled() {
      if (!this.isNextStep) {
        return this.selectedSubjects.length === 3;
      } else {
        return this.selectedSubjects.every(
          (subject) =>
            this.selectedCategories[subject] &&
            this.selectedCategories[subject].length >= 1
        );
      }
    },
    buttonMarginTop() {
      if (this.isNextStep) {
        let marginTop = "0.5rem";

        this.selectedSubjects.forEach((subject, index) => {
          if (this.categoryDropdowns[index]) {
            if (index === 0) marginTop = "10px";
            else if (index === 1) marginTop = "6px";
            else if (index === 2) marginTop = "60px";
          }
        });
        return marginTop;
      }
      if (this.dropdownOpen && !this.errorMessage) {
        return "240px";
      }
      if (this.dropdownOpen && this.errorMessage) {
        return "-9px";
      }

      let marginTop = "1.5rem";
      this.selectedSubjects.forEach((subject, index) => {
        if (this.categoryDropdowns[index]) {
          if (index === 0) marginTop = "10px";
          else if (index === 1) marginTop = "6px";
          else if (index === 2) marginTop = "60px";
        }
      });
      return marginTop;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick);
    window.addEventListener("popstate", this.handleBackNavigation);
    this.loadState();
    if (!this.isNextStep) {
      this.fetchSubjects();
    }
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  },
  methods: {
    handleBackNavigation() {
      if (this.isNextStep && !this.backButtonPressed) {
        // Reset to subject selection on first back button press
        this.isNextStep = false;
        this.backButtonPressed = true;

        // Restore selected subjects
        this.selectedSubjects = JSON.parse(
          localStorage.getItem("selectedSubjects") || "[]"
        );
        this.selectedSubjectIds = JSON.parse(
          localStorage.getItem("selectedSubjectIds") || "[]"
        );

        // Clear error and save updated state
        this.errorMessage = "";
        this.saveState();
      } else if (this.backButtonPressed) {
        // Prevent further back navigation to other steps
        history.pushState(null, null, document.URL);
      }
    },
    handlePopState(event) {
      if (event.state?.step === "categories") {
        // If we are in the categories step and back is pressed, revert to subject selection
        this.isNextStep = false;
        this.errorMessage = "";
      }
    },
    async fetchSubjects() {
      const email = localStorage.getItem("email");
      const dataToSend = { user_email: email };
      try {
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env
              .VUE_APP_FLUTE_N_FEATHER_LIST_PARENT_SUBJECTS_DETAILS_URL_PATH,
          dataToSend
        );
        if (response && response.data) {
          this.subjects = response.data.subjects.map((subject) => ({
            id: subject.subject_id,
            name: subject.subject_name,
          }));
          this.subjectMap = Object.fromEntries(
            this.subjects.map(({ id, name }) => [id, name])
          );
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            text: response?.data?.message || "Subjects not found.",
          });
        }
      } catch (error) {
        console.error("Error fetching subjects:", error);
        this.$notify({
          group: "foo",
          type: "error",
          text: "Something went wrong. Please try again",
        });
      }
    },
    toggleDropdown() {
      this.errorMessage = "";
      this.dropdownOpen = !this.dropdownOpen;
    },
    toggleCategoryDropdown(index) {
      if (!this.isDropdownDisabled(index)) {
        this.errorMessage = "";

        // Ensure categoryDropdowns is an array
        if (!Array.isArray(this.categoryDropdowns)) {
          this.categoryDropdowns = [];
        }

        // Toggle and close others
        this.categoryDropdowns = this.categoryDropdowns.map((value, i) =>
          i === index ? !value : false
        );

        // Handle undefined indexes
        if (this.categoryDropdowns[index] === undefined) {
          this.$set(this.categoryDropdowns, index, true);
        }
      }
    },
    selectSubject(subject) {
      const { id, name } = subject;
      if (this.selectedSubjectIds.includes(id)) {
        // Remove subject from the list
        this.selectedSubjects = this.selectedSubjects.filter((s) => s !== name);
        this.selectedSubjectIds = this.selectedSubjectIds.filter(
          (sid) => sid !== id
        );
        this.$delete(this.selectedCategories, name);
      } else if (this.selectedSubjects.length < 3) {
        // Add subject to the list
        this.selectedSubjects.push(name);
        this.selectedSubjectIds.push(id);
        this.$set(this.selectedCategories, name, []);
      } else {
        this.errorMessage = "You can only select up to 3 subjects.";
        return;
      }
      this.errorMessage = "";
      this.saveState(); // Persist the state
    },

    selectCategory(subject, category) {
      const subjectName = subject;
      if (!this.selectedCategories[subjectName]) {
        this.$set(this.selectedCategories, subjectName, []);
      }
      const categoryIds = this.selectedCategories[subjectName];
      if (!categoryIds.includes(category.id)) {
        if (categoryIds.length < 3) {
          categoryIds.push(category.id);
        }
      } else {
        this.selectedCategories[subjectName] = categoryIds.filter(
          (c) => c !== category.id
        );
      }
      this.saveState();
    },
    async handleNext() {
      this.dropdownOpen = true;

      if (!this.isNextStep) {
        // First step: Select subjects and fetch categories
        if (this.selectedSubjects.length === 3) {
          try {
            this.errorMessage = "";
            const dataToSend = {
              user_email: localStorage.getItem("email"),
              subject_ids: this.selectedSubjectIds,
            };
            const response = await axios.post(
              process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
                process.env
                  .VUE_APP_FLUTE_N_FEATHER_SUBJECT_CATEGORIES_DETAILS_URL_PATH,
              dataToSend
            );
            if (response && response.data) {
              this.isNextStep = true;
              this.backButtonPressed = false; // Reset back button state
              response.data.subjects.forEach((subject) => {
                const subjectName = this.subjectMap[subject.subject_id];
                this.$set(
                  this.categories,
                  subjectName,
                  subject.categories.map((c) => ({
                    id: c.category_id,
                    name: c.category_name,
                  }))
                );
              });
              this.saveState();
            } else {
              this.errorMessage =
                response?.data?.message || "Categories not found.";
            }
          } catch (error) {
            console.error("Error fetching categories:", error);
            this.errorMessage =
              error.response?.data?.message ||
              "Something went wrong. Please try again.";
          }
        } else {
          this.errorMessage = "Please select 3 subjects to move forward.";
        }
      } else {
        const missingCategories = this.selectedSubjects.filter(
          (subject) =>
            !this.selectedCategories[subject] ||
            this.selectedCategories[subject].length === 0
        );
        // Final step: Submit the selected subjects and categories
        if (missingCategories.length === 0) {
          this.errorMessage = "";
          this.saveState(); // Save the state to localStorage
          this.isFinished = true; // Mark setup as finished
        } else {
          this.errorMessage =
            "Please select a minimum of 1 and maximum of 3 categories";
        }
      }
    },
    async homeRedirect() {
      try {
        // Fetch stored data from localStorage
        const subjectsPayload = this.selectedSubjects.map((subject) => {
          const subjectId = Object.keys(this.subjectMap).find(
            (key) => this.subjectMap[key] === subject
          );
          return {
            subject_id: subjectId,
            category_ids: this.selectedCategories[subject],
          };
        });

        const finalPayload = {
          user_email: localStorage.getItem("email"),
          subjects: subjectsPayload,
        };

        // Call the final API
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_PARENT_SELECTION_UPDATE,
          finalPayload
        );

        if (response && response.data) {
          this.clearState(); // Clear state on success
          this.$router.push("/home"); // Redirect to home
        } else {
          this.errorMessage =
            response?.data?.message || "Final submission failed.";
        }
      } catch (error) {
        console.error("Error during final submission:", error);
        this.errorMessage =
          error.response?.data?.message ||
          "Something went wrong. Please try again.";
      }
    },
    saveState() {
      localStorage.setItem(
        "selectedSubjects",
        JSON.stringify(this.selectedSubjects)
      );
      localStorage.setItem(
        "selectedSubjectIds",
        JSON.stringify(this.selectedSubjectIds)
      );
      localStorage.setItem(
        "selectedCategories",
        JSON.stringify(this.selectedCategories)
      );
      localStorage.setItem("categories", JSON.stringify(this.categories));
      localStorage.setItem("isNextStep", JSON.stringify(this.isNextStep));
    },
    loadState() {
      const storedSubjects = localStorage.getItem("selectedSubjects");
      const storedSubjectIds = localStorage.getItem("selectedSubjectIds");
      const storedCategories = localStorage.getItem("selectedCategories");
      const storedCategoriesList = localStorage.getItem("categories");

      if (storedSubjects) {
        this.selectedSubjects = JSON.parse(storedSubjects);
      }
      if (storedSubjectIds) {
        this.selectedSubjectIds = JSON.parse(storedSubjectIds);
      }
      if (storedCategories) {
        this.selectedCategories = JSON.parse(storedCategories);
      }
      if (storedCategoriesList) {
        this.categories = JSON.parse(storedCategoriesList);
      }
    },
    isDropdownDisabled(index) {
      // If it's the first dropdown, it's always enabled
      if (index === 0) return false;

      // Check if the previous dropdown has at least one selected category
      const previousSubject = this.selectedSubjects[index - 1];
      return (
        !this.selectedCategories[previousSubject] ||
        this.selectedCategories[previousSubject].length === 0
      );
    },
    clearState() {
      localStorage.removeItem("selectedSubjects");
      localStorage.removeItem("selectedSubjectIds");
      localStorage.removeItem("selectedCategories");
      localStorage.removeItem("categories");
      localStorage.removeItem("isNextStep");
    },
    handleOutsideClick(event) {
      const mainDropdown = this.$refs.dropdown;
      const nextButton = this.$refs.nextButton;

      if (
        mainDropdown &&
        !mainDropdown.contains(event.target) &&
        nextButton &&
        !nextButton.contains(event.target)
      ) {
        this.dropdownOpen = false;
        this.errorMessage = "";
      }
      Object.keys(this.categoryDropdowns).forEach((_, index) => {
        const subject = this.selectedSubjects[index]; // Get the corresponding subject
        const dropdownRefName = `categoryDropdown-${subject}`;
        const dropdownElement = this.$refs[dropdownRefName];

        // Check if it's an array
        const element = Array.isArray(dropdownElement)
          ? dropdownElement[0]
          : dropdownElement;

        if (
          element &&
          typeof element.contains === "function" &&
          !element.contains(event.target)
        ) {
          this.$set(this.categoryDropdowns, index, false);
        }
      });
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
    window.removeEventListener("popstate", this.handleBackNavigation);
  },
};
</script>
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
}

/* Logo Styling */
.logo-container {
  margin-bottom: auto;
}
.fnf-logo {
  width: 250px;
  height: 60px;
  margin-bottom: 1rem;
}
.move-left {
  margin-left: -55px; /* Adjust the value to your preference */
}
/* Welcome Title Styling */
.welcome-title {
  font-size: 1.5rem;
  width: 295px;
  font-family: Nunito;
  margin-left: 70px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32.74px;
  margin-top: -30px;
}

/* Subtitle Styling */
/* Default for larger screens */
.subtitle {
  width: 343px;
  margin-left: 30px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.82px;
  text-align: center;
}

/* Adjust margin-left on medium screens */
@media (max-width: 768px) {
  .subtitle {
    margin-left: 12px; /* adjust as needed for tablets */
    width: 385px; /* adjust width if needed */
  }
}

/* Adjust margin-left on small screens */
@media (max-width: 576px) {
  .subtitle {
    margin-left: -19px; /* suitable for smaller phones */
    width: 103%; /* make the text centered */
  }
}

/* When isNextStep is true */
@media (max-width: 768px) {
  .subtitle[data-next-step="true"] {
    margin-left: 49px;
    width: 290px;
  }
}

@media (max-width: 576px) {
  .subtitle[data-next-step="true"] {
    margin-left: -16px;
    width: 103%; /* keep text within screen on smallest sizes */
  }
}

/* Label Styling */
.label {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.sub-content {
  width: 400px;
  max-width: 900px;
}

/* Custom Dropdown Styling */
.custom-dropdown {
  position: relative;
}
/* Base Styles */
.dropdown-toggle {
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 1rem;
  background: #f2f3ff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #5e6366;
  transition: border-radius 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
}

/* Large Screens (Desktops, width > 1024px) */
@media (min-width: 1024px) {
  .dropdown-toggle {
    width: 400px; /* Fixed width for larger screens */
    height: 48px;
    font-size: 1rem;
    padding: 8px 16px;
  }
}

/* Medium Screens (Tablets, width between 768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown-toggle {
    width: 295px; /* Slightly smaller for tablets */
    height: 46px; /* Slightly reduced height */
    font-size: 0.95rem; /* Adjust font size */
    padding: 7px 14px;
    left: 68px;
  }
}
/* Small Screens (Phones, width between 576px - 768px) */
@media (min-width: 576px) and (max-width: 768px) {
  .dropdown-toggle {
    width: 250px;
    height: 44px;
    left: 25%;
    font-size: 0.9rem;
    padding: 6px 12px;
  }
}

/* Extra Small Screens (Width between 480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .dropdown-toggle {
    width: 250px; /* Adjust width for this range */
    height: 42px; /* Reduce height slightly */
    font-size: 0.9rem; /* Smaller font size for smaller screens */
    padding: 5px 10px; /* Adjust padding for better fit */
  }
}

/* Extra Small Screens (Phones, width less than 480px) */
@media (max-width: 480px) {
  .dropdown-toggle {
    width: 240px; /* Narrower width for extra small devices */
    height: 40px;
    font-size: 0.85rem;
    padding: 5px 10px;
  }
}

/* Very Small Screens (Height less than 300px) */
@media (max-height: 300px) {
  .dropdown-toggle {
    width: 272px;
    height: 45px;
    font-size: 0.8rem;
    padding: 4px 8px;
  }
}

.dropdown-toggle.dropdown-open {
  border-radius: 16px 16px 0 0;
}

/* Fixed position for the dropdown icon */
.dropdown-toggle .dropdown-icon {
  font-size: 1rem;
  color: #333;
  position: absolute;
  right: 16px; /* Keeps the icon in the corner */
}
.dropdown-icon {
  display: inline-block;
  width: 7px;
  height: 7px;
  border: solid #333;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: transform 0.3s;
}
.dropdown-toggle::after {
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
}

.icon-up {
  transform: rotate(-135deg); /* Up arrow */
}

.icon-down {
  transform: rotate(45deg); /* Down arrow */
}
/* Base Styles */
.dropdown-menu {
  position: absolute;
  top: 96%; /* Position dropdown below the trigger */
  left: 0;
  width: 100%; /* Default width */
  background-color: #f2f3ff;
  border: 1px solid #ddd;
  border-radius: 0 0 16px 16px;
  color: #5e6366;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  display: block;
  max-height: 200px; /* Default height */
  overflow-y: auto; /* Scroll for overflow */
  z-index: 100;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth scaling */
}

/* Large Screens (Desktops, width between 1024px - 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
  .dropdown-menu {
    width: 100%;
    max-height: 210px;
    left: 0%; /* Centered */
    padding: 6px 0;
    border-radius: 0 0 14px 14px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .dropdown-menu {
    width: 73%;
    max-height: 214px;
    left: 17.5%;
    padding: 6px 0;
    font-size: 14px;
    border-radius: 0 0 12px 12px;
  }
}

/* Medium Screens (Tablets, width between 768px - 1024px) */
/* Specific rule for exactly 768px */
@media (min-width: 768px) and (max-width: 768px) {
  .dropdown-menu {
    width: 97%;
    left: 25.5%; /* Centered */
    border-radius: 0 0 10px 10px;
  }
}

/* Medium Screens (Tablets, width between 768px - 991px) */
@media (min-width: 769px) and (max-width: 991px) {
  .dropdown-menu {
    width: 113%;
    max-height: 209px;
    left: 26.5%;
    padding: 6px 0;
    font-size: 14px;
    border-radius: 0 0 12px 12px;
  }
}

/* Small Screens (Phones, width between 576px - 768px) */
@media (min-width: 577px) and (max-width: 767px) {
  .dropdown-menu {
    width: 76%;
    max-height: 213px;
    left: 25.5%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: 576px) {
  .dropdown-menu {
    width: 92%;
    max-height: 140px;
    left: 25.1%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

/* Extra Small Screens (Width between 480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .dropdown-menu {
    width: 110%;
    max-height: 205px;
    left: 0.8%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

/* Extra Small Screens (Phones, width less than 480px) */
@media (max-width: 480px) {
  .dropdown-menu {
    width: 111%;
    max-height: 203px;
    left: 0%; /* Centered */
    padding: 4px 0;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
  }
}

/* Ultra-Wide or Small Devices (min-height: 300px and max-height: 600px) */
@media (min-height: 300px) and (max-height: 600px) {
  .dropdown-menu {
    max-height: 211px;
    font-size: 12px;
    padding: 4px 0;
  }
}

/* Very Short Screens (Height less than 300px) */
@media (max-height: 300px) {
  .dropdown-menu {
    max-height: 200px;
    font-size: 11px;
    padding: 3px 0;
  }
}

/* Ultra-Small Heights (Height less than 200px) */
@media (max-height: 200px) {
  .dropdown-menu {
    max-height: 200px; /* Minimal height */
    font-size: 10px;
    padding: 2px 0;
  }
}

.dropdown-menu-category {
  position: absolute;
  top: 96%; /* Position dropdown below the trigger */
  left: 0;
  width: 100%; /* Default width */
  background-color: #f2f3ff;
  border: 1px solid #ddd;
  border-radius: 0 0 16px 16px;
  color: #5e6366;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  display: block;
  max-height: 200px; /* Default height */
  overflow-y: auto; /* Scroll for overflow */
  z-index: 100;
  box-sizing: border-box;
  transition: all 0.3s ease; /* Smooth scaling */
}

/* Large Screens (Desktops, width between 1024px - 1366px) */
@media (min-width: 1024px) and (max-width: 1366px) {
  .dropdown-menu-category {
    width: 100%;
    max-height: 210px;
    left: 0%; /* Centered */
    padding: 6px 0;
    border-radius: 0 0 14px 14px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .dropdown-menu-category {
    width: 73%;
    max-height: 214px;
    left: 17.5%;
    padding: 6px 0;
    font-size: 14px;
    border-radius: 0 0 12px 12px;
  }
}

/* Medium Screens (Tablets, width between 768px - 1024px) */
/* Specific rule for exactly 768px */
@media (min-width: 768px) and (max-width: 768px) {
  .dropdown-menu-category {
    width: 97%;
    left: 25.5%; /* Centered */
    border-radius: 0 0 10px 10px;
  }
}

/* Medium Screens (Tablets, width between 768px - 991px) */
@media (min-width: 769px) and (max-width: 991px) {
  .dropdown-menu-category {
    width: 113%;
    max-height: 209px;
    left: 26.5%;
    padding: 6px 0;
    font-size: 14px;
    border-radius: 0 0 12px 12px;
  }
}

/* Small Screens (Phones, width between 576px - 768px) */
@media (min-width: 577px) and (max-width: 767px) {
  .dropdown-menu-category {
    width: 77%;
    max-height: 213px;
    left: 25%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: 576px) {
  .dropdown-menu-category {
    width: 77%;
    max-height: 140px;
    left: 25.1%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

/* Extra Small Screens (Width between 480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .dropdown-menu-category {
    width: 109%;
    max-height: 205px;
    left: 0.4%; /* Centered */
    padding: 5px 0;
    font-size: 13px;
    border-radius: 0 0 10px 10px;
  }
}

/* Extra Small Screens (Phones, width less than 480px) */
@media (max-width: 480px) {
  .dropdown-menu-category {
    width: 105%;
    max-height: 219px;
    left: 0%; /* Centered */
    padding: 4px 0;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
  }
}
@media (max-width: 270px) {
  .dropdown-menu-category {
    width: 106%;
    max-height: 219px;
    left: 0%; /* Centered */
    padding: 4px 0;
    font-size: 12px;
    border-radius: 0 0 8px 8px;
  }
}

/* Ultra-Wide or Small Devices (min-height: 300px and max-height: 600px) */
@media (min-height: 300px) and (max-height: 600px) {
  .dropdown-menu-category {
    max-height: 211px;
    font-size: 12px;
    padding: 4px 0;
  }
}

/* Very Short Screens (Height less than 300px) */
@media (max-height: 300px) {
  .dropdown-menu-category {
    max-height: 200px;
    font-size: 11px;
    padding: 3px 0;
  }
}

/* Ultra-Small Heights (Height less than 200px) */
@media (max-height: 200px) {
  .dropdown-menu-category {
    max-height: 200px; /* Minimal height */
    font-size: 10px;
    padding: 2px 0;
  }
}

.dropdown-item {
  color: #5e6366;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  font-family: Nunito;
  transition: background-color 0.2s;
}

.dropdown-item .radio-button {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: transparent; /* Disable hover color */
}

/* Base Styles for .peacock-container */
.peacock-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(
    --linear-gradient---onbaord,
    linear-gradient(0deg, #d8dbf7 0.71%, #d8dbf7 45.6%, #e6f3ff 99.97%)
  );
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 86%;
  height: 401px;
  margin-left: 48px;
    padding: 0;
  margin: 0;
  line-height: normal; /* Ensure no inherited line-height */
}

/* Ultra-Wide Screens (above 1920px) */
@media (min-width: 1921px) {
  .peacock-container {
       width: 94%;
        height: 610px;
        margin-left: 20px;
        margin-top: 48px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1601px) and (max-width: 1920px) {
  .peacock-container {
        width: 84%;
        height: 549px;
        margin-left: 100px;
        margin-top: 59px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1441px) and (max-width: 1600px) {
  .peacock-container {
        width: 84%;
        height: 549px;
        margin-left: 85px;
        margin-top: 59px;
  }
}

/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .peacock-container {
    width: 85%;
    height: 400px;
    margin-left: 60px;
    margin-top: 8px;
  }
}
/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .peacock-container {
    width: 85%;
    height: 400px;
    margin-left: 40px;
    margin-top: 8px;
  }
}

/* Large Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .peacock-container {
    width: 94%;
    height: 300px;
    margin-left: -10px;
    margin-top: 10px;
  }
}

/* Medium Tablets (576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .peacock-container {
    width: 92%;
    height: 280px;
    margin-left: -10px;
    margin-top: -20px;
  }
}

/* Large Mobiles (480px to 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .peacock-container {
    width: 92%;
    height: 250px;
    margin-left: -15px;
    margin-top: -30px;
  }
}

/* Small Mobiles (less than 480px) */
@media (max-width: 480px) {
  .peacock-container {
    width: 100%;
    height: 240px;
    margin-left: -35px;
    margin-top: -40px;
  }
}

/* Ultra-Small Devices (height less than 300px) */
@media (max-height: 300px) {
  .peacock-container {
    width: 80%;
    height: 200px;
    margin-left: 20px;
    margin-top: -20px;
  }
}


.get-started-button {
  display: block; /* Ensures the button spans the full width */
  width: 100%;
  max-width: 279px;
  padding: 0.55rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 40px;
  background: linear-gradient(88deg, #8142ef -3.68%, #b566f2 103.37%);
  color: white;
  cursor: pointer;
  text-align: center;
  transition: margin-top 0.3s ease, padding 0.3s ease;
  box-shadow: 0px 6px 23px 0px rgba(122, 57, 235, 0.36);
  position: relative; /* Keeps the button in its container */
  margin-top: 257px; /* Default desktop style */
  margin-left: -278px;
  border: none;
}

/* For larger desktops (above 1440px) */
@media (min-width: 1441px) {
  .get-started-button {
        width: 110%;
        margin-top: 267px;
        margin-left: -378px;
        font-size: 1.1rem;
        padding: 0.6rem;
  }
}

/* For standard desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .get-started-button {
    width: 110%;
    margin-top: 257px;
    margin-left: -278px;
  }
}

/* For tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .get-started-button {
        width: 110%;
        margin-left: 93px;
        margin-top: -1rem;
        font-size: 0.95rem;
        padding: 0.5rem;
  }
}

/* For large mobiles (481px to 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .get-started-button {
        width: 120%;
        margin-left: 76px;
        margin-top: -2rem;
        font-size: 0.9rem;
        padding: 0.45rem;
  }
}

/* For small mobiles (up to 480px) */
@media (max-width: 480px) {
  .get-started-button {
    width: 110%;
    margin-left: 62px;
    margin-top: -3rem;
    font-size: 0.85rem;
    padding: 0.4rem;
  }
}

/* For ultra-wide desktops (above 1920px) */
@media (min-width: 1921px) {
  .get-started-button {
    width: 110%;
    margin-top: 280px;
    margin-left: -355px;
    font-size: 1.2rem;
    padding: 0.65rem;
  }
}

/* For extra-large tablets (e.g., 1200px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .get-started-button {
        width: 109%;
        margin-left: -344px;
        margin-top: 246px;
        font-size: 1rem;
        padding: 0.55rem;
  }
}

/* .peacock-content */
.peacock-content {
  display: flex;
  gap: 20px;
  margin-left: -103px;
}

.peacock-content {
  display: flex;
  gap: 20px;
  margin-left: -103px;
}

/* Responsive for tablets (portrait and smaller laptops) */
@media (max-width: 1024px) {
  .peacock-content {
    flex-direction: column; /* Stack elements */
    margin-top: -66px;
    margin-left: -188px;
  }
}

/* Responsive for large mobile devices and small tablets */
@media (min-width: 576px) and (max-width: 768px) {
  .peacock-content {
    flex-direction: column;
    margin-top: -40px;
    margin-left: -212px;
  }
}

/* Responsive for medium mobile devices */
@media (min-width: 480px) and (max-width: 575px) {
  .peacock-content {
    flex-direction: column;
    margin-top: -50px;
    margin-left: -202px;
  }
}

/* Responsive for small mobile devices */
@media (max-width: 479px) {
  .peacock-content {
    flex-direction: column;
    margin-top: -30px;
    margin-left: -120px;
  }
}

/* Ultra-wide screens (larger desktops) */
@media (min-width: 1440px) {
  .peacock-content {
    flex-direction: row; /* Default row layout */
        margin-top: -42px;
        margin-left: -170px;
    gap: 40px; /* Increase gap for wider screens */
  }
}

/* .peacock-image */
.peacock-image {
  width: 231px;
  height: 187px;
  margin-top: 40px;
}

/* Responsive for .peacock-image */
@media (max-width: 1024px) {
  .peacock-image {
    width: 200px; /* Reduce image size */
    height: 170px; /* Adjust height */
  }
}

@media (max-width: 768px) {
  .peacock-image {
    width: 180px; /* Reduce further for small screens */
    height: 150px; /* Adjust height */
  }
}

@media (max-width: 480px) {
  .peacock-image {
    width: 150px; /* Reduce image size for ultra-small screens */
    height: 120px; /* Adjust height */
  }
}

/* .peacock-svg-wrapper */
.peacock-svg-wrapper {
  max-width: 305px;
  width: 100%;
  position: relative;
}

/* Responsive for .peacock-svg-wrapper */
@media (max-width: 1024px) {
  .peacock-svg-wrapper {
    max-width: 250px; /* Reduce max width for medium screens */
  }
}

@media (max-width: 768px) {
  .peacock-svg-wrapper {
    max-width: 200px; /* Further reduce max width */
  }
}

@media (max-width: 480px) {
  .peacock-svg-wrapper {
    max-width: 180px; /* Reduce width for small devices */
  }
}

/* .peacock-text */
.peacock-text {
color: #323232;
font-family: Arial;
font-size: 35px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 120% */
width: 471px;
}



/* Ultra-Wide Screens (Above 1920px) */
@media (min-width: 1921px) {
  .peacock-text {
        font-size: 20px;
        line-height: 24px;
        width: 260px;
        top: 46%;
        margin-left: -36px;
        margin-top: -133px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1441px) and (max-width: 1920px) {
  .peacock-text {
        font-size: 18px;
        line-height: 20px;
        width: 236px;
        margin-left: -43px;
        margin-top: -113px;
        top: 51%;
  }
}

/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .peacock-text {
        font-size: 18px;
        line-height: 21px;
        width: 225px;
        margin-left: -35px;
        margin-top: -118px;
        top: 50%;
  }
}

/* Large Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .peacock-text {
        font-size: 16px;
        line-height: 19px;
        width: 200px;
        top: 49%;
        margin-top: -152px;
        margin-left: 186px;
  }
}

/* Medium Tablets (576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .peacock-text {
        font-size: 15px;
        line-height: 16px;
        width: 208px;
        top: 48%;
        margin-top: -141px;
        margin-left: 169px;
  }
}

/* Large Mobiles (480px to 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .peacock-text {
        font-size: 15px;
        line-height: 16px;
        width: 234px;
        top: 47%;
        margin-top: -142px;
        margin-left: 166px;
  }
}

/* Small Mobiles (Less than 480px) */
@media (max-width: 480px) {
  .peacock-text {
      font-size: 12px;
        line-height: 13px;
        width: 150px;
        top: 46%;
        margin-top: -123px;
        margin-left: 132px;
  }
}

/* Ultra-Small Devices (Height Less than 300px) */
@media (max-height: 300px) {
  .peacock-text {
        width: 109%;
        height: 200px;
        margin-left: -39px;
        margin-top: -20px;
  }
}

/* .peacock-svg */
.peacock-svg {
  width: 160%;
  height: auto;
  margin-left: -109px;
  margin-top: 82px;
}

/* Responsive for .peacock-svg */
@media (max-width: 1024px) {
  .peacock-svg {
    width: 120%;
    margin-left: 113px;
    margin-top: -204px;
  }
}

@media (max-width: 768px) {
  .peacock-svg {
    width: 146%;
    margin-left: 102px;
    margin-top: -190px;
  }
}

@media (max-width: 480px) {
  .peacock-svg {
    width: 111%;
    margin-left: 84px;
    margin-top: -168px;
  }
}

/* Next Button Styling */
/* Base Styles */
.next-button {
  width: 100%; /* Default full width */
  max-width: 400px; /* Maximum width for larger screens */
  padding: 0.75rem; /* Base padding */
  font-size: 1rem; /* Base font size */
  font-weight: bold;
  border-radius: 40px;
  background: #5e6366;
  color: white;
  cursor: pointer;
  transition: margin-top 0.3s ease, padding 0.3s ease;
  margin-top: 0.5rem;
  box-shadow: 0px 6px 23px 0px rgba(122, 57, 235, 0.36);
}

/* Large Screens (Desktops, width > 1024px) */
@media (min-width: 1024px) {
  .next-button {
    max-width: 400px; /* Maintain larger width */
    font-size: 1.1rem; /* Slightly larger font size */
    padding: 0.9rem; /* Increased padding */
    margin-top: 0.6rem; /* Slightly larger top margin */
  }
}

/* Specific rule for exactly 768px */
@media (min-width: 768px) and (max-width: 768px) {
  .next-button {
    max-width: 267px;
    font-size: 1rem;
    padding: 0.85rem;
    margin-left: 18%;
    height: 48px;
  }
}

/* Medium Screens (Tablets, width between 768px - 991px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .next-button {
    max-width: 291px;
    font-size: 1rem;
    padding: 0.85rem;
    margin-left: 18%;
    height: 48px;
  }
}

/* Small Screens (Phones, width between 576px - 768px) */
@media (min-width: 576px) and (max-width: 767px) {
  .next-button {
    max-width: 250px;
    font-size: 0.95rem;
    padding: 0.8rem;
    margin-left: 22%;
    height: 45px;
  }
}

/* Extra Small Screens (Width between 480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .next-button {
    max-width: 300px; /* Reduce width for smaller screens */
    font-size: 0.9rem; /* Decrease font size */
    padding: 0.7rem; /* Further reduce padding */
    margin-top: 0.45rem; /* Reduce top margin */
  }
}

/* Very Small Screens (Width less than 480px) */
@media (max-width: 480px) {
  .next-button {
    max-width: 240px; /* Narrow button for very small screens */
    font-size: 0.85rem; /* Smaller font */
    padding: 0.65rem; /* Compact padding */
    margin-top: 0.4rem; /* Smaller top margin */
  }
}

/* Ultra-Small Screens (Height less than 300px) */
@media (max-height: 300px) {
  .next-button {
    max-width: 271px; /* Further narrow button */
    font-size: 0.8rem; /* Compact font */
    padding: 0.6rem; /* Minimal padding */
    margin-top: 0.35rem; /* Minimal top margin */
  }
}

.next-button:hover {
  background: #3e3e4e;
}

.error-message {
  font-family: Nunito;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #e94235;
  transition: margin-top 0.3s ease; /* Smooth transition */
}

/* Dropdown Open */
.error-message.dropdown-open {
  margin-top: 235px; /* Margin for dropdown open */
}

/* Dropdown Closed */
.error-message.dropdown-closed {
  margin-top: 21px; /* Margin for dropdown closed */
}

/* Responsive Adjustments */
@media (min-width: 1024px) {
  .error-message {
    font-size: 1rem;
    line-height: 24px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .error-message {
    font-size: 14px;
    line-height: 20px;
    margin-right: -30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .error-message {
    font-size: 14px;
    line-height: 20px;
    margin-right: -170px;
  }
}

@media (max-width: 768px) {
  .error-message {
    font-size: 0.85rem;
    line-height: 20px;
    margin-right: -106px;
  }
}

@media (max-width: 576px) {
  .error-message {
    font-size: 0.85rem;
    line-height: 20px;
    margin-left: 1px;
    width: 104%;
  }
}

/* Small devices (phones, < 576px) */
@media (max-width: 575.98px) {
  .logo-container {
    margin-bottom: 1rem;
  }

  .fnf-logo {
    width: 180px;
    height: 40px;
    margin-left: -69px;
  }

  .welcome-title {
    width: auto;
    margin-left: 0;
    font-size: 20px;
    margin-top: 0px;
  }

  .subtitle {
    width: auto;
    margin-left: -8px;
    font-size: 14px;
    text-align: center;
  }

  .sub-content {
    width: 90%;
  }

  .label {
    width: 79%;
    font-size: 12px;
  }
}

/* Medium devices (tablets, 576px - 768px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .logo-container {
    margin-bottom: 2.5rem;
  }

  .fnf-logo {
    width: 161px;
    height: 41px;
    margin-left: -70px;
  }

  .welcome-title {
    width: auto;
    margin-left: 70px;
    margin-top: -51px;
    font-size: 22px;
  }

  .subtitle {
    font-size: 15px;
    margin-left: 0;
  }
  .label {
    margin-left: 84px;
    font-size: 14px;
    width: 65%;
  }

  .sub-content {
    width: 85%;
  }
}

/* Large devices (desktops, laptops, >= 768px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .logo-container {
    margin-bottom: 0rem;
  }

  .fnf-logo {
    width: 200px;
    height: 44px;
    margin-left: -67px;
  }

  .welcome-title {
    width: auto;
    margin-left: 67px;
    margin-top: 0px;
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }
  .label {
    margin-left: 72px;
    font-size: 14px;
  }

  .sub-content {
    width: 70%;
  }
}

/* Extra Large devices (desktops, > 992px) */
@media (min-width: 992px) {
  .logo-container {
    margin-bottom: 0rem;
  }

  .fnf-logo {
    width: 238px;
    height: 53px;
    margin-left: -49px;
  }

  .welcome-title {
    width: auto;
    margin-left: 66px;
    margin-top: 0px;
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .sub-content {
    width: 400px;
  }
}
</style>
