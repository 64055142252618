<template>
  <div class="container-fluid p-0 container-min-height">
    <div class="row m-0 pt-2per">
      <div class="col-12 d-flex">
        <div
          class="backButton text-primary fw-700 cursor-pointer"
          @click="topicBack"
        >
          <img class="leftArrow" src="../assets/arrow_left_alt.png" />
          <span class="pl-1">BACK</span>
        </div>
      </div>
    </div>
    <div class="row mx-0" v-if="!isLoading">
      <div class="col-12 col-sm-12 col-md-12 col-xl-12 col-padding p-0">
        <div class="row m-0 pb-2 mt-5">
          <div class="col-12 p-0 pb-3 d-flex justify-content-center">
            <span class="speakerText text-center">{{
              `What would you like to learn today?`
            }}</span>
          </div>
        </div>
        <div class="row m-0 d-flex justify-content-center">
          <div
            v-for="topic in flattenTopics"
            :key="topic.topic_name"
            class="topic-tiles"
          >
            <div
              class="card card-radius cursor-pointer"
              :style="{ backgroundColor: topic.base_color || '#ffffff' }"
              @click="selectTopic(topic)"
            >
              <img
                class="card-img-top"
                :src="topic.thumbnail_image || defaultImage"
                alt="Card image"
                style="width: 100%"
              />
              <div class="card-body p-10">
                <h4
                  class="card-title font-size-18 text-center m-0 text-capitalize"
                >
                  {{ topic.topic_name }}
                </h4>
              </div>
              <div class="progress h-5">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{
                    width: progressBarWidth(topic.status),
                    backgroundColor: progressBarColor(topic.status),
                  }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <span class="sr-only">{{
                    progressBarWidth(topic.status)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import { updateTitle } from "@/utils/utils";
import NoSleep from "@uriopass/nosleep.js";

export default {
  name: "TopicComponent",
  components: { Loading },
  data() {
    return {
      subjectName: localStorage.getItem("subjectName"),
      topicArr: [],
      flattenTopics: [], // This will hold the flattened list of topics
      defaultImage: require("@/assets/Rectangle 12.png"),
      isLoading: false,
      noSleep: null,
    };
  },

  async mounted() {
    updateTitle("Topics");
    this.noSleep = new NoSleep(); // Initialize NoSleep
    this.fetchTopic();
  },
  methods: {
    topicBack() {
      this.$router.push("/dashboard");
      if (this.noSleep) {
        this.noSleep.disable();
      }
    },
    progressBarWidth(status) {
      if (status === "Not Completed") {
        return "0%";
      } else if (status === "In Progress") {
        return "50%";
      } else if (status === "completed") {
        return "100%";
      } else {
        // Default value if status is not recognized
        return "0%";
      }
    },
    progressBarColor(status) {
      if (status === "In Progress") {
        return "#3CADEF";
      } else if (status === "completed") {
        return "#00CE64";
      } else {
        // Default color if status is not recognized
        return "#F2F2F2"; // Change this to the default color you want
      }
    },
    selectTopic(topic) {
      // Find the subject_name associated with the selected topic
      const selectedSubject = this.topicArr.find((subject) =>
        subject.list_topics.some((t) => t.topic_name === topic.topic_name)
      );

      // Save topic details to localStorage
      localStorage.setItem("topicDetails", JSON.stringify(topic));
      localStorage.setItem("topicName", topic.topic_name);
      localStorage.setItem("videoTitle", topic.video_title);
      localStorage.setItem("categoryName", topic.category_name);
      const topicColor = (topic.base_color && topic.base_color.trim() !== "") ? topic.base_color : "#7DD9CC";
      localStorage.setItem("topicColor", topicColor);

      // Save the associated subject_name to localStorage
      if (selectedSubject) {
        localStorage.setItem("subjectName", selectedSubject.subject_name);
      }

      // Enable NoSleep (to keep the screen awake)
      if (this.noSleep) {
        this.noSleep.enable();
      }
      // navigate to a route with the topic name as a parameter
      this.$router.push({
        name: "TopicDetailsComponentNew",
        query: { topic: topic.topic_name },
      });
    },
    fetchTopic() {
      this.isLoading = true;
      let dataTosend = {};
      (dataTosend.user_email = localStorage.getItem("email")),
        // (dataTosend.subject_name = this.subjectName),
        axios
          .post(
            process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
              process.env.VUE_APP_FLUTE_N_FEATHER_TOPIC_URL_PATH,
            dataTosend
          )
          .then((response) => {
            console.log("responce", response.data);
            this.isLoading = false;
            this.topicArr = response.data.response;
            this.flattenTopics = this.topicArr.reduce((acc, subject) => {
              return acc.concat(subject.list_topics);
            }, []);
          })
          .catch((error) => {
            this.isLoading = false;
            console.error("Error fetching subject", error);
          });
    },
  },
};
</script>
<style lang="scss" scoped>
.speakerText {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media all and (max-width: 600px) {
    font-size: 20px;
  }
}
.card-radius {
  border-radius: 10px;
  border: 2px solid #e2e2e2;
  color: black;
}
.card-img-top {
  height: 100px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.topic-tiles {
  min-height: 170px;
  width: 155px;
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: 350px) {
    width: 145px;
  }
}
</style>