<template>
  <div>
    <notifications group="login" :duration="5000" width="100%" />
    <div class="row mx-0 login-container container-min-height">
      <div class="login-card">
        <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-20">
          <div class="loginHeader pb-2">Sign in to your account</div>
          <form>
            <div class="form-group">
              <label class="label">Email address</label>
              <text-field
                id="email"
                name="email"
                v-model="form_data.email"
                placeholder="Enter your email"
                :maxLength="50"
                :valid="!$v.form_data.email.$error"
                :errorMessage="
                  isSubmitted && !$v.form_data.email.required
                    ? 'Email address is required'
                    : !$v.form_data.email.email
                    ? 'Please enter a valid email address'
                    : ''
                "
              />
            </div>
            <div class="form-group">
              <label class="label">Password</label>
              <div class="password-input">
                <text-field
                  id="password"
                  name="password"
                  v-model="form_data.password"
                  placeholder="Enter your password"
                  :type="showPassword ? 'text' : 'password'"
                  :maxLength="20"
                  :valid="!$v.form_data.password.$error"
                  :errorMessage="
                    isSubmitted && !$v.form_data.password.required
                      ? 'Password is required'
                      : ''
                  "
                />
                <button
                  class="password-toggle"
                  @click="togglePasswordVisibility"
                >
                  <img
                    v-if="showPassword"
                    class="showPassword"
                    src="../assets/eye-filled.png"
                    alt="Show Password"
                  />
                  <img v-else src="../assets/hide.png" alt="Hide Password" />
                </button>
              </div>
            </div>
            <Button btnClass="btn bg-primary button-card" @click="login"
              >Login</Button
            >
          </form>
          <br />
          <div class="text-center font-size-16 fw-600">
            Don't have an account? Click here to
            <a href="/register" class="signup">Sign up</a>
          </div>
          <div class="line"></div>
          <div class="col-12 p-0">
            <div class="google-signin-container">
              <div
                id="g_id_onload"
                :data-client_id="googleSignInParams.client_id"
                data-callback="handleCredentialResponse"
                data-context="signin"
                data-ux_mode="popup"
                data-itp_support="true"
                data-auto_prompt="false"
              ></div>
              <div
                class="g_id_signin"
                data-type="standard"
                data-theme="outline"
                data-shape="circle"
                data-size="large"
              ></div>
              <button id="custom-google-btn" @click="triggerGoogleSignIn">
                <div class="custom-google-btn-content">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    class="LgbsSe-Bz112c"
                  >
                    <g>
                      <path
                        fill="#EA4335"
                        d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                      ></path>
                      <path
                        fill="#4285F4"
                        d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                      ></path>
                      <path
                        fill="#FBBC05"
                        d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                      ></path>
                      <path
                        fill="#34A853"
                        d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                      ></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </g>
                  </svg>
                  <span>Sign in with Google</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
    <loading
      :active="isGisLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { required, email } from "vuelidate/lib/validators";
import TextField from "@/components/common/TextField.vue";
import Button from "./common/ButtonComponent.vue";
import axios from "axios";
import { updateTitle } from "@/utils/utils";

export default {
  components: { TextField, Button, Loading },
  name: "LoginComponent",
  data() {
    return {
      form_data: {
        email: null,
        password: null,
      },
      isLoading: false,
      isGisLoading: false,
      isSubmitted: false,
      showPassword: false,
      loggedOut: false,
      googleSignInParams: {
        client_id:
          "83230486502-9jmnaa8lpg19cfng2qaipi5282osts42.apps.googleusercontent.com",
      },
      googleSignInEmail: null,
      subjectArr: [],
    };
  },
  validations: {
    form_data: {
      email: { required, email },
      password: { required },
    },
  },
  mounted() {
    updateTitle("Sign in");
    document.addEventListener("keydown", this.handleKeyDown);
    const token = localStorage.getItem("token");
    const loaded = localStorage.getItem("pageReloaded");
    this.loggedOut = !!token;
    if (this.$route.path === "/login" && !token && loaded) {
      this.loggedOutMessage = true;
      this.$notify({
        group: "login",
        type: "success",
        text: "Successfully Logged Out",
      });
      localStorage.removeItem("pageReloaded");
    }
    this.initializeGoogleSignIn();
    window.handleCredentialResponse = this.handleCredentialResponse;
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    // Initialize Google Sign-In
    async initializeGoogleSignIn() {
      if (window.google && window.google.accounts) {
        this.googleSignInEmail = "";
        window.google.accounts.id.initialize({
          client_id: this.googleSignInParams.client_id,
          callback: this.handleCredentialResponse,
          context: "signin",
          ux_mode: "popup",
          itp_support: true,
          auto_prompt: false,
        });
        window.google.accounts.id.renderButton(
          document.querySelector(".g_id_signin"),
          {
            type: "standard",
            theme: "outline",
            shape: "circle",
          }
        );
        window.google.accounts.id.prompt();
      } else {
        console.log("Google Sign-In not available");
      }
    },
    // Loading Google Sign-In script if not already loaded
    async loadGoogleSignIn() {
      if (!document.getElementById("google-signin-script")) {
        const script = document.createElement("script");
        script.id = "google-signin-script";
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.onload = () => {
          this.initializeGoogleSignIn();
        };
        document.head.appendChild(script);
      } else {
        this.initializeGoogleSignIn();
      }
    },
    handleCredentialResponse(response) {
      const responsePayload = this.decodeJwtResponse(response.credential);
      localStorage.setItem("googleSignIn", JSON.stringify(responsePayload));
      this.googleSignInEmail = responsePayload.email;
      this.verifyEmail(responsePayload.email);
    },
    decodeJwtResponse(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    verifyEmail(email) {
      this.isGisLoading = true;
      let dataTosend = {};
      dataTosend.email = email.toLowerCase();
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_VERIFY_EMAIL_URL_PATH,
          dataTosend
        )
        .then((response) => {
          this.isGisLoading = false;
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", response.data.session_uuid);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("email", email);
          axios.defaults.headers.common["Authorization"] =
            response.data.session_uuid;
          this.fetchTopic();
          //this.$router.replace({ path: "/home" });
        })
        .catch((error) => {
          console.log(error);
          this.isGisLoading = false;
          this.$router.push("/gisregister");
        });
    },
    fetchTopic() {
      this.isLoading = true;

      let dataTosend = {
        user_email: localStorage.getItem("email"),
      };

      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_TOPIC_URL_PATH,
          dataTosend
        )
        .then((response) => {
          this.isLoading = false;
          this.subjectArr = response.data || [];
          if (this.subjectArr.length === 0) {
            if (this.$route.path !== "/parent_onboard") {
              localStorage.removeItem("selectedSubjects");
              localStorage.removeItem("selectedSubjectIds");
              localStorage.removeItem("selectedCategories");
              localStorage.removeItem("categories");
              localStorage.removeItem("isNextStep");
              this.redirectToParentOnboard();
            }
          } else {
            if (this.$route.path !== "/home") {
              this.$router.push("/home");
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (
            error.response.status ===
            404
          ) {
            if (this.$route.path !== "/parent_onboard") {
                  localStorage.removeItem("selectedSubjects");
                  localStorage.removeItem("selectedSubjectIds");
                  localStorage.removeItem("selectedCategories");
                  localStorage.removeItem("categories");
                  localStorage.removeItem("isNextStep");
              this.redirectToParentOnboard();
            }
          }
        });
    },
    // Redirect to parent onboarding
    redirectToParentOnboard() {
      this.$router.push("/parent_onboard");
    },
    async triggerGoogleSignIn() {
      // Ensure the Google Sign-In script is loaded before proceeding
      if (!window.google || !window.google.accounts) {
        await this.loadGoogleSignIn();
      }

      // Ensure initialization
      await this.initializeGoogleSignIn();

      // Try to select the button with a delay to allow for rendering
      setTimeout(() => {
        const googleBtn = document.querySelector(
          ".g_id_signin div[role=button]"
        );
        if (googleBtn) {
          googleBtn.click();
        }
      }, 500); // Adjust the delay as necessary
    },

    login() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      let dataTosend = {
        email: this.form_data.email.toLowerCase(),
        password: this.form_data.password,
      };
      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_LOGIN_DOMAIN_URL_PATH,
          dataTosend
        )
        .then((response) => {
          this.isLoading = false;
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("token", response.data.session_uuid);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("email", response.data.user_email);
          axios.defaults.headers.common["Authorization"] =
            response.data.session_uuid;
          this.fetchTopic();
          //this.$router.replace({ path: "/home" });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$notify({
            group: "login",
            type: "error",
            text:
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              "Login failed.",
          });
        });
    },
    togglePasswordVisibility(event) {
      event.preventDefault();
      this.showPassword = !this.showPassword;
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.login();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}
.login-card {
  width: 458px;
  border-radius: 47px 45px 50px 50px;
  background: #fff;
  @media all and (min-width: 400px) and (max-width: 600px) {
    width: 342px !important;
  }
  @media all and (max-width: 400px) {
    width: 300px !important;
  }
}

.button-card {
  width: 100%;
  border-radius: 40px;
  padding: 21px, 174px, 21px, 174px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(88.37deg, #8142ef -3.68%, #b566f2 103.37%);
  height: 53px;
}
.label {
  text-align: left;
}

.password-input {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 20px;
  top: 25px;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
}

.password-toggle img {
  width: 20px;
}

.signup {
  color: #9651f1;
}

.loginHeader {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media all and (max-width: 400px) {
    font-size: 20px;
  }
}
.notification-popup {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 1111119999;
  background: linear-gradient(88.37deg, #ec4c4c -3.68%, #dd6969 103.37%);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  @media all and (max-width: 400px) {
    top: 45px;
    right: 3px;
    padding: 2px;
    border-radius: 1px;
  }
}

.notification-popup-loggedout {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 1111119999;
  background: linear-gradient(88.37deg, #8142ef -3.68%, #b566f2 103.37%);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
}

.notification-content {
  display: flex;
  align-items: center;
}
.custom-dropdown .b-dropdown-toggle::after {
  content: none; /* Hide the default arrow icon */
}

.notification-message {
  flex: 1;
  margin-right: 10px;
  color: #fff;
}

.notification-close {
  background: none;
  border: none;
  color: #0c0c0c;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  transition: color 0.3s;
}
.link-with-z-index {
  margin-top: 10px;
}
.notification-close:hover {
  color: #555555;
}
.line {
  position: relative;
  height: 1px;
  width: 100%;
  margin: 30px 0;
  background-color: #d4d4d4;
}
.line::before {
  content: "Or";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #8b8b8b;
  padding: 0 15px;
}
.g_id_signin {
  display: none;
}
#custom-google-btn {
  width: 100%;
  max-width: 420px;
  min-width: 200px;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 40px;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  cursor: pointer;
  color: #3c4043;
  transition: background-color 0.218s, border-color 0.218s;
  display: flex;
  align-items: center;
  justify-content: center;
}

#custom-google-btn:hover {
  background-color: #f7f8fa;
}

.custom-google-btn-content {
  display: flex;
  align-items: center;
}

.custom-google-btn-content svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
</style>
